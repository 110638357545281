import React from "react";
import styled, { keyframes } from "styled-components";

const CustomeLoader = () => {
  const rotate360 = keyframes`
    from {
    transform: rotate(0deg);
    }
    
    to {
    transform: rotate(360deg);
    }
    `;

  const Spinner = styled.div`
    margin: 16px;
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid #16035e;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `;


  return (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div style={{ marginLeft: "20px" ,color:"#16035e" }}>
        <b>Loading...</b>
      </div>
    </div>
  );
};

export default CustomeLoader;
