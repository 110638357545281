import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
const FastMovingStock = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [batch, setBatch] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/fast_moving_stock?page=${page}&perPage=${perPage}&search=${search}`
      );
      res.data.statusCode == 200 ? setBatch(res.data.data.data) : setBatch([]);

      setPerPage(res.data.data.per_page);
      setTotalRows(res.data.data.total);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },
  
    {
      name: "PID",
      selector: (row) => (
        <div>#P{row.products?.id ? row.products.id : "No Value"}</div>
      ),
      width: "120px",
    },
  
    {
      name: "Product",
      selector: (row) => {
        const productName = row.products?.product_name
          ? row.products.product_name
          : "No Value";
        if (productName.length > 35) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 35 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "300px",
    },
  
    {
      name: "Type",
      selector: (row) => (
        <span className="text-capital">
          {row.products?.type ? row.products.type : "No Value"}
        </span>
      ),
      width: "150px",
    },
  
    {
      name: "Rate",
      selector: (row) => (
        <span className="text-capital">
          {row.products?.rate ? row.products.rate : "No Value"}
        </span>
      ),
      width: "120px",
    },
  
    {
      name: "Sale Rate",
      selector: (row) => (
        <span className="text-capital">
          {row.products?.sale_rate ? row.products.sale_rate : "No Value"}
        </span>
      ),
      width: "120px",
    },
  
    {
      name: "Total Sold",
      selector: (row) => (
        <span className="text-capital">
          {row.total_sold ? row.total_sold : "No Value"}
        </span>
      ),
      width: "120px",
    },
  ];

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Fast Moving Stock</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="col-sm-6 mt-1">
                    <TextField
                      type="text"
                      placeholder="Search...."
                      value={filterText}
                      autoFocus
                      onChange={(e) => setFilterText(e.target.value)}
                      onKeyUp={handleKeyUp}
                    />
                    <button
                      className="btn btn-primary m-1"
                      onClick={handleClear}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {batch.length == 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Fast Moving Stock Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={batch}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FastMovingStock;
