import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import "./customers.scss";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Swal from "sweetalert2";

const UpdateCustomer = () => {
  const { id } = useParams();
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [msg, setMsg] = useState("");
  const [shopstate, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [stateID, setStateId] = useState("");
  const [cityID, setCityID] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [drugLnumber, setdrugLnumber] = useState("");
  const [tinnumber, settinnumber] = useState("");
  const [gstregistered, setgstregistered] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    is_verified: "",
    mobile: "",
    shop_address1: "",
    shop_address2: "",
    shop_city: "",
    shop_state: "",
    pin: "",
    shop_image: "",
    profile_image: "",
    shop_name: "",
    shop_verified: "",
    shop_establishment_certificate: "",
    drug_license: "",
    gst_certificate: "",
    fssai: "",
    udhyam_adhar: "",
    trade_certificate: "",
    current_acount_cheque: "",
    other_shop_document: "",
  });

  // const [mobile, setMobile] = useState("");
  // const [nullvalue] = useState({
  //   name: "",
  //   email: "",
  //   is_verified: "",
  //   mobile: "",
  //   shop_name: "",
  //   shop_verified: "",
  //   shop_address1: "",
  //   shop_address2: "",
  //   shop_city: "",
  //   shop_state: "",
  //   pin: "",
  // });
  const [shopECimg, setShopECImage] = useState({
    image: "",
  });
  const [druglicenseimg, setDruglicenseImage] = useState({
    image: "",
  });
  const [gstcertificateimg, setGstcertificateImage] = useState({
    image: "",
  });
  const [fssaicertificateimg, setFssaicertificateImage] = useState({
    image: "",
  });
  const [udhyamadharimg, setUdhyamadharImage] = useState({
    image: "",
  });
  const [currentAchequeimg, setCurrentAchequeImage] = useState({
    image: "",
  });
  const [othershopdocumentimg, setOthershopdocumentImage] = useState({
    image: "",
  });
  const [tradecertificateimg, setTradecertificateImage] = useState({
    image: "",
  });
  const [profileimg, setProfileImage] = useState({
    image: "",
  });
  const [shopimg, setShopImage] = useState({
    image: "",
  });

  //  ---------------------------------HANDLE CUSTOMER INPUT FIELD DATA----------------------------------

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleInput = (e) => {
    e.persist();
    setCustomer({ ...customer, [e.target.name]: e.target.value });
    console.log(...customer);
  };

  const handleImage1 = (e) => {
    setShopECImage({ image: e.target.files[0] });
  };
  const handleImage2 = (e) => {
    setDruglicenseImage({ image: e.target.files[0] });
  };
  const handleImage3 = (e) => {
    setTradecertificateImage({ image: e.target.files[0] });
  };
  const handleImage4 = (e) => {
    setUdhyamadharImage({ image: e.target.files[0] });
  };
  const handleImage5 = (e) => {
    setFssaicertificateImage({ image: e.target.files[0] });
  };
  const handleImage6 = (e) => {
    setGstcertificateImage({ image: e.target.files[0] });
  };

  const handleImage7 = (e) => {
    setCurrentAchequeImage({ image: e.target.files[0] });
  };

  const handleImage8 = (e) => {
    setOthershopdocumentImage({ image: e.target.files[0] });
  };
  const handleImage9 = (e) => {
    setShopImage({ image: e.target.files[0] });
  };
  const handleImage0 = (e) => {
    setProfileImage({ image: e.target.files[0] });
  };

  //  ---------------------------------FETCH SINGLE CUSTOMER----------------------------------

  useEffect(() => {
    getCustomer();
  }, [id]);
  const getCustomer = async () => {
    await axios.get("/api/single_customer/" + id).then((res) => {
      console.log("single", res.data.data);
      setCustomer(res.data.data);
      setaddress1(res.data.data.shop_address1);
      setaddress2(res.data.data.shop_address2);
      setStateId(res.data.data.shop_state.id);
      setCityID(res.data.data.shop_city.id);
      setdrugLnumber(res.data.data.drug_license_no);
      setgstregistered(res.data.data.gst_registered);
      settinnumber(res.data.data.tin_no);
      setgstnumber(res.data.data.gst_no);
      // setMobile(res.data.data.mobile);
    });
    // debugger
  };

  // useEffect(() => {
  //   getCustomer();
  // }, [id]);

  // const getCustomer = async () => {
  //   await axios
  //     .get("/api/single_customer/" + id)
  //     .then((res) => {
  //       console.log("single", res.data.data);

  //       const { data } = res.data;

  //       if (!data) {
  //         console.error("Customer data not found.");

  //         return;
  //       }

  //       setCustomer(data);
  //       setaddress1(data.shop_address1 || "");
  //       setaddress2(data.shop_address2 || "");
  //       setStateId(data.shop_state?.id || "");
  //       setCityID(data.shop_city?.id || "");
  //       setdrugLnumber(data.drug_license_no || "");
  //       setgstregistered(data.gst_registered || "");
  //       settinnumber(data.tin_no || "");
  //       setgstnumber(data.gst_no || "");
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching customer data:", error);
  //       // Optionally, you can handle errors here, like showing an error message.
  //     });
  // };

  //  ---------------------------------FETCH STATE----------------------------------

  useEffect(() => {
    axios.get("/api/states").then((res) => {
      setState(res.data.data);
    });
  }, [stateID]);

  useEffect(() => {
    getCity();
  }, [stateID]);
  const getCity = async () => {
    await axios.get("/api/cities/" + stateID).then((res) => {
      setCity(res.data.data);
    });
  };

  //  ---------------------------------FETCH CITY----------------------------------

  console.log(tinnumber, "tinnumber");
  console.log(gstnumber, "gstnumber");
  console.log(gstregistered, "gstregistered");
  console.log(drugLnumber, "drugLnumber");

  //  ---------------------------------HANDLE STATE & CITY----------------------------------

  const handleCity = (e) => {
    setCityID(e.target.value);
  };

  const handleState = (e) => {
    setStateId(e.target.value);
  };

  // ----------------------------------------DROPDOWN DATA-----------------------------------------------

  const customerVerify = [
    {
      value: 1,
      name: "Verified",
    },
    {
      value: 0,
      name: "Not Verified",
    },
  ];
  console.log(customer.is_verified);

  const shopVerify = [
    {
      value: 0,
      name: "Not Verified",
    },
    {
      value: 1,
      name: "Processing",
    },
    {
      value: 2,
      name: "Verified",
    },
  ];
  console.log(customer.shop_verified);

  // -------------------------------------UPDATE CUSTOMER---------------------------------------

  const updateCustomer = async (e) => {
    e.preventDefault();
    const customerobj = new FormData();
    customerobj.append("name", customer.name);
    customerobj.append("email", customer.email);
    customerobj.append("mobile", customer.mobile);
    customerobj.append("is_verified", customer.is_verified);
    customerobj.append("shop_name", customer.shop_name);
    customerobj.append("profile_image", profileimg.image);
    customerobj.append("shop_image", shopimg.image);
    customerobj.append("address1", address1);
    customerobj.append("address2", address2);
    customerobj.append("city", cityID);
    customerobj.append("state", stateID);
    customerobj.append("pin", customer.pin);
    customerobj.append("shop_verified", customer.shop_verified);
    customerobj.append("shop_establishment_certificate", shopECimg.image);
    customerobj.append("drug_license", druglicenseimg.image);
    customerobj.append("trade_certificate", tradecertificateimg.image);
    customerobj.append("udhyam_adhar", udhyamadharimg.image);
    customerobj.append("fssai", fssaicertificateimg.image);
    customerobj.append("gst_certificate", gstcertificateimg.image);
    customerobj.append("current_acount_cheque", currentAchequeimg.image);
    customerobj.append("other_shop_document", othershopdocumentimg.image);
    customerobj.append("drug_license_no", drugLnumber);
    customerobj.append("tin_no", tinnumber);
    customerobj.append("gst_no", gstnumber);
    customerobj.append("gst_registered", gstregistered);

    axios
      .post("/api/update_customer_admin/" + customer.id, customerobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        // setMsg(result.data.message);
        // alert(result.data.message);
        navigate("/customers");
        // navigate("/customer/add-customer");
        console.log("amar", customer.shop_verified);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
        console.log(error);
        // alert(error.message);
      });
  };

  // ---------------------------------------------NO ACCESS WITHOUT TOKEN---------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    // Check if input value is numeric and does not exceed 10 digits
    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/customers">Customer</Link> / Edit
                </span>
              </div>

              <section className="bg-gre p-0">
                {/* {msg && <div className="alert alert-success">{msg}</div>} */}
                <MDBContainer className="px-5">
                  <form onSubmit={updateCustomer} encType="multipart/form-data">
                    <MDBRow>
                      <MDBCol lg="4">
                        <MDBCard className="mb-3 mt-4">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Name"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="name"
                                  type="text"
                                  placeholder="Name"
                                  value={customer.name}
                                  onChange={handleInput}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Email"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="email"
                                  type="email"
                                  placeholder="Email"
                                  value={customer.email}
                                  onChange={handleInput}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Mobile"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="mobile"
                                  type="text"
                                  placeholder="Mobile"
                                  value={customer.mobile}
                                  // onChange={handleInput}
                                  //           onChange={(e) => {
                                  //   const re = /^[0-9 .\b]+$/;
                                  //   if (
                                  //     e.target.value === "" ||
                                  //     re.test(e.target.value)
                                  //   ) {
                                  //     setMobile(e.target.value);
                                  //   }
                                  // }}
                                  onChange={handleInput}

                                  // onChange={(e) =>
                                  //   handleNumericInput(e, setMobile)
                                  // }
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Shop Name"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="shop_name"
                                  type="text"
                                  placeholder="Shop Name"
                                  value={customer.shop_name}
                                  onChange={handleInput}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Shop Address1"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="address1"
                                  type="text"
                                  placeholder="Shop Address1"
                                  value={address1}
                                  onChange={(e) => setaddress1(e.target.value)}
                                />
                              </MDBCol>
                            </MDBRow>{" "}
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Shop Address2"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="address2"
                                  type="text"
                                  placeholder="Shop Address2"
                                  value={address2}
                                  onChange={(e) => setaddress2(e.target.value)}
                                />
                              </MDBCol>
                            </MDBRow>{" "}
                            <MDBRow>
                              <MDBCol sm="12" className=" mb-3">
                                <select
                                  onChange={handleState}
                                  className="w-100 h-5"
                                >
                                  <option>----State----</option>
                                  {shopstate.map((state, index) => (
                                    <option
                                      selected={
                                        customer.shop_state &&
                                        customer.shop_state.id === state.id
                                      }
                                      key={index}
                                      value={state.id}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12" className=" mb-3">
                                <select
                                  onChange={handleCity}
                                  className="w-100 h-5"
                                >
                                  <option>----City----</option>

                                  {city.map((citys, index) => (
                                    <option
                                      selected={
                                        customer.shop_city &&
                                        customer.shop_city.id === citys.id
                                      }
                                      key={index}
                                      value={citys.id}
                                    >
                                      {citys.name}
                                    </option>
                                  ))}
                                </select>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Pin Code"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="pin"
                                  type="text"
                                  placeholder="Pin Code"
                                  value={customer.pin}
                                  onChange={handleInput}
                                  //           onChange={(e) => {
                                  //   const re = /^[0-9 .\b]+$/;
                                  //   if (
                                  //     e.target.value === "" ||
                                  //     re.test(e.target.value)
                                  //   ) {
                                  //     handleInput(e.target.value);
                                  //   }
                                  // }}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12" className=" mb-3">
                                {/* <option>--Select Customer Status--</option> */}
                                <select
                                  name="is_verified"
                                  onChange={handleInput}
                                  className="w-100 h-5"
                                >
                                  <option value="">
                                    --Select Customer Status--
                                  </option>
                                  {customerVerify.map((verify, index) => (
                                    <option
                                      selected={
                                        customer.is_verified == verify.value
                                      }
                                      key={index}
                                      value={verify.value}
                                    >
                                      {verify.name}
                                    </option>
                                  ))}
                                </select>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12" className="mb-3">
                                {/* <option>--Select Shop Status--</option> */}
                                <select
                                  name="shop_verified"
                                  onChange={handleInput}
                                  className="w-100 h-5"
                                >
                                  <option value="">
                                    --Select Shop Status--
                                  </option>
                                  {shopVerify.map((shopverifed, index) => (
                                    <option
                                      selected={
                                        customer.shop_verified ==
                                        shopverifed.value
                                      }
                                      key={index}
                                      value={shopverifed.value}
                                    >
                                      {shopverifed.name}
                                    </option>
                                  ))}
                                </select>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Tin Number"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="pin"
                                  type="text"
                                  placeholder="Tin Number"
                                  value={tinnumber}
                                  onChange={(e) => settinnumber(e.target.value)}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="Drug Licence Number"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="pin"
                                  type="text"
                                  placeholder="Drug Licence Number"
                                  value={drugLnumber}
                                  onChange={(e) =>
                                    setdrugLnumber(e.target.value)
                                  }
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12" className="mt-1 mb-4">
                                <select
                                  name="shop_verified"
                                  onChange={(e) =>
                                    setgstregistered(e.target.value)
                                  }
                                  className="w-100 h-5"
                                >
                                  <option>GST Registered</option>
                                  <option
                                    selected={gstregistered == "1"}
                                    value="1"
                                  >
                                    RD
                                  </option>
                                  <option
                                    selected={gstregistered == "0"}
                                    value="0"
                                  >
                                    URD
                                  </option>
                                </select>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol sm="12">
                                <TextField
                                  id="outlined-basic"
                                  label="GST Number"
                                  variant="outlined"
                                  className="w-100 mx-auto"
                                  name="pin"
                                  type="text"
                                  placeholder="GST Number"
                                  value={gstnumber}
                                  onChange={(e) => setgstnumber(e.target.value)}
                                />
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol lg="8">
                        <MDBCard className="mb-4 mt-4">
                          <MDBCardBody>
                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Profile Image</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.profile_image == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.profile_image}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3 mt-2"
                                  name="profile_image"
                                  type="file"
                                  onChange={handleImage0}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Shop Image</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="">
                                {customer.shop_image == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.shop_image}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="shop_image"
                                  type="file"
                                  onChange={handleImage9}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>
                                  Shop Estable Certificate
                                </MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.shop_establishment_certificate ==
                                null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.shop_establishment_certificate}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="shop_establishment_certificate"
                                  type="file"
                                  onChange={handleImage1}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Drug License</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.drug_license == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.drug_license}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="drug_license"
                                  type="file"
                                  onChange={handleImage2}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Trade Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.trade_certificate == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.trade_certificate}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="trade_certificate"
                                  type="file"
                                  onChange={handleImage3}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Udhyam Adhar</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.udhyam_adhar == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.udhyam_adhar}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="udhyam_adhar"
                                  type="file"
                                  onChange={handleImage4}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Fssai Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.fssai == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.fssai}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="fssai"
                                  type="file"
                                  onChange={handleImage5}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Gst Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.gst_certificate == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.gst_certificate}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                {" "}
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="gst_certificate"
                                  type="file"
                                  onChange={handleImage6}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Current Acount Cheque</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.current_acount_cheque == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.current_acount_cheque}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="current_acount_cheque"
                                  type="file"
                                  onChange={handleImage7}
                                />
                              </MDBCol>
                            </MDBRow>

                            <MDBRow className="mt-5">
                              <MDBCol sm="4">
                                <MDBCardText>Other Shop Document</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="3">
                                {customer.other_shop_document == null ? (
                                  <span className="text-red">
                                    Not Available
                                  </span>
                                ) : (
                                  <Link
                                    to={customer.other_shop_document}
                                    target="_blank"
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                )}
                              </MDBCol>
                              <MDBCol sm="5">
                                <input
                                  id="outlined-basic"
                                  variant="outlined"
                                  accept="image/jpeg, image/png, image/jpg"
                                  className="w-29 mx-auto mb-3"
                                  name="other_shop_document"
                                  type="file"
                                  onChange={handleImage8}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="my-4">
                              <MDBCol sm="12">
                                <button
                                  className="btn btn-danger mb-2 mt-2"
                                  style={{ marginLeft: "40%" }}
                                >
                                  Submit
                                </button>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </MDBContainer>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCustomer;
