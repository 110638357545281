import React, { useEffect, useState } from "react";
import axios from "axios";
import { AccordionDetails, Typography, TextField, Button } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { CCardHeader } from "@coreui/react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const EditHelpCenter = () => {
  const [helpCenter, setHelpCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .post("/api/get_center")
      .then((res) => {
        const data = res.data.data;
        setHelpCenter(data);
        setEmail(data.email);
        setMobileNumber(data.phone);
        setLandlineNumber(data.landline);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      });
  }, []);

  const handleSubmitForm = () => {
    axios
      .post(`/api/update_center/${helpCenter.id}`, {
        email,
        phone: mobileNumber,
        landline: landlineNumber,
      })
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: res.data.message,
        });
        navigate("/help-center");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/help-center">Help Center</Link> / Edit
              </span>
            </div>
            <div className="bg-gre " style={{ padding: "50px" }}>
              <AccordionDetails>
                <Typography>
                  <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                    <div className="text-box">
                      <strong className="m-3">
                        <CCardHeader>Edit Help Center</CCardHeader>
                      </strong>

                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Mobile Number"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => handleNumericInput(e, setMobileNumber)}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Text Message Number"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="textmessagenumber"
                        placeholder="Text Message Number"
                        value={landlineNumber}
                        onChange={(e) => {
                          const re = /^[0-9 .\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setLandlineNumber(e.target.value);
                          }
                        }}
                      />

                      <Button
                        onClick={handleSubmitForm}
                        variant="outlined"
                        className="btn btn-primary w-25 input-field mb-1"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHelpCenter;
