import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const EditBatchNumber = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [batch, setbatch] = useState("");
  const [quantity, setquantity] = useState("");
  const [expiry, setexpiry] = useState("");
  const [product, setproduct] = useState("");

  const [msg, setMsg] = useState("");
  const [errormsg, seterrorMsg] = useState("");
  const [batchdata, setbatchdata] = useState("");

  const [productlist, setproductlist] = useState([]);
  useEffect(() => {
    axios.get("/api/products").then((res) => {
      setproductlist(res.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get("/api/single_batch/" + id).then((res) => {
      setbatchdata(res.data.data);
      setproduct(res.data.data.product);
      setquantity(res.data.data.quantity);
      setbatch(res.data.data.batch_number);
      setexpiry(res.data.data.expiry);
    });
  }, [id]);

  console.log("batch", batchdata);
  console.log(quantity);
  console.log(expiry);
  console.log(product);
  // ---------------------------------------------UPDATE SINGLE BATCH NUMBER----------------------------------

  const submitForm = async (e) => {
    e.preventDefault();
    const batchobj = new FormData();
    batchobj.append("batch_number", batch);
    batchobj.append("expiry", expiry);
    batchobj.append("quantity", quantity);
    batchobj.append("product", product);
    axios
      .post("/api/update_batch/" + id, batchobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
      })
      .catch((error) => {
        console.log(error)
        seterrorMsg(error.response.data.message);
      });
  };

  // ---------------------------------------------NO ACCESS WITHOUT TOKEN----------------------

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/batch-number">Btach Number</Link> / Add
                </span>
              </div>
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <div className="bg-gre " style={{ padding: "37px" }}>
                <div className="main-box">
                  <h5>
                    <center className="mb-4 mt-1">
                      <b>Add New Batch Number</b>
                    </center>
                  </h5>
                  <div className="text-box">
                    <TextField
                      id="outlined-basic"
                      label="Batch Number"
                      variant="outlined"
                      className="input-field w-40 mb-3"
                      name="batch_number"
                      type="text"
                      placeholder="Batch Number"
                      value={batch}
                      onChange={(e) => setbatch(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Quantity"
                      variant="outlined"
                      className="input-field w-40 mb-3"
                      name="quantity"
                      type="text"
                      placeholder="Quantity"
                      value={quantity}
                      onChange={(e) => setquantity(e.target.value)}
                    />
                    <Select
                      value={product}
                      onChange={(e) => setproduct(e.target.value)}
                      displayEmpty
                      name="product"
                      className="w-40 mb-4"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <span>Product</span>
                      </MenuItem>
                      {productlist.map((items, index) => (
                        <MenuItem
                          key={index}
                          selected={product == items.id}
                          value={items.id}
                        >
                          {items.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Expiry"
                      variant="outlined"
                      className="input-field w-40 mt-1 mb-3"
                      name="expiry"
                      type="text"
                      placeholder="Expiry"
                      value={expiry}
                      onChange={(e) => setexpiry(e.target.value)}
                    />

                    <button
                      onClick={submitForm}
                      className="btn btn-outline-danger   input-field mb-4"
                    >
                      Add Batch Number
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBatchNumber;
