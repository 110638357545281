import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Faqs = () => {
  const [faqall, setFaq] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
 


  useEffect(() => {
    axios.get("/api/faqs").then((res) => {
      setFaq(res.data.data);
    });
  }, []);


  const deleteHandler = (state) => {
    
    Swal.fire({
     
      html: "Are you sure you wish to<br /> delete this FAQ?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button", 
        cancelButton: "swal-cancel-button", 
      },
      width:"350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {

    axios
      .delete("/api/delete_faqs/" + state.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        let newArr = faqall.filter((items) => {
          return state.id !== items.id;
        });
        setFaq(newArr);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });

      });
    };
  });
  };


  const submitForm = async (e) => {
    e.preventDefault();
    const faqobj = new FormData();
    faqobj.append("question", question);
    faqobj.append("answer", answer);

    axios
      .post("/api/create_faqs", faqobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        window.location.reload(true);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white  ml-3">
                <span>
                  <Link to="/dashboard">Dashboard</Link> / FAQ's
                </span>
              </div>
             
              <div className="bg-gre  ml-3" style={{ padding: "35px",minHeight:"600px" }}>
                <Accordion className="mb-3">
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      {" "}
                      <b>ADD NEW FAQ's</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {" "}
                      <div className="main-box mb-2">
                        <div className="text-box">
                          <TextField
                            id="outlined-basic"
                            label="Question"
                            variant="outlined"
                            className="input-field w-40 mb-2"
                            name="question"
                            type="text"
                            placeholder="Question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                          <TextField
                            id="outlined-basic"
                            label="Answer"
                            variant="outlined"
                            className="input-field w-40 mb-2"
                            type="text"
                            name="answer"
                            placeholder="Answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            rows={3}
                            multiline
                          />

                          <button
                            onClick={submitForm}
                            className="btn btn-outline-danger  w-25 input-field mb-1"
                          >
                            Add FAQ's
                          </button>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <div>
                  <center>
                    <h5>ALL FAQ's</h5>
                  </center>
                  {faqall &&
                    faqall.map((state, index) => (
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography key={index}>
                            <span className="text-red ">Q.</span>{" "}
                            <span className="text-capital">{state.question}</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="mb-3">
                            <span className="text-red">Answer-: </span>
                            <span className="text-capital">{state.answer}</span>
                         
                          </Typography>
                          <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteHandler(state)}
                          >
                            <DeleteIcon />
                          </button>{" "}
                          <Link
                            to={`/FAQ's/${state.id}`}
                            className="btn btn-primary mx-1"
                          >
                            <EditIcon />
                          </Link>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
