import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";

const OrderReturns = () => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [singleOrder, setSingleOrder] = useState([]);
  const [singleOrderdetail, setSingleOrderdetail] = useState("");
  const [invoice, setInvoice] = useState("");
  const [setting, setSetting] = useState("");
  const [amount, setAmount] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [status, setStatus] = useState("");

  const [shipAddress1, setShipAddress1] = useState("");
  const [shipAddress2, setShipAddress2] = useState("");

  const [shipAddress3, setShipAddress3] = useState("");

  const [image, setImage] = useState("");

  const [reason, setReason] = useState("");

  const [visibleLg, setVisibleLg] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState(null);

  const openModal = (purchaseOrderId) => {
    setSelectedPurchaseOrderId(purchaseOrderId);
    setVisibleLg(true);
  };

  const statuschange = async (e) => {
    e.preventDefault();
    const productobj = new FormData();
    productobj.append("order_status", e.target.value);
    try {
      await axios.post("/api/update_order_return/" + id, productobj, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (visibleLg && selectedPurchaseOrderId) {
      loadCustomer(selectedPurchaseOrderId);

      axios
        .get("/api/get_settings")
        .then((res) => {
          setSetting(res.data.data);
          setImage(res.data.data.logo);
        })
        .catch((error) => {});
    }
  }, [visibleLg, selectedPurchaseOrderId]);

  const loadCustomer = async (purchaseOrderId) => {
    try {
      const result = await axios.get(
        "/api/single_return_order/" + purchaseOrderId
      );
      setSingleOrder(result.data.data);
      setSingleOrderdetail(result.data.order.customer || {});
      setInvoice(result.data.invoice);
      setAmount(result.data.order.total);
      setOrderNo(result.data.order);
      setStatus(result.data.order);
      setReason(result.data.order.app_reason);
      setShipAddress1(result.data.order.ship_city);
      setShipAddress2(result.data.order.ship_state);
      setShipAddress3(result.data.order.ship_country);
    } catch (error) {}
  };

  useEffect(() => {
    axios
      .get("/api/get_all_return_orders")
      .then((res) => {
        res.data.status == "Success" ? setOrders(res.data.data) : setOrders([]);
      })

      .catch((err) => {});
  }, []);

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <div style={{ width: "90px" }}>
          <CButton color="primary" onClick={() => openModal(row.id)}>
            <VisibilityIcon />
          </CButton>
        </div>
      ),
      width: "90px",
    },

    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Order No",
      selector: (row, index) => row.order_number,
      width: "100px",
    },

    {
      name: "Customer",
      selector: (row) => (
        <span style={{ fontSize: "16px" }}>
          {row.customer
            ? row.customer.name || "Not Available"
            : "Not Available"}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Total",
      selector: (row) => Number(row.total).toFixed(2),

      width: "120px",
    },
    {
      name: "Payment",
      selector: (row) => row.payment_mode,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span>{row.order_status == 0 ? "Processing" : "Completed"} </span>
      ),
      width: "120px",
    },

    {
      name: "Mobile",
      selector: (row) => row.phone,
      width: "120px",
    },
  ];

  const columns2 = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Product Id",
      selector: (row, index) => row.product_id,
      width: "130px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product.product_name
          ? row.product.product_name
          : "Not Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "170px",
    },

    {
      name: "Batch",
      selector: (row) => row.batch_number,
      width: "100px",
    },

    {
      name: "Disc",
      selector: (row) => <span>{row.discount + "%"}</span>,

      width: "100px",
    },

    {
      name: "Discount Price",
      selector: (row) => <span>{row.discounted_price}</span>,

      width: "130px",
    },

    {
      name: "GST",
      selector: (row) => <span>{row.gst + "%"}</span>,
      width: "80px",
    },

    {
      name: "Sale Rate",
      selector: (row) => Number(row.sale_rate).toFixed(2),

      width: "100px",
    },

    {
      name: "Qty",
      selector: (row) => row.quantity,
      width: "100px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(orders);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  const filteredItems = orders.filter(
    (item) =>
      (item.customer?.name &&
        item.customer?.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.order_number &&
        item.order_number.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <CModal
        size="fullscreen"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="ViewPurchaseModal"
      >
        <CModalHeader closeButton>
          <CModalTitle style={{ marginLeft: "75px" }}>
            <strong>View Order Return </strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Order Return ID: {selectedPurchaseOrderId}
          <MDBContainer className="px-5 mt-4">
            <MDBRow>
              <MDBCol lg="12">
                <MDBCard className="mb-2">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol
                        sm="4"
                        className="border-right"
                        style={{ fontSize: "14px" }}
                      >
                        <MDBCardText>
                          <span>
                            <strong>{singleOrderdetail.name}</strong>
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          <span>{singleOrderdetail.email}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span>{singleOrderdetail.mobile}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span>{singleOrderdetail.total}</span>
                        </MDBCardText>
                        <MDBCardText>
                          <span>
                            {singleOrderdetail.shop_address1 +
                              " , " +
                              shipAddress1.name +
                              " , " +
                              shipAddress2.name +
                              " , " +
                              shipAddress3}
                          </span>
                        </MDBCardText>
                        <MDBCardText>
                          Order No.-
                          <span>{orderNo.order_number}</span>
                        </MDBCardText>
                        <MDBCardText>
                          Reason for Cancel -
                          <span style={{ color: "red" }}>
                            {" "}
                            {orderNo.app_reason}
                          </span>
                        </MDBCardText>
                      </MDBCol>

                      <MDBCol
                        sm="3"
                        className="border-right"
                        style={{ fontSize: "14px" }}
                      >
                        <MDBCardText>
                          <span className="fw-600">
                            <strong>{setting.company_name}</strong>
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.email}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.phone}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.website}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.address}</span>
                        </MDBCardText>
                      </MDBCol>

                      <MDBCol className="d-flex align-items-center" sm="4">
                        <img
                          src={image}
                          className="rounded mx-auto d-block"
                          alt="Logo"
                          width={180}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow className="mt-1">
                      <div style={{ margin: "1%" }}>
                        <DataTable
                          columns={columns2}
                          title="Order Detail"
                          data={singleOrder}
                          defaultSortFieldId
                          fixedHeader
                          responsive
                          allowOverflow
                          subHeaderAlign="right"
                        />
                      </div>
                    </MDBRow>
                    <hr></hr>

                    <MDBRow className="mt-2">
                      <MDBCol sm="5"></MDBCol>
                      <MDBCol sm="4"></MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText style={{ fontSize: "25px" }}>
                          Total :{" "}
                          <span style={{ fontSize: "25px", fontWeight: "700" }}>
                            ₹ {parseFloat(amount).toFixed(2)}
                          </span>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="8">
                        <MDBCardText>
                          Change Status :{" "}
                          <select onChange={statuschange}>
                            <option
                              selected={status.order_status == "0"}
                              value="0"
                            >
                              Processing
                            </option>
                            <option
                              selected={status.order_status == "1"}
                              value="1"
                            >
                              Complete
                            </option>
                          </select>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </CModalBody>
      </CModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Order Return</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <TextField
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>

                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination={10}
                      allowOverflow
                      subHeaderAlign="right"
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderReturns;
