import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import axios from "axios";

const ViewExpiryReturn = () => {
  const { id } = useParams();

  const [singleOrder, setSingleOrder] = useState([]);
  const [singleOrderdetail, setSingleOrderdetail] = useState("");
  const [invoice, setInvoice] = useState("");
  const [setting, setSetting] = useState("");
  const [amount, setAmount] = useState("");
  const [gst, setGst] = useState("");

  const [image, setImage] = useState("");

  const loadCustomer = async () => {
    const result = await axios.get("/api/expiry_order_detail/" + id);
    setSingleOrder(result.data.data);
    setSingleOrderdetail(result.data.order || {});
    setInvoice(result.data.invoice);
    setAmount(result.data.total_amount);
    setGst(result.data.total_gst_amount);
  };

  useEffect(() => {
    loadCustomer();
  }, []);

  useEffect(() => {
    axios.get("/api/get_settings").then((res) => {
      setSetting(res.data.data);
      setImage(res.data.data.logo);
    });
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Product Id",
      selector: (row, index) => row.product_id,
      width: "130px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product_name
          ? row.product_name
          : "Not Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "170px",
    },
    {
      name: "Batch",
      selector: (row) => row.batch_number,
      width: "100px",
    },

    {
      name: "GST",
      selector: (row) => <span>{row.gst + "%"}</span>,
      width: "80px",
    },

    {
      name: "Gst Price",
      selector: (row) => Number(row.gst_price).toFixed(2),

      width: "130PX",
    },

    {
      name: "Sale Rate",
      selector: (row) => Number(row.sale_rate).toFixed(2),

      width: "120px",
    },

    {
      name: "Qty",
      selector: (row) => row.quantity,
      width: "100px",
    },
  ];

  const statuschange = async (e) => {
    e.preventDefault();
    const productobj = new FormData();
    productobj.append("order_status", e.target.value);
    await axios
      .post("/api/update_expiry_order_return/" + id, productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {})
      .catch((error) => {});
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const totalamount = amount + gst;
  let reducedAmount = totalamount * 0.65;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <div className="container-fluid">
            <Navbar />

            <div className="p-2 bg-white ml-3">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/expiry-returns">Expiry Return</Link> / View
              </span>
            </div>

            <section
              className="bg-gre ml-3"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol
                            sm="4"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span>
                                <strong>
                                  {singleOrderdetail.customer_name}
                                </strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>{singleOrderdetail.customer_email}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>{singleOrderdetail.customer_mobile}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>
                                {singleOrderdetail.ship_address +
                                  " , " +
                                  singleOrderdetail.ship_address2 +
                                  " , " +
                                  singleOrderdetail.ship_city +
                                  " , " +
                                  singleOrderdetail.ship_state +
                                  " , " +
                                  singleOrderdetail.ship_country}
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              Order No.-
                              <span>{singleOrderdetail.order_number}</span>
                            </MDBCardText>
                            <MDBCardText>
                              Reason for Cancel -
                              <span style={{ color: "red" }}>
                                {" "}
                                {singleOrderdetail.app_reason}
                              </span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol
                            sm="3"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span className="fw-600">
                                <strong>{setting.company_name}</strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.email}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.phone}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.website}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.address}</span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol className="d-flex align-items-center" sm="4">
                            <img
                              src={image}
                              className="rounded mx-auto d-block"
                              alt="Logo"
                              width={180}
                            />
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow className="mt-1">
                          <div style={{ margin: "1%" }}>
                            <DataTable
                              columns={columns}
                              title="Order Detail"
                              data={singleOrder}
                              defaultSortFieldId
                              fixedHeaderScrollHeight="480px"
                              fixedHeader
                              responsive
                              allowOverflow
                              subHeaderAlign="right"
                            />
                          </div>
                        </MDBRow>
                        <hr></hr>
                        <MDBRow className="mt-2">
                          <MDBCol sm="5"></MDBCol>
                          <MDBCol sm="4"></MDBCol>
                          <MDBCol sm="3">
                            <MDBCardText style={{ fontSize: "25px" }}>
                              Total :{" "}
                              <span
                                style={{ fontSize: "25px", fontWeight: "700" }}
                              >
                                ₹ {parseFloat(totalamount).toFixed(2)}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2 mr-4">
                          <MDBCol sm="6"></MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText style={{ fontSize: "25px" }}>
                              Amount After 35% Deduction :{" "}
                              <span
                                style={{ fontSize: "25px", fontWeight: "700" }}
                              >
                                ₹ {parseFloat(reducedAmount).toFixed(2)}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="8">
                            <MDBCardText>
                              Change Status :{" "}
                              <select onChange={statuschange}>
                                <option
                                  selected={
                                    singleOrderdetail.order_status == "0"
                                  }
                                  value="0"
                                >
                                  Processing
                                </option>
                                <option
                                  selected={
                                    singleOrderdetail.order_status == "1"
                                  }
                                  value="1"
                                >
                                  Complete
                                </option>
                              </select>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewExpiryReturn;
