import React, { useEffect, useState } from "react";
import axios from "axios";
import { AccordionDetails, Typography, TextField, Button } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { CCardHeader } from "@coreui/react";
import Navbar from "../../components/navbar/Navbar";
const HelpCenter = () => {
  const [helpCenter, setHelpCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");

  useEffect(() => {
    axios
      .post("/api/get_center")
      .then((res) => {
        const data = res.data.data;
        setHelpCenter(data);
        setEmail(data.email);
        setMobileNumber(data.phone);
        setLandlineNumber(data.landline);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white  ml-3">
                <span>
                  <Link to="/dashboard">Dashboard</Link>
                </span>
              </div>
              <div className="bg-gre  ml-3 " style={{ padding: "50px" }}>
                <AccordionDetails>
                  <Typography>
                    <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                      <div className="text-box">
                        <strong className="m-3">
                          <CCardHeader>Help Center</CCardHeader>
                        </strong>

                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          name="email"
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Mobile Number"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="mobileNumber"
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Text Message Number"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="textmessagenumber"
                          placeholder="Text Message Number"
                          value={landlineNumber}
                          onChange={(e) => setLandlineNumber(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />

                        <Link to="/edit-help-center">
                          <Button
                            variant="outlined"
                            className="btn btn-primary w-25 input-field mb-1"
                          >
                            Edit
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
