import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";

import { CCard, CCardHeader } from "@coreui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EarlyExpiry = () => {
  const [stock, setStock] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [batch, setBatch] = useState([]);

  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, selectedDate]);

  useEffect(() => {
    if (selectedDate !== null) {
      fetchData();
    }
  }, [selectedDate]);

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  const fetchData = async () => {
    try {
      let url = `/api/early_expiry_statement?page=${page}&perPage=${perPage}&expiry_date=${search}`;
      if (selectedDate !== null) {
        const formattedDate = formatDate(selectedDate);
        url += `${formattedDate}`;
      }
      const response = await axios.post(url);

      setBatch(response.data.data);
      setPerPage(response.data.pagination.per_page);
      setTotalRows(response.data.pagination.total);
    } catch (error) {}
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,

      width: "100px",
    },

    {
      name: "PID",
      selector: (row, index) => <div>#P{row.products.id}</div>,

      width: "90px",
    },
    {
      name: "Batch No.",
      selector: (row, index) => <div>{row.batch_number}</div>,

      width: "150px",
    },

    {
      name: "Product Name",
      selector: (row) => {
        const productName = row.products.product_name;
        const isProductNameAvailable = productName != null;
        const textColor = isProductNameAvailable ? "black" : "red";

        if (isProductNameAvailable && productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ color: textColor, whiteSpace: "pre-line" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return (
            <span className="text-capital" style={{ color: textColor }}>
              {isProductNameAvailable ? productName : "Product No Available"}
            </span>
          );
        }
      },
    },

    {
      name: "Type",
      selector: (row) => (
        <span className="text-capital">{row.products.type}</span>
      ),
      width: "120px",
    },

    {
      name: "Rate",
      selector: (row) => (
        <span className="text-capital">{row.products.rate}</span>
      ),
      width: "120px",
    },

    {
      name: "expiry",
      selector: (row) => <span className="text-capital">{row.expiry}</span>,
      width: "120px",
    },

    {
      name: "Total Quantity",
      selector: (row) => (
        <span className="text-capital">{row.total_quantity}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Order",
      selector: (row) => (
        <span className="text-capital">{row.total_order}</span>
      ),
      width: "120px",
    },

    {
      name: "Remaning Quantity",
      selector: (row) => (
        <span className="text-capital">{row.remaning_quantity}</span>
      ),
      width: "150px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(stock);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const filteredItems = batch.filter(
    (item) =>
      (item.products.product_name &&
        item.products.product_name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.batch_number &&
        item.batch_number.toLowerCase().includes(filterText.toLowerCase()))
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4 ml-3">
                <CCardHeader>
                  <strong>Early Expiry</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="col-sm-6 mt-1">
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="btn btn-primary m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>
                    </div>

                    <div className="col-sm-4 pt-2 mt-2 mr-2 d-flex justify-content-between align-items-center">
                      <strong>Select Date</strong>
                      <DatePicker
                        selected={selectedDate}
                        minDate={new Date(today.getFullYear())}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy"
                        showDateInput
                        showIcon
                        showMonthYearPicker
                        withPortal
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {filteredItems.length == 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Early Expiry Stock Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EarlyExpiry;
