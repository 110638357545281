import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CCardBody,
  CCol,
  CFormInput,
  CFormLabel,
  CForm,
  CFormSelect,
  CCardTitle,
  CModalFooter,
} from "@coreui/react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
const PurchaseOtable = () => {
  const [orders, setOrders] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [visibleLg, setVisibleLg] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState(null);
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [invoice, setInvoice] = useState("");

  const [setting, setSetting] = useState("");
  const [image, setImage] = useState("");

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);

  const { id } = useParams();

  const [expiryDates, setExpiryDates] = useState([]);
  const today = new Date();

  const [suppliername, setsuppliername] = useState("");
  const [billnumber, setBillnumber] = useState("");
  const [date, setDate] = useState(null);
  const [purchaseBillNo, setPurchaseBillNo] = useState("db231");
  const [city, setCity] = useState("");
  const [remark, setRemark] = useState("");

  const [productID, setProductId] = useState([
    {
      product: {
        product_name: "",
      },
      product_id: "",
      selectedProductId: "",
      quantity: "",
      add_gst: "",
      mrp: "",
      discount: "",
      additional_discount: "",
      batch_number: "",
      purchase_rate: "",
      final_purchase_rate: "",
      sale_rate: "",
      expiry: "",
      additional_amount: "",
      product_type: "",
      remove_discount: "",
      scheme_discount_name: "",
      remaining_quantity: "",
    },
  ]);

  const [productlist, setproductlist] = useState([]);

  const [visibleSaleProduct, setVisibleSaleProduct] = useState(false);

  const [saleProductDetails, setSaleProductDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [visible, setVisible] = useState(false);

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(true);

  const [maxOrder, setMaxOrder] = useState("");
  const [purchaseQuantity, setPurchaseQuantity] = useState("");

  const [batchList, setBatchList] = useState([]);

  const [selectedBatch, setSelectedBatch] = useState([]);
  const [selectedBatchName, setSelectedBatchName] = useState([]);

  const [purchaseRates, setPurchaseRates] = useState([]);

  const [productDetail, setProductDetail] = useState([]);

  const [grandTotal, setGrandTotal] = useState("");
  const [items, setItems] = useState({ product: null });
  const [showSelectedProduct, setShowSelectedProduct] = useState(
    Array(productlist.length).fill(true)
  );

  const inputRefs = useRef([]);
  const [selectedProducts, setSelectedProducts] = useState([""]);

  const generatePurchaseBillNo = () => {
    const randomNum = Math.floor(100 + Math.random() * 900);
    return `db${randomNum}`;
  };

  useEffect(() => {
    setPurchaseBillNo(generatePurchaseBillNo());
  }, []);

  const handleInputSearch = (event, index) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = [...prevSelectedProducts];
      updatedSelectedProducts[index] = event.target.value;
      return updatedSelectedProducts;
    });
  };

  const handleClear = (index) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = [...prevSelectedProducts];
      updatedSelectedProducts[index] = "";
      return updatedSelectedProducts;
    });
    inputRefs.current[index].focus();
  };

  const handleArrowClick = (index) => {
    inputRefs.current[index].focus();
  };

  const openEditModal = (id) => {
    setEditModalVisible(true);
    setCurrentEditId(id);
  };

  useEffect(() => {
    if (editModalVisible && currentEditId) {
      axios
        .get("/api/single_batch/" + currentEditId)
        .then((res) => {
          const { po, data } = res.data;
          setsuppliername(res.data.po.supplier_id);

          setBillnumber(res.data.po.bill_number);
          setPurchaseBillNo(res.data.po.purchase_bill_no);
          setCity(res.data.po.supplier_city);
          setDate(res.data.po.date);
          setRemark(res.data.po.remarks);
          setProductId(res.data.data);
        })
        .catch((err) => {});
    }
  }, [editModalVisible, currentEditId]);

  const handleBatchSelect = (value, name, i) => {
    const updatedSelectedBatch = [...selectedBatch];
    updatedSelectedBatch[i] = value;
    setSelectedBatch(updatedSelectedBatch);

    const updatedSelectedBatchName = [...selectedBatchName];
    updatedSelectedBatchName[i] = name;
    setSelectedBatchName(updatedSelectedBatchName);

    handleInput(
      {
        target: {
          name: "batch_number",
          value: value,
        },
      },
      i
    );
  };

  const inputRef1 = useRef(null);
  const handleArrowClick1 = () => {
    inputRef1.current.focus();
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/products")
      .then((res) => {
        setproductlist(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const fetchBatchList = (selectedProductId) => {
    if (selectedProductId) {
      axios
        .get(`/api/purchase_product_batch/${selectedProductId}`)
        .then((res) => {
          setBatchList(res.data.data);
        })
        .catch((err) => {
          setBatchList([]);
        });
    }
  };

  useEffect(() => {
    if (selectedProductId) {
      fetchBatchList(selectedProductId);
    }
  }, [selectedProductId]);
  const totalPurchaseAmount = productID
    .reduce((total, product) => {
      return (
        total + parseFloat(product.purchase_rate) * parseFloat(product.qty)
      );
    }, 0)
    .toFixed(2);
  const [suppliernamelist, setSuppliernamelist] = useState([]);

  useEffect(() => {
    axios.get("/api/get_supplier").then((res) => {
      setSuppliernamelist(res.data.data);
    });
  }, []);

  const calculateTotal = (final_purchase_rate, quantity, additional_amount) => {
    const rate = parseFloat(final_purchase_rate);
    const quantity1 = parseFloat(quantity);
    const additionalAmount = parseFloat(additional_amount) || 0;

    if (isNaN(rate) || isNaN(quantity1) || isNaN(additionalAmount)) {
      return 0;
    }

    const total = rate * quantity1 + additionalAmount;

    return total;
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    const initialTotal = productID.reduce((acc, curr) => {
      return (
        acc +
        calculateTotal(
          parseFloat(curr.final_purchase_rate),
          parseInt(curr.quantity),
          parseFloat(curr.additional_amount)
        )
      );
    }, 0);

    setGrandTotal(initialTotal);
  }, [productID]);

  const initializeData = () => {
    setProductId([
      { final_purchase_rate: 0, quantity: 0, additional_amount: 0 },
    ]);
    setPurchaseRates([]);
  };

  const handleInput = (e, i) => {
    const { name, value } = e.target;

    setProductId((prevProductID) => {
      const updatedProductID = [...prevProductID];
      if (!updatedProductID[i]) {
        updatedProductID[i] = {
          final_purchase_rate: 0,
          quantity: 0,
          additional_amount: 0,
        };
      }
      updatedProductID[i] = {
        ...updatedProductID[i],
        [name]: value,
      };
      const totalAmount = updatedProductID.reduce((acc, curr) => {
        return (
          acc +
          calculateTotal(
            parseFloat(curr.final_purchase_rate),
            parseInt(curr.quantity),
            parseFloat(curr.additional_amount)
          )
        );
      }, 0);
      setGrandTotal(totalAmount);
      if (
        name === "discount" ||
        name === "additional_discount" ||
        name === "add_gst" ||
        name === "purchase_rate" ||
        name === "remove_discount"
      ) {
        const discount = parseFloat(updatedProductID[i].discount) || 0;
        const additional_discount =
          parseFloat(updatedProductID[i].additional_discount) || 0;
        const remove_discount =
          parseFloat(updatedProductID[i].remove_discount) || 0;
        const addGst = parseFloat(updatedProductID[i].add_gst) || 0;
        const purchaseRate = parseFloat(updatedProductID[i].purchase_rate) || 0;

        let newPurchaseRate = purchaseRate - (purchaseRate * discount) / 100;
        newPurchaseRate -= (newPurchaseRate * additional_discount) / 100;
        newPurchaseRate += (newPurchaseRate * remove_discount) / 100;
        newPurchaseRate *= 1 + addGst / 100;

        updatedProductID[i] = {
          ...updatedProductID[i],
          final_purchase_rate: newPurchaseRate.toFixed(2),
        };

        const newPurchaseRates = [...purchaseRates];
        newPurchaseRates[i] = newPurchaseRate.toFixed(2);
        setPurchaseRates(newPurchaseRates);
      }

      return updatedProductID;
    });
  };

  const handleProductSelect = (selectedProductName, i) => {
    const selectedProduct = productlist.find(
      (product) => product.product_name === selectedProductName
    );

    if (selectedProduct) {
      setItems((prevItems) => {
        const updatedItems = { ...prevItems };
        updatedItems.product = selectedProduct;
        return updatedItems;
      });

      setProductId((prevProductID) => {
        const updatedProductID = [...prevProductID];
        updatedProductID[i] = {
          ...updatedProductID[i],
          product: selectedProduct,
          selectedProductId: selectedProduct.id,
          product_id: selectedProduct.id,
        };
        setProductDetails([]);
        setSaleProductDetails([]);
        fetchData(selectedProduct.id, i);
        fetchBatchList(selectedProduct.id);

        const updatedShowSelectedProduct = [...showSelectedProduct];
        updatedShowSelectedProduct[i] = false;
        setShowSelectedProduct(updatedShowSelectedProduct);

        return updatedProductID;
      });
    }
  };

  const fetchData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_purchase_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {};
      const productData = responseData.data || [];

      setMaxOrder(res.data.product_data.max_order);
      setPurchaseQuantity(res.data.product_data.total_purchase_quantity);

      setProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],

            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }
      if (
        res.data.last_batch_data &&
        Object.keys(res.data.last_batch_data).length > 0
      ) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            additional_amount: res.data.last_batch_data.additional_amount,
            additional_discount: res.data.last_batch_data.additional_discount,
            back_stock: res.data.last_batch_data.back_stock,
            batch_number: res.data.last_batch_data.batch_number,
            discount: res.data.last_batch_data.discount,
            expiry: res.data.last_batch_data.expiry,
            final_purchase_rate: res.data.last_batch_data.final_purchase_rate,
            id: res.data.last_batch_data.id,
            mrp: res.data.last_batch_data.mrp,
            purchase_order: res.data.last_batch_data.purchase_order,
            purchase_rate: res.data.last_batch_data.purchase_rate,
            quantity: res.data.last_batch_data.quantity,
            remaining_quantity: res.data.last_batch_data.remaining_quantity,
            remove_discount: res.data.last_batch_data.remove_discount,
            sale_rate: res.data.last_batch_data.sale_rate,
            scheme_discount_name: res.data.last_batch_data.scheme_discount_name,
          };
          return updatedProducts;
        });
      } else {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            additional_amount: "",
            additional_discount: "",
            back_stock: "",
            batch_number: "",
            discount: "",
            expiry: "",
            final_purchase_rate: "",
            id: "",
            mrp: "",
            purchase_order: "",
            purchase_rate: "",
            quantity: "",
            remaining_quantity: "",
            remove_discount: "",
            sale_rate: "",
            scheme_discount_name: "",
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };

  const fetchSaleProductData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_sale_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {};
      const productData = responseData.data || [];

      setSaleProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],

            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };

  const handleRow = (e, productId) => {
    setSelectedProductId("");
    setBatchList([]);

    setProductId((prevProductID) => {
      const newProductId = [
        ...prevProductID,
        {
          product: {
            product_name: "",
          },
          product_id: productId,
          selectedProductId: "",
          quantity: "",
          add_gst: "",
          mrp: "",
          discount: "",
          additional_discount: "",
          batch_number: "",
          purchase_rate: "",
          final_purchase_rate: "",
          sale_rate: "",
          expiry: "",
          additional_amount: "",
          product_type: "",
          remove_discount: "",
          scheme_discount_name: "",
          remaining_quantity: "",
        },
      ];
      setExpiryDates([...expiryDates, null]);
      setPurchaseRates([...purchaseRates, ""]);

      return newProductId;
    });

    e.preventDefault();
  };

  const totalamount = productID.reduce((acc, curr) => {
    return acc + calculateTotal(curr.purchase_rate, curr.qty, curr.addAmount);
  }, 0);

  const handleRemove = (index) => {
    const newForm = [...productID];
    newForm.splice(index, 1);

    const newExpiryDates = [...expiryDates];
    newExpiryDates.splice(index, 1);

    const newPurchaseRates = [...purchaseRates];
    newPurchaseRates.splice(index, 1);

    setProductId(newForm);
    setExpiryDates(newExpiryDates);
    setPurchaseRates(newPurchaseRates);
  };
  const submitForm = async (e) => {
    e.preventDefault();

    if (
      !suppliername ||
      !billnumber ||
      !purchaseBillNo ||
      !city ||
      !date ||
      !remark
    ) {
      Toast.fire({
        icon: "error",
        title: "Please fill in all required fields.",
      });
      return;
    }

    setPurchaseBillNo(generatePurchaseBillNo());

    const batchNumbersMap = new Map();

    for (const [index, product] of productID.entries()) {
      if (!product || !product.product || !product.product.id) {
        Toast.fire({
          icon: "error",
          title: `Invalid product information for item at index ${index}.`,
        });
        return;
      }

      const compositeKey = `${product.selectedProductName}_${product.product.id}`;

      let batchNumbersForProduct = batchNumbersMap.get(compositeKey);

      if (!batchNumbersForProduct) {
        batchNumbersForProduct = new Set();
        batchNumbersMap.set(compositeKey, batchNumbersForProduct);
      }

      if (batchNumbersForProduct.has(product.batch_number)) {
        Toast.fire({
          icon: "error",
          title: `Duplicate batch number detected for product (ID: ${product.product.id}): ${product.batch_number}`,
        });
        return;
      }

      batchNumbersForProduct.add(product.batch_number);
      if (!product.gst) {
        product.gst = "0";
      }
    }

    const formData = new FormData();
    formData.append("supplier_id", suppliername);
    formData.append("bill_number", billnumber);
    formData.append("purchase_bill_no", purchaseBillNo);
    formData.append("city", city);
    formData.append("date", date);
    formData.append("remarks", remark);

    productID.forEach((product, index) => {
      if (!product || !product.product || !product.product.id) {
        Toast.fire({
          icon: "error",
          title: `Invalid product information for item at index ${index}.`,
        });
        return;
      }

      formData.append(`product_id[${index}]`, product.product.id);
      formData.append(`qty[${index}]`, product.quantity);
      formData.append(`add_gst[${index}]`, product.add_gst);
      formData.append(`mrp[${index}]`, product.mrp);
      formData.append(
        `discount[${index}]`,
        product.discount ? product.discount : 0
      );
      formData.append(
        `additional_discount[${index}]`,
        product.additional_discount ? product.additional_discount : 0
      );
      formData.append(`batch_number[${index}]`, product.batch_number);
      formData.append(`purchase_rate[${index}]`, product.purchase_rate);
      formData.append(
        `final_purchase_rate[${index}]`,
        product.final_purchase_rate
      );
      formData.append(`sale_rate[${index}]`, product.sale_rate);
      formData.append(`expiry[${index}]`, product.expiry);
      formData.append("grand_total", grandTotal);
      formData.append(
        `additional_amount[${index}]`,
        product.additional_amount ? product.additional_amount : 0
      );
      formData.append(`product_type[${index}]`, product.product_type);
      formData.append(
        `remove_discount[${index}]`,
        product.remove_discount ? product.remove_discount : 0
      );
      formData.append(
        `scheme_discount_name[${index}]`,
        product.scheme_discount_name ? product.scheme_discount_name : ""
      );
    });

    axios
      .post(`/api/update_batch/${currentEditId}`, formData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        setEditModalVisible(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  const formatDate1 = (date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    axios
      .get("/api/get_batch")
      .then((res) => {
        setOrders(res.data.data);
      })
      .catch((err) => {});
  }, [editModalVisible, currentEditId]);

  const openModal = (purchaseOrderId) => {
    setSelectedPurchaseOrderId(purchaseOrderId);
    setVisibleLg(true);
  };

  useEffect(() => {
    if (visibleLg && selectedPurchaseOrderId) {
      loadCustomer(selectedPurchaseOrderId);

      axios
        .get("/api/get_settings")
        .then((res) => {
          setSetting(res.data.data);
          setImage(res.data.data.logo);
        })
        .catch((error) => {});
    }
  }, [visibleLg, selectedPurchaseOrderId]);

  const loadCustomer = async (purchaseOrderId) => {
    try {
      const result = await axios.get("/api/single_batch/" + purchaseOrderId);
      setProducts(result.data.data);
      setProduct(result.data.po);
      setInvoice(result.data.invoice);
    } catch (error) {}
  };

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <div style={{ width: "90px" }}>
          <CButton color="primary" onClick={() => openModal(row.id)}>
            <VisibilityIcon />
          </CButton>
        </div>
      ),
      width: "90px",
    },

    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Order No",
      selector: (row) => row.order_number,
      width: "120px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      width: "250px",
    },

    {
      name: "Supplier Bill No",
      selector: (row) => row.bill_number,
      width: "160px",
    },

    {
      name: "Purchase Bill No",
      selector: (row) => row.purchase_bill_no,
      width: "160px",
    },

    {
      name: "City",
      selector: (row) => row.city,
      width: "130px",
    },

    {
      name: "Date",
      selector: (row) => row.date,
      width: "120px",
    },

    {
      name: "MRP",
      selector: (row) => Number(row.grand_total).toFixed(2),
      width: "120px",
    },

    {
      name: "Remarks",
      selector: (row) => row.remarks,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <CButton color="primary" onClick={() => openEditModal(row.id)}>
            <EditIcon />
          </CButton>
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  const columns2 = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "60px",
    },

    {
      name: "Product Id",
      selector: (row) => row.product?.id,
      width: "100px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product?.product_name
          ? row.product?.product_name
          : "Not Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "170px",
    },

    {
      name: "Product Type",
      selector: (row) => row.product_type,
      width: "120px",
    },

    {
      name: "Pack",
      selector: (row) => row.product?.pack_of,
      width: "120px",
    },

    {
      name: "HSN",
      selector: (row) => row.product?.hsn,
      width: "140px",
    },

    {
      name: "Batch",
      selector: (row) => row.batch_number,
      width: "140px",
    },

    {
      name: "Expiry",
      selector: (row) => row.expiry,
      width: "90px",
    },

    {
      name: "Sale Rate",
      selector: (row) => Number(row.sale_rate).toFixed(2),

      width: "120px",
    },

    {
      name: "Scheme Name",
      selector: (row) => row.scheme_discount_name,
      width: "140px",
    },

    {
      name: "Scheme Discount%",
      selector: (row) => row.discount,
      width: "140px",
    },

    {
      name: "Add. Discount%",
      selector: (row) => row.additional_discount,
      width: "140px",
    },

    {
      name: "Remove Discount%",
      selector: (row) => row.remove_discount,
      width: "140px",
    },

    {
      name: "Add. Amount",
      selector: (row) => row.additional_amount,
      width: "140px",
    },

    {
      name: "GST%",
      selector: (row) => row.product?.gst,
      width: "90px",
    },

    {
      name: "Bill Q",
      selector: (row) => row.quantity,
      width: "80px",
    },

    {
      name: "Purchase R",
      selector: (row) => Number(row.final_purchase_rate).toFixed(2),

      width: "120px",
    },
  ];

  const columns3 = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "product_name",
      selector: (row) => row.product_name,
      sortable: true,
      width: "180px",
    },

    {
      name: "batch Number",
      selector: (row) => row.batch_number,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Rate",
      selector: (row) => row.purchase_rate,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Order",
      selector: (row) => row.purchase_order,
      sortable: true,
      width: "180px",
    },

    {
      name: "Sale Rate",
      selector: (row) => row.sale_rate,
      sortable: true,
      width: "180px",
    },
    {
      name: "Bill Number",
      selector: (row) => row.bill_number,
      sortable: true,
      width: "180px",
    },
    
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
      width: "220px",
    },
    
    {
      name: "Purchase Date",
      selector: (row) => row.purchase_date,
      sortable: true,
      width: "180px",
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(orders);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const filteredItems = orders.filter(
    (item) =>
      item.supplier_name &&
      item.supplier_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClearinput = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(orders);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <CModal
        size="fullscreen"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="ViewPurchaseModal"
      >
        <CModalHeader closeButton>
          <CModalTitle style={{ marginLeft: "75px" }}>
            <strong>View Purchase Order </strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Purchase Order ID: {selectedPurchaseOrderId}
          <MDBContainer className="px-5 mt-4">
            <MDBRow>
              <MDBCol lg="12">
                <MDBCard className="mb-2">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol
                        sm="3"
                        className="border-right"
                        style={{ fontSize: "14px" }}
                      >
                        <MDBCardText>
                          <span className="fw-600">
                            <strong
                              className="text-capital"
                              style={{ fontSize: "20px" }}
                            >
                              {product.supplier_name}
                            </strong>
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">
                            {product.supplier_mobile}
                          </span>
                        </MDBCardText>
                        <MDBCardText>
                          <span className="fw-600">
                            {product.supplier_address +
                              " , " +
                              product.supplier_city +
                              " , " +
                              product.supplier_district +
                              " , " +
                              product.supplier_pincode}
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          Bill No. -
                          <span className="fw-600">{product.bill_number}</span>
                        </MDBCardText>
                      </MDBCol>

                      <MDBCol
                        sm="3"
                        className="border-right"
                        style={{ fontSize: "14px" }}
                      >
                        <MDBCardText>
                          Order No :{" "}
                          <span className="fw-600">{product.order_number}</span>
                        </MDBCardText>
                        <MDBCardText>
                          DL No. :{" "}
                          <span className="fw-600">
                            {product.supplier_drug_license}
                          </span>
                        </MDBCardText>
                        <MDBCardText>
                          GST No. :{" "}
                          <span className="fw-600">
                            {product.supplier_gst_number}
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          Date : <span className="fw-600">{product.date}</span>
                        </MDBCardText>
                      </MDBCol>

                      <MDBCol
                        sm="3"
                        className="border-right"
                        style={{ fontSize: "14px" }}
                      >
                        <MDBCardText>
                          <span className="fw-600">
                            <strong style={{ fontSize: "20px" }}>
                              {setting.company_name}
                            </strong>
                          </span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.email}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.phone}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.website}</span>
                        </MDBCardText>

                        <MDBCardText>
                          <span className="fw-600">{setting.address}</span>
                        </MDBCardText>
                      </MDBCol>

                      <MDBCol className="d-flex align-items-center" sm="3">
                        <img
                          src={image}
                          className="rounded mx-auto d-block"
                          alt="Logo"
                          width={180}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr />

                    <MDBRow className="mt-1">
                      <div style={{ margin: "1%" }}>
                        <DataTable
                          columns={columns2}
                          title="Products Detail"
                          data={products}
                          defaultSortFieldId
                          fixedHeader
                          responsive
                          allowOverflow
                          subHeaderAlign="right"
                        />
                      </div>

                      <MDBRow className="mt-2">
                        <hr></hr>
                        <MDBRow className="mt-2">
                          <MDBCol sm="5"></MDBCol>
                          <MDBCol sm="4"></MDBCol>

                          <MDBCol sm="3">
                            <MDBCardText style={{ fontSize: "25px" }}>
                              Total :{" "}
                              <span
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "700",
                                }}
                              >
                                ₹ {parseFloat(product.grand_total).toFixed(2)}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <MDBCol sm="9">
                          <MDBCardText>
                            Download Invoice :
                            <Link to={invoice}>
                              <button
                                className="bg-white"
                                style={{ border: "none" }}
                              >
                                <DownloadIcon />
                              </button>
                            </Link>
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </CModalBody>
      </CModal>

      <CModal
        size="xl"
        visible={editModalVisible}
        aria-labelledby="ViewPurchaseModal"
      >
        <div class="d-flex justify-content-between m-2">
          <strong>Edit Purchase ID: {currentEditId}</strong>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </div>

        <CModalBody>
          <div className="bg-gre " style={{ padding: "37px" }}>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong> Purchase Product</strong>
                </CCardHeader>
                <CCardBody>
                  <CForm className="row g-3">
                    <CCol md={4} className="mb-2">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Supplier Name
                      </CFormLabel>
                      <CFormSelect
                        value={suppliername}
                        required
                        onChange={(e) => setsuppliername(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option>Select Supplier</option>

                        {suppliernamelist?.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.head_name + " - " + item.city}
                          </option>
                        ))}
                      </CFormSelect>
                    </CCol>

                    <CCol md={4} className="mb-2">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Supplier Bill No
                      </CFormLabel>
                      <CFormInput
                        value={billnumber}
                        required
                        onChange={(e) => setBillnumber(e.target.value)}
                        id="inputAddress"
                        placeholder="Supplier Bill No"
                      />
                    </CCol>

                    <CCol md={4} className="mb-2">
                      <CFormLabel htmlFor="purchaseBillNo">
                        Purchase Bill No
                      </CFormLabel>
                      <CFormInput
                        value={purchaseBillNo}
                        required
                        readOnly
                        id="purchaseBillNo"
                        placeholder="Purchase Bill No"
                      />
                    </CCol>

                    <CCol md={4} className="mb-2">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        City
                      </CFormLabel>

                      <CFormInput
                        value={city}
                        required
                        onChange={(e) => setCity(e.target.value)}
                        id="inputAddress"
                        placeholder="City"
                      />
                    </CCol>

                    <CCol md={4} className="mb-2 ">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Date
                      </CFormLabel>

                      <CCol></CCol>
                      <DatePicker
                        selected={date}
                        required
                        onChange={(selectedDate) => {
                          if (selectedDate) {
                            const formattedDate = formatDate1(selectedDate);
                            setDate(formattedDate);
                          } else {
                            setDate("");
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                        timeInputLabel="Time:"
                        showDateInput
                        showIcon
                      />
                    </CCol>

                    <CCol md={4} className="mb-2">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Remark
                      </CFormLabel>
                      <CFormInput
                        value={remark}
                        required
                        onChange={(e) => setRemark(e.target.value)}
                        id="inputAddress"
                        placeholder="Remark"
                      />
                    </CCol>

                    <CCol md={3}></CCol>
                    <CCol md={6}>
                      <hr />
                    </CCol>
                    <CCol md={3}></CCol>

                    {productID.map((items, i) => {
                      return (
                        <CCard key={i}>
                          <div className="row g-3">
                            <div className="mt-2 text-right">
                              {productID[i].selectedProductId && (
                                <button
                                  className="btn btn-primary mx-3 mt-3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const selectedProductId =
                                      productID[i].selectedProductId;

                                    if (selectedProductId) {
                                      setSelectedProductId(selectedProductId);
                                      setVisible(!visible);
                                      fetchData(selectedProductId);
                                    } else {
                                    }
                                  }}
                                >
                                  Previous Purchase Product
                                </button>
                              )}

                              {visible && (
                                <CModal
                                  backdrop="static"
                                  visible={visible}
                                  onClose={() => setVisible(false)}
                                  aria-labelledby="StaticBackdropExampleLabel"
                                >
                                  {productDetails.length === 0 ? (
                                    <CCardHeader>
                                      <CCardTitle className="m-3">
                                        Product Not Found
                                      </CCardTitle>
                                    </CCardHeader>
                                  ) : (
                                    <div>
                                      <CModalBody>
                                        <h5>
                                          Previous Purchase Product Details
                                        </h5>
                                      </CModalBody>
                                      {productDetails &&
                                      productDetails.length > 0 ? (
                                        <DataTable
                                          defaultSortFieldId={1}
                                          search
                                          columns={columns3}
                                          data={productDetails}
                                          pagination
                                          paginationServer
                                          paginationTotalRows={totalRows}
                                          paginationPerPage={perPage}
                                          progressComponent={<CustomeLoader />}
                                          paginationRowsPerPageOptions={[
                                            10, 20, 30, 50, 100,
                                          ]}
                                          onChangePage={(page) => setPage(page)}
                                          onChangeRowsPerPage={(
                                            newPerPage,
                                            page
                                          ) =>
                                            handleRowsPerPageChange(
                                              newPerPage,
                                              page
                                            )
                                          }
                                        />
                                      ) : (
                                        <div>No Data Available</div>
                                      )}
                                    </div>
                                  )}

                                  <CModalFooter>
                                    <CButton
                                      color="secondary"
                                      onClick={() => setVisible(false)}
                                    >
                                      Close
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              )}

                              {productID[i].selectedProductId && (
                                <button
                                  className="btn btn-primary mx-3 mt-3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const selectedProductId =
                                      productID[i].selectedProductId;

                                    if (selectedProductId) {
                                      setSelectedProductId(selectedProductId);
                                      setVisibleSaleProduct(
                                        !visibleSaleProduct
                                      );
                                      fetchSaleProductData(selectedProductId);
                                    } else {
                                    }
                                  }}
                                >
                                  Previous Sale Product
                                </button>
                              )}
                              {visibleSaleProduct && (
                                <CModal
                                  backdrop="static"
                                  visible={visibleSaleProduct}
                                  onClose={() => setVisibleSaleProduct(false)}
                                  aria-labelledby="StaticBackdropExampleLabel"
                                >
                                  {saleProductDetails.length === 0 ? (
                                    <CCardHeader>
                                      <CCardTitle className="m-3">
                                        Product Not Found
                                      </CCardTitle>
                                    </CCardHeader>
                                  ) : (
                                    <div>
                                      <CModalBody>
                                        <h5>Previous Sale Product Details</h5>
                                      </CModalBody>
                                      {saleProductDetails &&
                                      saleProductDetails.length > 0 ? (
                                        <DataTable
                                          defaultSortFieldId={1}
                                          search
                                          columns={columns3}
                                          data={saleProductDetails}
                                          pagination
                                          paginationServer
                                          paginationTotalRows={totalRows}
                                          paginationPerPage={perPage}
                                          progressComponent={<CustomeLoader />}
                                          paginationRowsPerPageOptions={[
                                            10, 20, 30, 50, 100,
                                          ]}
                                          onChangePage={(page) => setPage(page)}
                                          onChangeRowsPerPage={(
                                            newPerPage,
                                            page
                                          ) =>
                                            handleRowsPerPageChange(
                                              newPerPage,
                                              page
                                            )
                                          }
                                        />
                                      ) : (
                                        <div>No Data Available</div>
                                      )}
                                    </div>
                                  )}

                                  <CModalFooter>
                                    <CButton
                                      color="secondary"
                                      onClick={() =>
                                        setVisibleSaleProduct(false)
                                      }
                                    >
                                      Close
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              )}
                            </div>
                            {showSelectedProduct && (
                              <span>
                                <CFormLabel htmlFor="exampleFormControlTextarea1">
                                  Selected Product :{" "}
                                </CFormLabel>

                                {items.product
                                  ? items.product.product_name
                                  : ""}
                              </span>
                            )}
                            <CCol md={3}>
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Product Name
                              </CFormLabel>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <input
                                  list={`productList${i}`}
                                  ref={(el) => (inputRefs.current[i] = el)}
                                  onInput={(e) => handleInputSearch(e, i)}
                                  onChange={(e) =>
                                    handleProductSelect(e.target.value, i)
                                  }
                                  value={selectedProducts[i]}
                                  placeholder="Select Product"
                                  className="product-input"
                                  style={{
                                    width: "236px",
                                    height: "40px",
                                    paddingLeft: "10px",
                                    paddingRight: "40px",
                                  }}
                                />
                                {selectedProducts[i] ? (
                                  <ClearIcon
                                    onClick={() => handleClear(i)}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                      color: "#555",
                                    }}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => handleArrowClick(i)}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                      color: "#555",
                                    }}
                                  />
                                )}
                                <datalist id={`productList${i}`}>
                                  {productlist.map((product) => (
                                    <option
                                      key={product.id}
                                      value={product.product_name}
                                    />
                                  ))}
                                </datalist>
                              </div>
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Product Type
                              </CFormLabel>

                              <CFormInput
                                readOnly
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="product_type"
                                value={items.product_type}
                                placeholder="Product Type"
                              />
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Remaining Quantity
                              </CFormLabel>

                              <CFormInput
                                readOnly
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="remaining_quantity"
                                value={items.remaining_quantity}
                                placeholder="Remaining Quantity"
                              />
                            </CCol>

                            <CCol md={3}>
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Batch List
                              </CFormLabel>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <input
                                  value={items.batch_number}
                                  name="batch_number"
                                  required
                                  list="batchList"
                                  ref={inputRef1}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    const selectedName = batchList.find(
                                      (batch) =>
                                        batch.batch_number === selectedValue
                                    )?.name;
                                    handleBatchSelect(
                                      selectedValue,
                                      selectedName,
                                      i
                                    );
                                  }}
                                  placeholder="Select Batch"
                                  className="batch-input"
                                  style={{
                                    width: "236px",
                                    height: "40px",
                                    paddingLeft: "10px",
                                    paddingRight: "40px",
                                  }}
                                />
                                <ArrowDropDownIcon
                                  onClick={() => handleArrowClick1()}
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    color: "#555",
                                  }}
                                />
                                <div
                                  style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <datalist id="batchList">
                                    {batchList.map((values, index) => (
                                      <option
                                        key={index}
                                        value={values.batch_number}
                                      />
                                    ))}
                                  </datalist>
                                </div>
                              </div>
                            </CCol>

                            <CCol md={3} className="mb-2">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Expiry Date: {items.expiry}
                              </CFormLabel>
                              <div>
                                <DatePicker
                                  selected={expiryDates[i]}
                                  required
                                  minDate={
                                    new Date(
                                      today.getFullYear(),
                                      today.getMonth() + 1
                                    )
                                  }
                                  onChange={(date) => {
                                    const newExpiryDates = [...expiryDates];
                                    newExpiryDates[i] = date || "";
                                    setExpiryDates(newExpiryDates);
                                    handleInput(
                                      {
                                        target: {
                                          name: "expiry",
                                          value: date ? formatDate(date) : "",
                                        },
                                      },
                                      i
                                    );
                                  }}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  autoComplete="off"
                                  showIcon
                                />

                                <input
                                  type="text"
                                  className="form-control"
                                  style={{ display: "none", width: "100%" }}
                                  value={
                                    expiryDates[i]
                                      ? formatDate(expiryDates[i])
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    const [month, year] = value.split("/");
                                    const newDate = new Date(
                                      `${year}-${month}-01`
                                    );
                                    if (!isNaN(newDate.getTime())) {
                                      const newExpiryDates = [...expiryDates];
                                      newExpiryDates[i] = newDate;
                                      setExpiryDates(newExpiryDates);
                                      handleInput(
                                        {
                                          target: {
                                            name: "expiry",
                                            value: value,
                                          },
                                        },
                                        i
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Product Quantity
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                name="quantity"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 ]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                placeholder="Product Quantity"
                                value={items.quantity}
                              />
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                MRP
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="mrp"
                                value={items.mrp}
                                placeholder="₹"
                              />
                            </CCol>

                            <CCol md={3} className="mb-3">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Sale Rate
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="sale_rate"
                                placeholder="₹"
                                value={items.sale_rate}
                              />
                            </CCol>

                            <CCol md={3} className="mb-3">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Purchase Rate
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="purchase_rate"
                                placeholder="₹"
                                value={items.purchase_rate}
                              />
                            </CCol>

                            <CCol md={3} className="mb-3">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Scheme Name
                              </CFormLabel>
                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                id="inputAddress"
                                name="scheme_discount_name"
                                placeholder="Scheme Name"
                                value={items.scheme_discount_name}
                              />
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Scheme Discount %
                              </CFormLabel>

                              <CFormInput
                                value={items.discount}
                                required
                                type="text"
                                id="inputAddress"
                                name="discount"
                                placeholder="₹"
                                onChange={(e) => {
                                  const sanitizedValue = e.target.value.replace(
                                    /[^\d.]/g,
                                    ""
                                  );

                                  const [integerPart, decimalPart] =
                                    sanitizedValue.split(".");

                                  const truncatedInteger = integerPart.slice(
                                    0,
                                    2
                                  );

                                  let newValue = truncatedInteger;
                                  if (decimalPart !== undefined) {
                                    newValue += "." + decimalPart;
                                  }

                                  e.target.value = newValue;

                                  handleInput(e, i);
                                }}
                              />
                            </CCol>

                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Add Discount (-) %
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                onInput={(e) => {
                                  let inputValue = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  const parts = inputValue.split(".");
                                  if (parts.length > 1) {
                                    parts[0] = parts[0].slice(0, 2);
                                    parts[1] = parts[1].slice(0, 2);
                                    inputValue = parts.join(".");
                                  } else {
                                    inputValue = inputValue.slice(0, 2);
                                  }
                                  inputValue = inputValue.slice(0, 5);
                                  e.target.value = inputValue;
                                }}
                                id="inputAddress"
                                name="additional_discount"
                                placeholder="₹"
                                onChange={(e) => {
                                  handleInput(e, i);
                                  items.remove_discount = "";
                                }}
                                value={items.additional_discount}
                                disabled={
                                  parseFloat(items.remove_discount) > 0
                                    ? "disabled"
                                    : ""
                                }
                              />
                            </CCol>
                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Remove Discount (+) %
                              </CFormLabel>

                              <CFormInput
                                value={items.remove_discount}
                                type="text"
                                onInput={(e) => {
                                  let inputValue = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  const parts = inputValue.split(".");
                                  if (parts.length > 1) {
                                    parts[0] = parts[0].slice(0, 2);
                                    parts[1] = parts[1].slice(0, 2);
                                    inputValue = parts.join(".");
                                  } else {
                                    inputValue = inputValue.slice(0, 2);
                                  }
                                  inputValue = inputValue.slice(0, 5);
                                  e.target.value = inputValue;
                                }}
                                id="inputAddress"
                                name="remove_discount"
                                placeholder="₹"
                                onChange={(e) => {
                                  handleInput(e, i);
                                  items.additional_discount = "";
                                }}
                                disabled={
                                  parseFloat(items.additional_discount) > 0
                                    ? "disabled"
                                    : ""
                                }
                              />
                            </CCol>
                            <CCol md={3} className="">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                GST %
                              </CFormLabel>

                              <CFormInput
                                readOnly
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="add_gst"
                                value={items.add_gst}
                                placeholder="₹"
                              />
                            </CCol>

                            <CCol md={3} className="mb-5">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                New Purchase Rate
                              </CFormLabel>
                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="final_purchase_rate"
                                placeholder="₹"
                                value={items.final_purchase_rate}
                              />
                            </CCol>

                            <CCol md={3} className="mb-3">
                              <CFormLabel htmlFor="exampleFormControlTextarea1">
                                Additional Amount
                              </CFormLabel>

                              <CFormInput
                                required
                                type="text"
                                onChange={(e) => handleInput(e, i)}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9 .]/g,
                                    ""
                                  );
                                }}
                                id="inputAddress"
                                name="additional_amount"
                                placeholder="₹"
                                value={items.additional_amount}
                              />
                            </CCol>

                            <CCol md={8}></CCol>
                            <CCol md={6} key={i}>
                              <h5 style={{}}>
                                Total: ₹{" "}
                                {parseFloat(
                                  calculateTotal(
                                    items.final_purchase_rate,
                                    items.quantity,
                                    items.additional_amount
                                  )
                                ).toFixed(2)}
                              </h5>
                            </CCol>

                            <CCol md={12} className="">
                              {productID.length > 1 && (
                                <button
                                  className="bg-white mt-5"
                                  style={{ border: "none" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemove(i);
                                  }}
                                >
                                  <DeleteIcon className="text-red" />
                                </button>
                              )}
                            </CCol>

                            <CCol md={12} className="">
                              <hr />
                            </CCol>
                          </div>
                        </CCard>
                      );
                    })}
                    <div className="text-center"></div>

                    <CCol md={10}>
                      <h5>
                        Grand Total: ₹ {parseFloat(grandTotal).toFixed(2)}
                      </h5>
                    </CCol>

                    <CCol md={2}>
                      <button
                        className=" btn btn-outline-danger mb-2"
                        onClick={(e) => handleRow(e)}
                      >
                        + Add More
                      </button>
                    </CCol>

                    <CCol md={12} className="text-center">
                      <button
                        type="submit"
                        className="btn btn-danger my-3 "
                        onClick={submitForm}
                      >
                        Submit
                      </button>
                    </CCol>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CModalBody>
      </CModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Purchase Order</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row ">
                      <div className="col-sm-6 mt-1">
                        <input
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClearinput}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/add-purchase-order"
                            className="btn btn-primary "
                          >
                            + Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination={10}
                      allowOverflow
                      subHeaderAlign="right"
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseOtable;
