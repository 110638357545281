import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CCard,
  CCardBody,
  CCol,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CForm,
  CFormSelect,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCardTitle,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import CustomeLoader from "../../components/CustomeLoader";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { InputLabel } from "@material-ui/core";

const EditPurchaseOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [expiryDates, setExpiryDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const today = new Date();

  const [suppliername, setsuppliername] = useState("");
  const [billnumber, setBillnumber] = useState("");
  // const [date, setDate] = useState("");
  const [date, setDate] = useState(null);
  const [purchaseBillNo, setPurchaseBillNo] = useState("");
  const [city, setCity] = useState("");
  const [remark, setRemark] = useState("");

  // const [productID, setProductId] = useState([
  //   {
  //     product_id: "",
  //     qty: "",
  //     // freeq: "",
  //     cureentdate: "",
  //     purchase_rate: "",
  //     batch_number: "",
  //     retailer_rate: "",
  //     mrp: "",
  //     sale_rate: "",
  //     selectedProductId: "",
  //     discount: "",
  //     gst: "",
  //     add_gst: "",
  //     expiry_date: "",
  //     addAmount: "",
  //     remove_discount: "",
  //     product_type: "",
  //     // calculateTotal: "",
  //   },
  // ]);
  const [productID, setProductId] = useState([
    {
      product: {
        product_name: "",
      },
      product_id: "",
      qty: "",
      cureentdate: "",
      purchase_rate: "",
      batch_number: "",
      retailer_rate: "",
      mrp: "",
      sale_rate: "",
      selectedProductId: "",
      discount: "",
      gst: "",
      add_gst: "",
      expiry_date: "",
      addAmount: "",
      remove_discount: "",
      product_type: "",
      scheme_discount_name: "",
    },
  ]);

  const [productlist, setproductlist] = useState([]);

  const [visibleSaleProduct, setVisibleSaleProduct] = useState(false);
  const [saleProductDetails, setSaleProductDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [visible, setVisible] = useState(false);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(true);

  const [maxOrder, setMaxOrder] = useState("");
  const [purchaseQuantity, setPurchaseQuantity] = useState("");

  const [batchList, setBatchList] = useState([]); // Define batchList state variable

  const [selectedBatch, setSelectedBatch] = useState([]);
  const [selectedBatchName, setSelectedBatchName] = useState([]);

  const [purchaseRate, setPurchaseRate] = useState("");
  const [purchaseRates, setPurchaseRates] = useState([]);

  const [products, setProducts] = useState([]);

  const [productDetail, setProductDetail] = useState([]);

  const [grandTotal, setGrandTotal] = useState("");
  const [items, setItems] = useState({ product: null });
  // const [showSelectedProduct, setShowSelectedProduct] = useState(true);
  // Assuming you have a separate state for controlling the visibility of selected product name for each field
const [showSelectedProduct, setShowSelectedProduct] = useState(Array(productlist.length).fill(true));
  useEffect(() => {
    axios
      .get("/api/single_batch/" + id)
      .then((res) => {
        console.log(id);
        const { po, data } = res.data;
        setsuppliername(res.data.po.supplier_id);
        console.log(res.data.po.supplier_id);

        setBillnumber(res.data.po.bill_number);
        setPurchaseBillNo(res.data.po.purchase_bill_no);
        setCity(res.data.po.supplier_city);
        setDate(res.data.po.date);
        setRemark(res.data.po.remarks);
        setProductId(res.data.data);
        // debugger
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleBatchSelect = (value, name, i) => {
    const updatedSelectedBatch = [...selectedBatch];
    updatedSelectedBatch[i] = value;
    setSelectedBatch(updatedSelectedBatch);

    const updatedSelectedBatchName = [...selectedBatchName];
    updatedSelectedBatchName[i] = name;
    setSelectedBatchName(updatedSelectedBatchName);

    handleInput(
      {
        target: {
          name: "batch_number",
          value: value,
        },
      },
      i
    );
  };

  const inputRef = useRef(null);
  const handleArrowClick = () => {
    inputRef.current.focus();
  };

  const inputRef1 = useRef(null);
  const handleArrowClick1 = () => {
    inputRef1.current.focus();
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/products")
      .then((res) => {
        setproductlist(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const fetchBatchList = (selectedProductId) => {
    if (selectedProductId) {
      axios
        .get(`/api/purchase_product_batch/${selectedProductId}`)
        .then((res) => {
          setBatchList(res.data.data); // Assuming res.data.data is the array of batch numbers
        })
        .catch((err) => {
          console.error("Error fetching batch data:", err);
          setBatchList([]); // Set batch list to empty array on error
        });
    }
  };

  useEffect(() => {
    // Fetch batch list immediately after component mount if a product is already selected
    if (selectedProductId) {
      fetchBatchList(selectedProductId);
    }
  }, [selectedProductId]);
  // Calculate total purchase amount
  const totalPurchaseAmount = productID
    .reduce((total, product) => {
      return (
        total + parseFloat(product.purchase_rate) * parseFloat(product.qty)
      );
    }, 0)
    .toFixed(2);
  const [suppliernamelist, setSuppliernamelist] = useState([]);

  useEffect(() => {
    axios.get("/api/get_supplier").then((res) => {
      setSuppliernamelist(res.data.data);
    });
  }, []);

  const calculateTotal = (final_purchase_rate, quantity, additional_amount) => {
    // Convert parameters to numbers
    const rate = parseFloat(final_purchase_rate);
    const quantity1 = parseFloat(quantity);
    const additionalAmount = parseFloat(additional_amount) || 0;

    // Check if any of the parameters are not valid numbers
    if (isNaN(rate) || isNaN(quantity1) || isNaN(additionalAmount)) {
      console.error("One or more parameters are not valid numbers.");
      return 0;
    }

    // Calculate the total
    const total = rate * quantity1 + additionalAmount;

    // Return the total as a number
    return total;
  };

  useEffect(() => {
    initializeData(); // Call the initialization function when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  useEffect(() => {
    // Calculate initial grand total whenever productId changes
    const initialTotal = productID.reduce((acc, curr) => {
      return (
        acc +
        calculateTotal(
          parseFloat(curr.final_purchase_rate),
          parseInt(curr.quantity),
          parseFloat(curr.additional_amount)
        )
      );
    }, 0);

    setGrandTotal(initialTotal);
  }, [productID]); // This effect runs whenever productId changes

  const initializeData = () => {
    // Your initialization logic here
    // For example, if you want to initialize with one empty product object:
    setProductId([
      { final_purchase_rate: 0, quantity: 0, additional_amount: 0 },
    ]);
    setPurchaseRates([]);
  };

  const handleInput = (e, i) => {
    const { name, value } = e.target;

    setProductId((prevProductID) => {
      const updatedProductID = [...prevProductID];
      if (!updatedProductID[i]) {
        // If the product data at index 'i' is not initialized, initialize it
        updatedProductID[i] = {
          final_purchase_rate: 0,
          quantity: 0,
          additional_amount: 0,
        }; // or any default values
      }
      updatedProductID[i] = {
        ...updatedProductID[i],
        [name]: value,
      };
      const totalAmount = updatedProductID.reduce((acc, curr) => {
        return (
          acc +
          calculateTotal(
            parseFloat(curr.final_purchase_rate),
            parseInt(curr.quantity),
            parseFloat(curr.additional_amount)
          )
        );
      }, 0);
      setGrandTotal(totalAmount);
      if (
        name === "discount" ||
        name === "additional_discount" ||
        name === "add_gst" ||
        name === "purchase_rate" ||
        name === "remove_discount" // Include remove_discount here
      ) {
        const discount = parseFloat(updatedProductID[i].discount) || 0;
        const additional_discount =
          parseFloat(updatedProductID[i].additional_discount) || 0;
        const remove_discount =
          parseFloat(updatedProductID[i].remove_discount) || 0; // Retrieve remove_discount
        const addGst = parseFloat(updatedProductID[i].add_gst) || 0;
        const purchaseRate = parseFloat(updatedProductID[i].purchase_rate) || 0;

        let newPurchaseRate = purchaseRate - (purchaseRate * discount) / 100;
        newPurchaseRate -= (newPurchaseRate * additional_discount) / 100;
        newPurchaseRate += (newPurchaseRate * remove_discount) / 100; // Apply remove_discount here
        newPurchaseRate *= 1 + addGst / 100;

        updatedProductID[i] = {
          ...updatedProductID[i],
          final_purchase_rate: newPurchaseRate.toFixed(2),
        };
        // if(additional_discount >0 && remove_discount ==0)
        //   {
        //     return additional_discount
        //   }else if (remove_discount> 0 && additional_discount == 0)
        //   {
        //     return remove_discount
        //   }

        // Update purchase rates array if needed
        const newPurchaseRates = [...purchaseRates];
        newPurchaseRates[i] = newPurchaseRate.toFixed(2);
        setPurchaseRates(newPurchaseRates);
      }

      return updatedProductID;
    });
  };

// Modified handleProductSelect function
const handleProductSelect = (selectedProductName, i) => {
  const selectedProduct = productlist.find(
    (product) => product.product_name === selectedProductName
  );

  if (selectedProduct) {
    console.log("Selected Product:", selectedProduct);
    console.log("Index:", i);

    setItems((prevItems) => {
      const updatedItems = { ...prevItems };
      updatedItems.product = selectedProduct;
      return updatedItems;
    });

    setProductId((prevProductID) => {
      const updatedProductID = [...prevProductID];
      updatedProductID[i] = {
        ...updatedProductID[i],
        product: selectedProduct, // Update with the selected product object
        selectedProductId: selectedProduct.id, // Update selectedProductId with the selected product ID
        product_id: selectedProduct.id, // Set product_id as well
      };
      setProductDetails([]);
      setSaleProductDetails([]);
      fetchData(selectedProduct.id, i);
      // Fetch the batch list for the selected product ID immediately after selecting the product
      fetchBatchList(selectedProduct.id);

      // Hide the <p> element after selecting a product for this specific field
      const updatedShowSelectedProduct = [...showSelectedProduct];
      updatedShowSelectedProduct[i] = false;
      setShowSelectedProduct(updatedShowSelectedProduct);

      return updatedProductID;
    });
  }
};
  
  

  // const handleProductSelect = (selectedProductName, i) => {
  //   const selectedProduct = productlist.find(
  //     (product) => product.product_name === selectedProductName
  //   );
  //   if (selectedProduct) {
  //     console.log("Selected Product:", selectedProduct);
  //     console.log("Index:", i);

  //     setProductId((prevProductID) => {
  //       const updatedProductID = [...prevProductID];
  //       updatedProductID[i] = {
  //         ...updatedProductID[i],
  //         product: selectedProduct, // Update with the selected product object
  //         selectedProductId: selectedProduct.id, // Update selectedProductId with the selected product ID
  //         product_id: selectedProduct.id, // Set product_id as well
  //       };
  //       setProductDetails([]);
  //       setSaleProductDetails([]);
  //       fetchData(selectedProduct.id, i);
  //       // Fetch the batch list for the selected product ID immediately after selecting the product
  //       fetchBatchList(selectedProduct.id);

  //       return updatedProductID;
  //     });
  //   }
  // };

  // const handleProductSelect = (selectedProductName, i) => {
  //   const selectedProduct = productlist.find(
  //     (product) => product.product_name === selectedProductName
  //   );
  //   if (selectedProduct) {
  //     const { id, product_name } = selectedProduct; // Extract product_name
  //     console.log("Selected Product:", selectedProduct);
  //     console.log("Selected Product ID:", id);
  //     console.log("Index:", i);

  //     setProductId((prevProductID) => {
  //       const updatedProductID = [...prevProductID];
  //       updatedProductID[i] = {
  //         ...updatedProductID[i],
  //         selectedProductId: id, // Update selectedProductId with the selected product ID
  //         product_id: id, // Set product_id as well
  //       };
  //       setProductDetails([]);
  //       setSaleProductDetails([]);
  //       fetchData(id, i);
  //       // Fetch the batch list for the selected product ID immediately after selecting the product
  //       fetchBatchList(id);

  //       return updatedProductID;
  //     });

  //     // Update the value of the input field with the selected product name
  //     const updatedProductID = [...productID];
  //     updatedProductID[i] = {
  //       ...updatedProductID[i],
  //       product: {
  //         ...updatedProductID[i].product,
  //         product_name: product_name,
  //       },
  //       product_id: id, // Set product_id for the product
  //     };
  //     setProductId(updatedProductID);
  //   }
  // };

  const fetchData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_purchase_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {}; // Ensure data object exists
      const productData = responseData.data || []; // Ensure data array exists

      setMaxOrder(res.data.product_data.max_order);
      setPurchaseQuantity(res.data.product_data.total_purchase_quantity);

      setProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      // Check if product_data exists in the response
      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],

            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };
  const fetchSaleProductData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_sale_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {}; // Ensure data object exists
      const productData = responseData.data || []; // Ensure data array exists

      setSaleProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      // Check if product_data exists in the response
      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],

            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };
  // ----------------------------------------------FETCH ROW FOR MULTIPLE PRODUCT---------------------------------------------
  // const handleRow = (e) => {
  //   // Clear selected product and set empty batch list
  //   setSelectedProductId("");
  //   setBatchList([]);
  //   setProductId((prevProductID) => {
  //     const newProductId = [
  //       ...prevProductID,
  //       {
  //         // product_id: productId,
  //         qty: "",
  //         cureentdate: "",
  //         purchase_rate: "",
  //         batch_number: "",
  //         retailer_rate: "",
  //         mrp: "",
  //         sale_rate: "",
  //         discount: "",
  //         gst: "",
  //         add_gst: "",
  //         expiry_date: "",
  //         addAmount: "",
  //         remove_discount: "",
  //         type: "",
  //       },
  //     ];

  //     // Update expiry dates and purchase rates
  //     setExpiryDates([...expiryDates, null]); // Add null for the new item
  //     setPurchaseRates([...purchaseRates, ""]); // Add empty string for the new item
  //     console.log(prevProductID);
  //     return newProductId;
  //   });

  //   e.preventDefault();
  // };

  // const handleRow = () => {
  //   setProductId((prevProductID) => [
  //     ...prevProductID,
  //     {
  //       product: {
  //         product_name: "", // Initially empty
  //       },
  //       product_id: "",
  //       qty: "",
  //       cureentdate: "",
  //       purchase_rate: "",
  //       batch_number: "",
  //       retailer_rate: "",
  //       mrp: "",
  //       sale_rate: "",
  //       selectedProductId: "",
  //       discount: "",
  //       gst: "",
  //       add_gst: "",
  //       expiry_date: "",
  //       addAmount: "",
  //       remove_discount: "",
  //       product_type: "",
  //     },
  //   ]);
  // };

  const handleRow = (e, productId) => {
    // Clear selected product and set empty batch list
    setSelectedProductId("");
    setBatchList([]);
    // console.log("Adding more rows for product ID:", productId);

    setProductId((prevProductID) => {
      const newProductId = [
        ...prevProductID,
        {
          product: {
            product_name: "", // Initially empty
            // product_id:""
          },
          product_id: productId,
          qty: "",
          cureentdate: "",
          purchase_rate: "",
          batch_number: "",
          retailer_rate: "",
          mrp: "",
          sale_rate: "",
          // selectedProductId: "",
          discount: "",
          gst: "",
          add_gst: "",
          expiry_date: "",
          addAmount: "",
          remove_discount: "",
          product_type: "",
          scheme_discount_name: "",
        },
      ];
      // Update expiry dates and purchase rates
      setExpiryDates([...expiryDates, null]); // Add null for the new item
      setPurchaseRates([...purchaseRates, ""]); // Add empty string for the new item
      console.log(newProductId);

      return newProductId;
    });

    e.preventDefault();
  };

  // Render your form with the productID state

  // const handleRow = (e) => {
  //   e.preventDefault();

  //   const newProduct = {
  //     product_id: id,
  //     // product_id: productID,

  //     qty: "",
  //     cureentdate: "",
  //     purchase_rate: "",
  //     batch_number: "",
  //     retailer_rate: "",
  //     mrp: "",
  //     sale_rate: "",
  //     discount: "",
  //     gst: "",
  //     add_gst: "",
  //     expiry_date: "",
  //     addAmount: "",
  //     remove_discount: "",
  //     type: "",
  //   };

  //   console.log(newProduct);
  //   return newProduct;
  // };

  const totalamount = productID.reduce((acc, curr) => {
    return acc + calculateTotal(curr.purchase_rate, curr.qty, curr.addAmount);
  }, 0);
  // ----------------------------------------------HANDLE REMOVE ROW---------------------------------------------

  const handleRemove = (index) => {
    const newForm = [...productID];
    newForm.splice(index, 1);

    // Remove the corresponding expiry date and purchase rate
    const newExpiryDates = [...expiryDates];
    newExpiryDates.splice(index, 1);

    const newPurchaseRates = [...purchaseRates];
    newPurchaseRates.splice(index, 1);

    setProductId(newForm);
    setExpiryDates(newExpiryDates);
    setPurchaseRates(newPurchaseRates);
  };

  // var i = 0;
  // ---------------------------------------------ADD PURCHASE ORDER BY POST METHOD----------------------------------

  var i = 0;

  //   const submitForm = async (e) => {
  //     console.log(id);
  //     e.preventDefault();

  //     // Check if any required field is empty
  //     if (
  //         !suppliername ||
  //         !billnumber ||
  //         !purchaseBillNo ||
  //         !city ||
  //         !date ||
  //         !remark
  //     ) {
  //         Toast.fire({
  //             icon: "error",
  //             title: "Please fill in all required fields.",
  //         });
  //         return;
  //     }
  //     const formData = new FormData();
  //     formData.append("supplier_id", suppliername);
  //     formData.append("bill_number", billnumber);
  //     formData.append("purchase_bill_no", purchaseBillNo);
  //     formData.append("city", city);
  //     formData.append("date", date);
  //     formData.append("remarks", remark);

  //     // Append product IDs
  //     const productIds = productID.map(product => product.product.id);
  //     formData.append("product_ids", JSON.stringify(productIds));

  //     // Append other product details
  //     productID.forEach((product, index) => {
  //         formData.append(`qty[${index}]`, product.quantity);
  //         formData.append(`add_gst[${index}]`, product.add_gst);
  //         formData.append(`mrp[${index}]`, product.mrp);
  //         formData.append(
  //             `discount[${index}]`,
  //             product.discount ? product.discount : 0
  //         );
  //         formData.append(
  //             `additional_discount[${index}]`,
  //             product.additional_discount ? product.additional_discount : 0
  //         );
  //         formData.append(`batch_number[${index}]`, product.batch_number);
  //         formData.append(`purchase_rate[${index}]`, product.purchase_rate);
  //         formData.append(
  //             `final_purchase_rate[${index}]`,
  //             product.final_purchase_rate
  //         );
  //         formData.append(`sale_rate[${index}]`, product.sale_rate);
  //         formData.append(`expiry[${index}]`, product.expiry);
  //         formData.append(
  //             `additional_amount[${index}]`,
  //             product.additional_amount ? product.additional_amount : 0
  //         );
  //         formData.append(`product_type[${index}]`, product.product_type);
  //         formData.append(
  //             `remove_discount[${index}]`,
  //             product.remove_discount ? product.remove_discount : 0
  //         );
  //     });

  //     console.log("Form Data:", formData);

  //     axios
  //         .post(`/api/update_batch/${id}`, formData, {
  //             headers: {
  //                 accept: "application/json",
  //             },
  //         })
  //         .then((result) => {
  //             Toast.fire({
  //                 icon: "success",
  //                 title: result.data.message,
  //             });
  //             console.log(id);

  //             navigate("/purchase-order");
  //         })
  //         .catch((error) => {
  //             Toast.fire({
  //                 icon: "error",
  //                 title: error.response.data.message,
  //             });
  //         });
  // };

  const submitForm = async (e) => {
    console.log(id);
    e.preventDefault();

    // Check if any required field is empty
    if (
      !suppliername ||
      !billnumber ||
      !purchaseBillNo ||
      !city ||
      !date ||
      !remark
    ) {
      Toast.fire({
        icon: "error",
        title: "Please fill in all required fields.",
      });
      return;
    }
    const formData = new FormData();
    formData.append("supplier_id", suppliername);
    formData.append("bill_number", billnumber);
    formData.append("purchase_bill_no", purchaseBillNo);
    formData.append("city", city);
    formData.append("date", date);
    formData.append("remarks", remark);

    // Append product details
    productID.forEach((product, index) => {
      console.log(`product_id[${index}]:`, product);
      formData.append(`product_id[${index}]`, product.product.id);
      // formData.append(`product_id[${index}]`, product.selectedProductId !== undefined ? product.selectedProductId : product.product.id);

      formData.append(`qty[${index}]`, product.quantity);
      formData.append(`add_gst[${index}]`, product.add_gst);

      formData.append(`mrp[${index}]`, product.mrp);
      formData.append(
        `discount[${index}]`,
        product.discount ? product.discount : 0
      );
      formData.append(
        `additional_discount[${index}]`,
        product.additional_discount ? product.additional_discount : 0
      );
      formData.append(`batch_number[${index}]`, product.batch_number);
      formData.append(`purchase_rate[${index}]`, product.purchase_rate);
      formData.append(
        `final_purchase_rate[${index}]`,
        product.final_purchase_rate
      );
      formData.append(`sale_rate[${index}]`, product.sale_rate);
      formData.append(`expiry[${index}]`, product.expiry);
      formData.append("grand_total", grandTotal);
      // formData.append("additional_amount[${index}]", product.addAmount);
      formData.append(
        `additional_amount[${index}]`,
        product.additional_amount ? product.additional_amount : 0
      );
      formData.append(`product_type[${index}]`, product.product_type);
      formData.append(
        `remove_discount[${index}]`,
        product.remove_discount ? product.remove_discount : 0
      );
      formData.append(
        `scheme_discount_name[${index}]`,
        product.scheme_discount_name ? product.scheme_discount_name : ""
      );
    });
    // console.log("Product IDs:", productID.product);
    console.log("ID:", id);
    axios
      .post(`/api/update_batch/${id}`, formData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        console.log(id);

        navigate("/purchase-order");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "product_name",
      selector: (row) => row.product_name,
      sortable: true,
      width: "180px",
    },

    {
      name: "batch Number",
      selector: (row) => row.batch_number,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Rate",
      selector: (row) => row.purchase_rate,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Order",
      selector: (row) => row.purchase_order,
      sortable: true,
      width: "180px",
    },

    {
      name: "Sale Rate",
      selector: (row) => row.sale_rate,
      sortable: true,
      width: "180px",
    },
  ];

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  // Expiry date logic
  const formatDate = (date) => {
    // Format the date to 'MM/yyyy' format
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  // Function to format date as "dd-MM-yyyy"
  const formatDate1 = (date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    // return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;
  };

  function getCurrentMonthYear() {
    const today = new Date();
    const mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm.toString().padStart(2, "0")}`; // Set minimum to the current month
  }

  // console.log("purchaseRates[i]:", purchaseRates[i]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/purchase-order">Purchase Order</Link> / Add
                </span>
              </div>

              <div className="bg-gre " style={{ padding: "37px" }}>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardHeader>
                      <strong> Purchase Product</strong>
                    </CCardHeader>
                    <CCardBody>
                      {/* <CForm className="row g-3" onSubmit={submitForm}> */}
                      <CForm className="row g-3">
                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Supplier Name
                          </CFormLabel>
                          <CFormSelect
                            value={suppliername}
                            required
                            onChange={(e) => setsuppliername(e.target.value)}
                            // name="product_id"
                            aria-label="Default select example"
                          >
                            <option>Select Supplier</option>

                            {suppliernamelist?.map((item, index) => (
                              <option key={index} value={item.id}>
                                {/* {item.id} */}
                                {item.head_name + " - " + item.city}
                              </option>
                            ))}
                          </CFormSelect>
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Supplier Bill No
                          </CFormLabel>
                          <CFormInput
                            value={billnumber}
                            required
                            onChange={(e) => setBillnumber(e.target.value)}
                            id="inputAddress"
                            placeholder="Supplier Bill No"
                          />
                        </CCol>
                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Purchase Bill No
                          </CFormLabel>
                          <CFormInput
                            value={purchaseBillNo}
                            required
                            onChange={(e) => setPurchaseBillNo(e.target.value)}
                            id="inputAddress"
                            placeholder="Purchase Bill No"
                          />
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            City
                          </CFormLabel>

                          <CFormInput
                            value={city}
                            required
                            onChange={(e) => setCity(e.target.value)}
                            id="inputAddress"
                            placeholder="City"
                          />

                          {/* <CFormSelect
                            required
                            onChange={(e) => setCity(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option value="">Select City</option>

                            {citylist?.map((item1, index) => (
                              <option key={index} value={item1.name}>
                              
                                {item1.name}
                              </option> 
                            ))}
                          </CFormSelect> */}
                          {/* {item.id} */}
                        </CCol>

                        <CCol md={4} className="mb-2 ">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Date
                          </CFormLabel>

                          <CCol></CCol>
                          <DatePicker
                            selected={date}
                            required
                            onChange={(selectedDate) => {
                              if (selectedDate) {
                                const formattedDate = formatDate1(selectedDate);
                                setDate(formattedDate);
                                console.log("Formatted Date:", formattedDate); // For testing
                              } else {
                                setDate(""); // Set date to empty string if selectedDate is null or undefined
                              }
                            }}
                            dateFormat="dd-MM-yyyy"
                            timeInputLabel="Time:"
                            showDateInput
                            showIcon
                            // minDate={today}
                          />

                          {/* <DatePicker
                            selected={date}
                            required
                            onChange={(selectedDate) => {
                              const formattedDate = formatDate1(selectedDate);
                              setDate(formatDate1(selectedDate));
                              console.log("Formatted Date:", formattedDate); // For testing
                            }}
                            dateFormat="dd-MM-yyyy"
                            timeInputLabel="Time:"
                            showDateInput
                            showIcon
                            minDate={today}
                          /> */}
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Remark
                          </CFormLabel>
                          <CFormInput
                            value={remark}
                            required
                            onChange={(e) => setRemark(e.target.value)}
                            id="inputAddress"
                            placeholder="Remark"
                          />
                        </CCol>

                        <CCol md={3}></CCol>
                        <CCol md={6}>
                          <hr />
                        </CCol>
                        <CCol md={3}></CCol>

                        {productID.map((items, i) => {
                          return (
                            <CCard key={i}>
                              <div className="row g-3">
                                {/* TAB code */}

                                {/* <div> */}
                                {/* <p>
                                    Selected Product:{" "}
                                    {items.product ? items.product.product_name : ''}
                                  </p> */}
                                  {showSelectedProduct && (
                                    <p>
                                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Selected Product :{" "}
                                    </CFormLabel>
                                   
                                      {items.product
                                        ? items.product.product_name
                                        : ""}
                                    </p>
                                  )}
                                  <CCol md={3}>
                                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                                      Product Name
                                    </CFormLabel>
                                    <div>
                                      {loading ? (
                                        <CircularProgress />
                                      ) : (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            // value={items.product.product_name} Remove this line
                                            required
                                            list="productList"
                                            ref={inputRef}
                                            onChange={(e) =>
                                              handleProductSelect(
                                                e.target.value,
                                                i
                                              )
                                            }
                                            placeholder="Select Product"
                                            className="product-input"
                                            style={{
                                              width: "236px",
                                              height: "40px",
                                              paddingLeft: "10px",
                                              paddingRight: "40px",
                                            }}
                                          />
                                          <ArrowDropDownIcon
                                            onClick={() => handleArrowClick()}
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              right: "10px",
                                              transform: "translateY(-50%)",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          />
                                          <datalist id="productList">
                                            {productlist.map((product) => (
                                              <option
                                                key={product.id}
                                                value={product.product_name}
                                              />
                                            ))}
                                          </datalist>
                                        </div>
                                      )}
                                    </div>
                                  </CCol>
                                {/* </div> */}

                                {/* <CCol md={3}>
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Name
                                  </CFormLabel>
                                  <div>
                                    {loading ? (
                                      <CircularProgress />
                                    ) : (
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <input
                                          value={items.product.product_name}
                                          // value={items.product_name}

                                          required
                                          list="productList"
                                          ref={inputRef}
                                          onChange={(e) =>
                                            handleProductSelect(
                                              e.target.value,
                                              i
                                            )
                                          }
                                          placeholder="Select Product"
                                          className="product-input"
                                          style={{
                                            width: "236px",
                                            height: "40px",
                                            paddingLeft: "10px",
                                            paddingRight: "40px",
                                          }}
                                        />
                                        <ArrowDropDownIcon
                                          onClick={() => handleArrowClick()}
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "10px",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                            color: "#555",
                                          }}
                                        />
                                        <datalist id="productList">
                                          {productlist.map((product) => (
                                            <option
                                              key={product.id}
                                              value={product.product_name}
                                            />
                                          ))}
                                        </datalist>
                                      </div>
                                    )}
                                  </div>
                                </CCol> */}

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Type
                                  </CFormLabel>

                                  <CFormInput
                                    readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="product_type"
                                    value={items.product_type}
                                    // name="add_gst"
                                    // value={items.add_gst}
                                    placeholder="Product Type"
                                  />
                                </CCol>

                                <CCol md={3}>
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Batch List
                                  </CFormLabel>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <input
                                      value={items.batch_number}
                                      name="batch_number"
                                      required
                                      list="batchList"
                                      ref={inputRef1}
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedName = batchList.find(
                                          (batch) =>
                                            batch.batch_number === selectedValue
                                        )?.name;
                                        handleBatchSelect(
                                          selectedValue,
                                          selectedName,
                                          i
                                        ); // Pass 'i' as the third argument
                                      }}
                                      placeholder="Select Batch"
                                      className="batch-input"
                                      style={{
                                        width: "236px",
                                        height: "40px",
                                        paddingLeft: "10px",
                                        paddingRight: "40px",
                                      }}
                                    />
                                    <ArrowDropDownIcon
                                      // onClick={handleArrowClick1}
                                      onClick={() => handleArrowClick1()}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "10px",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                        color: "#555",
                                      }}
                                    />
                                    <div
                                      style={{
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <datalist id="batchList">
                                        {batchList.map((values, index) => (
                                          <option
                                            key={index}
                                            value={values.batch_number}
                                          />
                                        ))}
                                      </datalist>
                                    </div>
                                  </div>
                                </CCol>

                                <CCol md={3} className="mb-2">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Expiry Date
                                  </CFormLabel>
                                  <DatePicker
                                    value={items.expiry}
                                    showIcon
                                    selected={expiryDates[i]}
                                    required
                                    minDate={
                                      new Date(
                                        today.getFullYear(),
                                        today.getMonth() + 1
                                      )
                                    }
                                    onChange={(date) => {
                                      const newExpiryDates = [...expiryDates];
                                      newExpiryDates[i] = date || ""; // Set to empty string if date is null or undefined
                                      setExpiryDates(newExpiryDates);

                                      handleInput(
                                        {
                                          target: {
                                            name: "expiry",
                                            value: date ? formatDate(date) : "", // Format the selected date if available
                                          },
                                        },
                                        i
                                      );
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Quantity
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    name="quantity"
                                    // min="1"
                                    // defaultValue={1}
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 ]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    placeholder="Product Quantity"
                                    value={items.quantity}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    MRP
                                  </CFormLabel>

                                  <CFormInput
                                    // readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="mrp"
                                    value={items.mrp}
                                    placeholder="₹"
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Sale Rate
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="sale_rate"
                                    placeholder="₹"
                                    value={items.sale_rate}
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Purchase Rate
                                  </CFormLabel>

                                  <CFormInput
                                    // readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="purchase_rate"
                                    placeholder="₹"
                                    value={items.purchase_rate}
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Scheme Name
                                  </CFormLabel>
                                  <CFormInput
                                    // readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    // onInput={(e) => {
                                    //   e.target.value = e.target.value.replace(
                                    //     /[^0-9 + .]/g,
                                    //     ""
                                    //   );
                                    // }}
                                    id="inputAddress"
                                    name="scheme_discount_name"
                                    placeholder="Scheme Name"
                                    value={items.scheme_discount_name}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Scheme Discount %
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.discount}
                                    required
                                    type="text"
                                    id="inputAddress"
                                    name="discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      // Remove any non-digit characters except the decimal point
                                      const sanitizedValue =
                                        e.target.value.replace(/[^\d.]/g, "");

                                      // Split the value into integer and decimal parts
                                      const [integerPart, decimalPart] =
                                        sanitizedValue.split(".");

                                      // Limit the integer part to 2 digits
                                      const truncatedInteger =
                                        integerPart.slice(0, 2);

                                      // Combine the integer and decimal parts back
                                      let newValue = truncatedInteger;
                                      if (decimalPart !== undefined) {
                                        // Add the decimal point and append the decimal part
                                        newValue += "." + decimalPart;
                                      }

                                      // Update the input value
                                      e.target.value = newValue;

                                      // Call your handleInput function here if needed
                                      handleInput(e, i);
                                    }}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Add Discount (-) %
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    onInput={(e) => {
                                      let inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      const parts = inputValue.split(".");
                                      if (parts.length > 1) {
                                        parts[0] = parts[0].slice(0, 2);
                                        parts[1] = parts[1].slice(0, 2);
                                        inputValue = parts.join(".");
                                      } else {
                                        inputValue = inputValue.slice(0, 2);
                                      }
                                      inputValue = inputValue.slice(0, 5);
                                      e.target.value = inputValue;
                                    }}
                                    id="inputAddress"
                                    name="additional_discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      handleInput(e, i);
                                      // Clear the value of remove discount field
                                      items.remove_discount = "";
                                    }}
                                    value={items.additional_discount}
                                    disabled={
                                      parseFloat(items.remove_discount) > 0
                                        ? "disabled"
                                        : ""
                                    }
                                  />
                                </CCol>
                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Remove Discount (+) %
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.remove_discount}
                                    type="text"
                                    onInput={(e) => {
                                      let inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      const parts = inputValue.split(".");
                                      if (parts.length > 1) {
                                        parts[0] = parts[0].slice(0, 2);
                                        parts[1] = parts[1].slice(0, 2);
                                        inputValue = parts.join(".");
                                      } else {
                                        inputValue = inputValue.slice(0, 2);
                                      }
                                      inputValue = inputValue.slice(0, 5);
                                      e.target.value = inputValue;
                                    }}
                                    id="inputAddress"
                                    name="remove_discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      handleInput(e, i);
                                      // Clear the value of add discount field
                                      items.additional_discount = "";
                                    }}
                                    disabled={
                                      parseFloat(items.additional_discount) > 0
                                        ? "disabled"
                                        : ""
                                    }
                                  />
                                </CCol>
                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    GST %
                                  </CFormLabel>

                                  <CFormInput
                                    readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="add_gst"
                                    value={items.add_gst}
                                    placeholder="₹"
                                  />
                                </CCol>

                                <CCol md={3} className="mb-5">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    New Purchase Rate
                                  </CFormLabel>
                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e)} // No need to pass index 'i' now
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="final_purchase_rate"
                                    placeholder="₹"
                                    value={items.final_purchase_rate}
                                    // value={purchaseRates[i] || ""} // Use the corresponding purchase rate from the array
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Additional Amount
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    // name="retailer_rate"
                                    name="additional_amount"
                                    placeholder="₹"
                                    value={items.additional_amount}
                                  />
                                </CCol>

                                <CCol md={8}></CCol>
                                <CCol md={6} key={i}>
                                  <h5 style={{}}>
                                    Total: ₹{" "}
                                    {parseFloat(
                                      calculateTotal(
                                        items.final_purchase_rate,
                                        items.quantity,
                                        items.additional_amount
                                      )
                                    ).toFixed(2)}
                                  </h5>

                                  {/* <CCol md={2}>
                                    <button
                                      className=" btn btn-outline-danger mb-2"
                                      onClick={(e) => handleRow(e)}
                                    >
                                      + Add More
                                    </button>
                                  </CCol> */}
                                </CCol>

                                <CCol md={12} className="">
                                  {productID.length > 1 && ( // Ensure there's more than one item before rendering delete button
                                    <button
                                      className="bg-white mt-5"
                                      style={{ border: "none" }}
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent default action of the click event
                                        handleRemove(i); // Pass the index to the handleRemove function
                                      }}
                                    >
                                      <DeleteIcon className="text-red" />
                                    </button>
                                  )}
                                </CCol>

                                <CCol md={12} className="">
                                  <hr />
                                </CCol>
                              </div>
                            </CCard>
                          );
                        })}
                        <div className="text-center"></div>
                        {/* <CCol md={10}></CCol> */}

                        {/* <div className="d-flex justify-content-between"> */}
                        <CCol md={10}>
                          <h5>
                            Grand Total: ₹ {parseFloat(grandTotal).toFixed(2)}
                          </h5>
                          {/* ₹ {parseFloat(product.total).toFixed(2)} */}
                        </CCol>

                        <CCol md={2}>
                          <button
                            className=" btn btn-outline-danger mb-2"
                            onClick={(e) => handleRow(e)}
                          >
                            + Add More
                          </button>
                        </CCol>

                        {/* <CCol md={2}>
                          <button
                            className="btn btn-outline-danger mb-2"
                            onClick={handleRow}
                          >
                            + Add More
                          </button>
                        </CCol> */}
                        {/* </div> */}
                        {/* <CCol md={3} >
                          <h4>Grand Total: ₹ {total}</h4>
                        </CCol> */}
                        <CCol md={12} className="text-center">
                          <button
                            type="submit"
                            className="btn btn-danger my-3 "
                            onClick={submitForm}
                          >
                            Submit
                          </button>
                        </CCol>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPurchaseOrder;
