import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import DownloadIcon from "@mui/icons-material/Download";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import axios from "axios";

const ViewOrder = () => {
  const { id } = useParams();

  const [singleOrder, setSingleOrder] = useState([]);
  const [singleOrderdetail, setSingleOrderdetail] = useState("");
  const [invoice, setInvoice] = useState("");

  const [amount, setAmount] = useState("");

  const [setting, setSetting] = useState("");

  const [shipAddress1, setShipAddress1] = useState("");
  const [shipAddress2, setShipAddress2] = useState("");

  const [shipAddress3, setShipAddress3] = useState("");
  const [orderNo, setOrderNo] = useState("");

  const [image, setImage] = useState("");

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = async () => {
    const result = await axios.get("/api/single_order/" + id);
    setSingleOrder(result.data.data);
    setSingleOrderdetail(result.data.order.customer || {});
    setInvoice(result.data.invoice);
    setAmount(result.data.total_amount);

    setShipAddress1(result.data.order.ship_city);
    setShipAddress2(result.data.order.ship_state);

    setShipAddress3(result.data.order.ship_country);
    setOrderNo(result.data.order);
  };
  useEffect(() => {
    axios.get("/api/get_settings").then((res) => {
      setSetting(res.data.data);
      setImage(res.data.data.logo);
    });
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Product Id",
      selector: (row) => row.product_id,
      width: "100px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product_name
          ? row.product_name
          : "No Product Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "170px",
    },

    {
      name: "Batch",
      selector: (row) => row.batch_number,
      width: "100px",
    },

    {
      name: "Expiry",
      selector: (row) => row.expiry,
      width: "110px",
    },

    {
      name: "GST",
      selector: (row) => <span>{row.gst + "%"}</span>,
      width: "80px",
    },

    {
      name: "GST Price",
      selector: (row) => Number(row.gst_price).toFixed(2),

      width: "120px",
    },

    {
      name: "Sale R",
      selector: (row) => Number(row.sale_rate).toFixed(2),

      width: "100px",
    },

    {
      name: "Qty",
      selector: (row) => row.quantity,
      width: "100px",
    },

    {
      name: "Net Value",
      selector: (row) => Number(row.total_mrp).toFixed(2),

      width: "120px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <div className="container-fluid">
            <Navbar />

            <div className="p-2 bg-white ml-3">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/order">Orders</Link> / View
              </span>
            </div>

            <section
              className="bg-gre ml-3"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol
                            sm="4"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span>
                                <strong
                                  className="text-capital"
                                  style={{ fontSize: "20px" }}
                                >
                                  {singleOrderdetail.shop_name}
                                </strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>{singleOrderdetail.email}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>{singleOrderdetail.mobile}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>{singleOrderdetail.total}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span>
                                {orderNo.ship_address +
                                  " , " +
                                  orderNo.ship_address2 +
                                  " , " +
                                  shipAddress1.name +
                                  " , " +
                                  shipAddress2.name +
                                  " , " +
                                  orderNo.ship_zipcode +
                                  " , " +
                                  shipAddress3}
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              Order No. -<span>{orderNo.order_number}</span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol
                            sm="3"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span className="fw-600">
                                <strong style={{ fontSize: "20px" }}>
                                  {setting.company_name}
                                </strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.email}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.phone}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.website}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.address}</span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol className="d-flex align-items-center" sm="4">
                            <img
                              src={image}
                              className="rounded mx-auto d-block"
                              alt="Logo"
                              width={180}
                            />
                          </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow className="mt-1">
                          <div style={{ margin: "1%" }}>
                            <DataTable
                              columns={columns}
                              title="Order Detail"
                              data={singleOrder}
                              defaultSortFieldId
                              fixedHeader
                              responsive
                              allowOverflow
                              subHeaderAlign="right"
                            />
                          </div>
                        </MDBRow>
                        <hr></hr>

                        <MDBRow className="mt-2">
                          <MDBCol sm="5"></MDBCol>
                          <MDBCol sm="4"></MDBCol>

                          <MDBCol sm="3">
                            <MDBCardText style={{ fontSize: "25px" }}>
                              Total :{" "}
                              <span
                                style={{ fontSize: "25px", fontWeight: "700" }}
                              >
                                {amount}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="mt-2">
                          <MDBCol sm="9">
                            <MDBCardText>
                              Download Invoice :{" "}
                              <Link to={invoice}>
                                <button
                                  className="bg-white"
                                  style={{ border: "none" }}
                                >
                                  <DownloadIcon />
                                </button>
                              </Link>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
