import "./orders.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomeLoader from "../../components/CustomeLoader";
import { CRow, CCol, CCard, CCardHeader } from "@coreui/react";
import Widget from "../../components/widget/Widget";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const CustomInput = ({ value, onClick }) => (
    <button
      style={{
        width: "250px",
        padding: "5px",
        textAlign: "center",
        backgroundColor: "#ffffff",
        border: "1px solid black",
      }}
      className="custom-date-input"
      onClick={onClick}
    >
      {value || "Select Date"}
    </button>
  );

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const type = params.get("type");

  const api_url = type === null ? "/api/orders" : "/api/orders?type=" + type;

  useEffect(() => {
    axios
      .get(api_url, {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })
      .then((res) => {
        res.data.status === "Success"
          ? setOrders(res.data.data)
          : setOrders([]);
      })
      .catch((err) => {});
  }, [api_url, formattedStartDate, formattedEndDate]);

  const handleStatus = (e, row) => {
    e.preventDefault();

    const newStatus = e.target.value;

    axios
      .get(`/api/change-order-status/${row.id}/${newStatus}`)
      .then((result) => {
        axios.get("/api/orders").then((res) => {
          res.data.status == "Success"
            ? setOrders(res.data.data)
            : setOrders([]);
        });
      })
      .catch((error) => {});
  };

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/view-order/${row.id}`} className="btn btn-primary">
          <VisibilityIcon />
        </Link>
      ),
      width: "90px",
    },
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Order No",
      selector: (row, index) => row.order_number,
      width: "100px",
    },
    {
      name: "Shop Name",
      selector: (row) => (
        <span className="text-capital" style={{ whiteSpace: 'pre-line', width: '250px' }}>
          {row.customer ? row.customer.shop_name : "Not Available"}
        </span>
      ),
      width: "250px",
    },
    {
      name: "Total",
      selector: (row) => Number(row.total).toFixed(2),
      width: "120px",
    },
    {
      name: "Payment",
      selector: (row) => row.payment_mode,
      width: "100px",
    },

    {
      name: "Status",
      selector: (row) => (
        <>
          <select
            style={{
              border: "none",
              fontSize: "14px",
              fontWeight: "400",
              color:
                row.order_status == "0"
                  ? "#f5d442"
                  : row.order_status == "2"
                  ? "blue"
                  : row.order_status == "3"
                  ? "green"
                  : "",
              background:
                row.order_status == "0"
                  ? "#ffffff "
                  : row.order_status === "2"
                  ? "#ffffff "
                  : "#ffffff",
              borderRadius: "8px",
            }}
            onChange={(e) => handleStatus(e, row)}
            value={row.order_status}
          >
            <option
              style={{ color: "#f5d442", fontSize: "15px", fontWeight: "700" }}
              disabled
              value="0"
            >
              Processing
            </option>
            <option
              style={{ color: "blue", fontSize: "15px", fontWeight: "700" }}
              value="2"
            >
              Out for Delivery
            </option>
            <option
              style={{ color: "green", fontSize: "15px", fontWeight: "700" }}
              value="3"
            >
              Delivered
            </option>
          </select>
        </>
      ),
      width: "140px",
    },

    {
      name: "Mobile",
      selector: (row) => row.phone,
      width: "120px",
    },

    {
      name: "Order Date",
      selector: (row) => {
        const createdAt = new Date(row.created_at);
        return createdAt.toISOString().split("T")[0];
      },
      width: "180px",
    },
    {
      name: "Customer",
      selector: (row) => (
        <span className="text-capital" style={{ whiteSpace: 'pre-line', width: '250px' }}>
          {row.customer ? row.customer.name : "Not Available"}
        </span>
      ),
      width: "250px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(orders);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  const filteredItems = orders.filter(
    (item) =>
      (item.customer?.name &&
        item.customer?.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.order_number &&
        item.order_number.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <div className="px-2">
                <CRow className="pt-3 pb-3">
                  <CCol xs={12} sm={12} lg={4} className="mb-3">
                    <Widget type="allorders" />
                  </CCol>
                  <CCol xs={12} sm={12} lg={4} className="mb-3">
                    <Widget type="activeorder" />
                  </CCol>
                  <CCol xs={12} sm={12} lg={4} className="mb-3">
                    <Widget type="deliveredorder" />
                  </CCol>
                </CRow>
              </div>
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Orders</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-sm-6  mt-4">
                        <TextField
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-4 pt-2 mr-2 d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between align-items-center w-75">
                          <div>
                            <strong>Start Date</strong>{" "}
                          </div>
                          <div>
                            <strong>End Date</strong>{" "}
                          </div>
                        </div>
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            if (
                              update &&
                              Array.isArray(update) &&
                              update.length === 2
                            ) {
                              const start = update[0];
                              const end = update[1];
                              setStartDate(start);
                              setEndDate(end);
                            } else {
                              setStartDate(null);
                              setEndDate(null);
                            }
                          }}
                          isClearable={true}
                          withPortal
                          showIcon
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination={10}
                      allowOverflow
                      subHeaderAlign="right"
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Orders;
