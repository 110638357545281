import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/datatable/Datatable";
import OutOfStock from "../stockmanagement/OutOfStock";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <div className="container-fluid p-0">
            <Navbar />

            <div className="home">
              <div className="homeContainer ">
                <div className="row">
                  <div className="col-12">
                    <div className="widgets ">
                      <Widget type="user" />
                      <Widget type="order" />
                      <Widget type="earning" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="widgets">
                      <Widget type="balance" />
                      <Widget type="newcustomer" />

                      <Widget type="selling" />
                    </div>
                  </div>
                </div>

                <div className="my-5">
                  <OutOfStock />
                </div>
                <div className="my-5">
                  <Datatable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
