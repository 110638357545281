import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCol,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CForm,
  CFormTextarea,
} from "@coreui/react";
import Swal from "sweetalert2";

const AddSupplier = () => {
  const navigate = useNavigate();
  const [contactPname, setContactPame] = useState("");
  const [headname, setHeadName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gstRegistered, setGstRegistered] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [drugLnumber, setDrugLnumber] = useState("");

  const [emailId, setEmailId] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submitForm = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();
    categoryobj.append("address", address);
    categoryobj.append("mobile", mobile);
    categoryobj.append("city", city);
    categoryobj.append("head_name", headname);
    categoryobj.append("contact_person", contactPname);
    categoryobj.append("district", district);
    categoryobj.append("pincode", pinCode);
    categoryobj.append("drug_license", drugLnumber);
    categoryobj.append("gst_registered", gstRegistered);
    categoryobj.append("gst_number", gstNumber);

    categoryobj.append("email", emailId);
    categoryobj.append("telephone_number", telephoneNumber);
    categoryobj.append("state", state);
    categoryobj.append("state_code", stateCode);

    axios
      .post("/api/add_supplier", categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/supplier");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/supplier">Supplier</Link> / Add
                </span>
              </div>
              <div className="bg-gre p-5">
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardHeader>
                      <strong>Add New Supplier</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CForm className="row g-3" onSubmit={submitForm}>
                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Account Head Name
                          </CFormLabel>

                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              setHeadName(input);
                            }}
                            onKeyDown={(e) => {
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Contact Person Name
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              setContactPame(input);
                            }}
                            onKeyDown={(e) => {
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            E-Mail ID
                          </CFormLabel>

                          <CFormInput
                            required
                            onChange={(e) => setEmailId(e.target.value)}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Address
                          </CFormLabel>
                          <CFormTextarea
                            onChange={(e) => setAddress(e.target.value)}
                            id="exampleFormControlTextarea1"
                            required
                            name="description"
                            placeholder="Address"
                            rows="1"
                          ></CFormTextarea>
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            State
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              setState(input);
                            }}
                            onKeyDown={(e) => {
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            State Code
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) =>
                              handleNumericInput(e, setStateCode)
                            }
                            id="inputPincode"
                            placeholder=""
                            value={stateCode}
                            maxLength={2}
                            minLength={2}
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            City
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              setCity(input);
                            }}
                            onKeyDown={(e) => {
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="inputMobile">Mobile</CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => handleNumericInput(e, setMobile)}
                            id="inputMobile"
                            placeholder=""
                            value={mobile}
                            minLength={10}
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            District
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              setDistrict(input);
                            }}
                            onKeyDown={(e) => {
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Pincode
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => handleNumericInput(e, setPinCode)}
                            id="inputPincode"
                            placeholder=""
                            value={pinCode}
                            maxLength={6}
                            minLength={6}
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="inputNo">
                            {" "}
                            Telephone Number
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) =>
                              handleNumericInput(e, setTelephoneNumber)
                            }
                            id="inputMobile"
                            placeholder=""
                            value={telephoneNumber}
                            minLength={10}
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Drug Licence No
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => setDrugLnumber(e.target.value)}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>
                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            GST Registered
                          </CFormLabel>
                          <select
                            required
                            name="shop_verified"
                            onChange={(e) => setGstRegistered(e.target.value)}
                            className="w-100 "
                            style={{ height: "38px" }}
                          >
                            <option value="">Select</option>
                            <option value="1">RD</option>
                            <option value="0">URD</option>
                          </select>
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            GST No
                          </CFormLabel>
                          <CFormInput
                            required
                            value={gstNumber}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                              if (input.length <= 16) {
                                setGstNumber(input);
                              }
                            }}
                            onKeyDown={(e) => {
                              const input = e.target.value;
                              if (
                                input.length === 16 &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                              if (!e.key.match(/[a-zA-Z0-9]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={12} className="text-center">
                          <button
                            type="submit"
                            className="btn btn-danger my-3 "
                          >
                            Submit
                          </button>
                        </CCol>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSupplier;
