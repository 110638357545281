import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const EditSuperSaving = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productimg, setProductimg] = useState("");

  const [singleproduct, setSingleproduct] = useState("");
  const [image, setimage] = useState("");

  const [productname, setProductname] = useState([]);
  // const [singleproductname, setSingleProductname] = useState("");
  const [singleproductname, setSingleProductname] = useState(null);


  const [status, setStatus] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/get-super-savings/" + id)
      .then((res) => {
        setSingleproduct(res.data.data.title);
        // setSingleProductname(res.data.data.product_id);
        setSingleProductname({
          value: res.data.data.product_id,
          label: "Loading..." // Placeholder label, will be updated after fetching product list
        });
        setimage(res.data.data.image);

        setStatus(res.data.data.is_show);
      })
      .then((res) => {})
      .catch((err) => {});
  }, [id]);
  useEffect(() => {
    axios
      .get("/api/products")
      .then((res) => {
        const products = res.data.data.map((item) => ({
          value: item.id,
          label: item.product_name,
        }));
        setProductname(products);

        // Update the label of the selected product if it matches
        setSingleProductname((prevSelected) => {
          if (prevSelected) {
            const selectedProduct = products.find(
              (product) => product.value === prevSelected.value
            );
            return selectedProduct || null;
          }
          return null;
        });
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
      });
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (!singleproductname || !singleproduct) {
      Toast.fire({
        icon: "error",
        title: "Please fill out all fields.",
      });
      return;
    }

    const productobj = new FormData();
    productobj.append("id", id);
    productobj.append("title", singleproduct);
    productobj.append("image", productimg);

    productobj.append("status", status);
    productobj.append("product_id", singleproductname.value);

    // productobj.append("product_id", singleproductname);
    await axios
      .post("/api/edit-super-savings", productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        result.data.status == 400
          ? navigate("/api/edit-super-savings")
          : navigate("/super-saving");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  // useEffect(() => {
  //   axios
  //     .get("/api/products")
  //     .then((res) => {
  //       setProductname(res.data.data);
  //     })
  //     .catch((err) => {});
  // }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/super-saving">Super Saving</Link> / Edit Super
                  Saving
                </span>
              </div>

              <section className="bg-gre" style={{ padding: "50px" }}>
                <div className="row bg-white pl-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded">
                  <h5>
                    <center className="mb-4 mt-3">Edit Super Saving</center>
                  </h5>

                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        className="w-100"
                        name="title"
                        type="text"
                        placeholder="Title"
                        value={singleproduct}
                        onChange={(e) => setSingleproduct(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row ">
                  <div className="col-12 mb-3">
                    <Select
                      options={productname}
                      value={singleproductname}
                      onChange={(selectedOption) => setSingleProductname(selectedOption)}
                      placeholder="----Select Product----"
                      isSearchable={true}
                      className="w-100"
                    />
                  </div>
                    {/* <div className="col-12 mb-3 ">
                      <select
                        onChange={(e) => setSingleProductname(e.target.value)}
                        name="state"
                        className="w-100 h-5"
                        value={singleproductname}
                      >
                        <option value="">----Select Product----</option>
                        {productname.map((items, index) => (
                          <option key={index} value={items.id}>
                            &nbsp;&nbsp;&nbsp;{items.product_name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3">
                      <img src={image} alt="" style={{ width: "60px" }} />
                    </div>
                    <div className="col-lg-6">
                      <div>Image-1</div>
                      <input
                        type="file"
                        className="w-100 mt-"
                        name="image"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => setProductimg(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="col-6 mb-3 mt-5">
                    <span>Status</span>
                  </div>
                  <div className="col-6 mb-3 mt-5"></div>
                  <div className="col-3 form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="inlineRadio1"
                      value="1"
                      status
                      checked={status === 1}
                      onChange={() => setStatus(1)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="col-3 form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="inlineRadio2"
                      value="0"
                      checked={status === 0}
                      onChange={() => setStatus(0)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>

                  <div className="row my-2">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSuperSaving;
