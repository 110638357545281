import "./product.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { TextField } from "@mui/material";
const Products = () => {
  const { id } = useParams();
  const [users, setUser] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [brands, setBrands] = useState([]);

  const [product, setProduct] = useState("");

  const [visibleLg, setVisibleLg] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const isEthicalProduct = product.type === "ethical";
  const isGenericProduct = product.type === "generic";
  const dealoftheday = product.deal_of_the_day == "1 ";

  const [productimg, setProductimg] = useState("");
  const [productimg1, setProductimg1] = useState("");
  const [productimg2, setProductimg2] = useState("");
  const [manufacturer, setmanufacturer] = useState("");
  const [packof, setPackof] = useState("");
  const [discount, setDiscount] = useState("");
  const [gst, setGst] = useState("");
  const [scheme, setScheme] = useState("");
  const [schemeDiscount, setSchemeDiscount] = useState("");
  const [maxorder, setmaxorder] = useState("");
  const [minorder, setminorder] = useState("");
  const [rate, setrate] = useState("");
  const [salerate, setsalerate] = useState("");
  const [salerateg, setsalerateg] = useState("");

  const [mrp, setmrp] = useState("");
  const [productname, setproductname] = useState("");
  const [delivery, setDelivery] = useState("");
  const [returnp, setreturnp] = useState("");
  const [expiryReturn, setExpiryReturn] = useState("");
  const [dealOfTheDay, setDealOfTheDay] = useState("");
  const [dealTill, setDealTill] = useState("");
  const [hsn, setHsn] = useState("");
  const [composition, setcomposition] = useState("");
  const [manufacturerlist, setmanufacturerlist] = useState([]);
  const [hsnlist, setHsnlist] = useState([]);
  const [image, setimage] = useState("");
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [singleproduct, setsingleproduct] = useState("");

  const [mrp1, setmrp1] = useState("");
  const [rate1, setrate1] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);

  const openModal = (productId) => {
    setSelectedProductId(productId);
    setVisibleLg(true);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `api/products_test?page=${page}&perPage=${perPage}&search=${search}`
      );

      const responseData = res.data.data;
      setUser(responseData.data);

      setTotalRows(responseData.total);
      setPerPage(responseData.per_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, editModalVisible, currentEditId]);

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  const deleteHandler = (row) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Product?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete_product/" + row.id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });
            let newArr = users.filter((items) => {
              return row.id !== items.id;
            });
            setUser(newArr);
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },

    {
      name: "View",
      selector: (row) => (
        <div style={{ width: "90px" }}>
          <CButton color="primary" onClick={() => openModal(row.id)}>
            <VisibilityIcon />
          </CButton>
        </div>
      ),
      width: "90px",
    },

    {
      name: "PID",
      selector: (row, index) => <div>#P{row.id}</div>,
      width: "90px",
    },
    {
      name: "Images",
      selector: (row) => (
        <span>
          <img
            width={50}
            height={50}
            className="m-1"
            src={row.image}
            alt="MDN logo"
          />
        </span>
      ),
      width: "110px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product_name;
        if (productName.length > 25) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 25 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "220px",
    },

    {
      name: "Product Type",
      selector: (row) => <span className="text-capital">{row.type}</span>,
      width: "120px",
    },

    {
      name: "Manufacturer",
      selector: (row) => (
        <span className="text-capital">{row.division?.name}</span>
      ),
      width: "200px",
    },
    {
      name: "MRP",
      selector: (row) => <span>{parseFloat(row.mrp).toFixed(2)}</span>,
      width: "80px",
    },

    {
      name: "Deal",
      selector: (row) => (
        <span>
          {row.deal_of_the_day == 0 ? (
            <CloseIcon style={{ color: "red" }} />
          ) : (
            <DoneIcon style={{ color: "green" }} />
          )}
        </span>
      ),
      width: "80px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          {" "}
          <CButton color="primary" onClick={() => openEditModal(row.id)}>
            <EditIcon />
          </CButton>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const selectbrand = (e) => {
    const selectedBrandId = e.target.value;

    setSearch(selectedBrandId);
  };

  useEffect(() => {
    if (visibleLg && selectedProductId) {
      axios
        .get("/api/get_single_product/" + selectedProductId)
        .then((response) => {
          const productData = response.data.data;
          setProduct(productData);
        })
        .catch((error) => {});
    }
  }, [visibleLg, selectedProductId]);

  const openEditModal = (id) => {
    setEditModalVisible(true);
    setCurrentEditId(id);
  };

  const isFutureDateTime = (selectedDateTime) => {
    const selectedDate = new Date(selectedDateTime);
    const now = new Date();
    const difference = selectedDate.getTime() - now.getTime();
    const hoursDifference = difference / (1000 * 3600);
    return hoursDifference >= 24;
  };

  let newsalerate = (
    (rate * (100 - Number(discount)) * (100 - Number(schemeDiscount))) /
    10000
  ).toFixed(2);
  newsalerate = Math.max(0, parseFloat(newsalerate));

  const newsalerate1 =
    discount === "0"
      ? parseFloat(rate1.toFixed(2))
      : parseFloat(((rate1 * (100 - Number(discount))) / 100).toFixed(2));

  useEffect(() => {
    if (editModalVisible && currentEditId) {
      axios.get("/api/get_single_product/" + currentEditId).then((res) => {
        setsingleproduct(res.data.data);
        setcomposition(res.data.data?.composition);
        setHsn(res.data.data?.hsn + "-" + res.data.data?.gst);
        setproductname(res.data.data?.product_name);
        setreturnp(res.data.data?.return);
        setsalerate(res.data.data?.sale_rate);
        setsalerateg(res.data.data?.sale_rate_g);
        setPackof(res.data.data?.pack_of);
        setrate(res.data.data?.rate);
        setScheme(res.data.data?.scheme);
        setSchemeDiscount(res.data.data?.scheme_percent);
        setmrp(res.data.data?.mrp);
        setminorder(res.data.data?.min_order);
        setmaxorder(res.data.data?.max_order);
        setExpiryReturn(res.data.data?.expiry_return);
        setmanufacturer(res.data.data?.division?.id);
        setDiscount(res.data.data?.discount);
        setDelivery(res.data.data?.delivery);
        setDealTill(res.data.data?.deal_till);
        setDealOfTheDay(res.data.data?.deal_of_the_day);
        setimage(res.data.data?.image);
        setimage1(res.data.data?.image2);
        setimage2(res.data.data?.image3);
        setGst(res.data.data?.gst);
        setmrp1(res.data.data?.mrp_g);
        setrate1(res.data.data?.rate_g);
        setSelectedType(res.data.data?.type);
      });
    }
  }, [editModalVisible, currentEditId]);

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        setmanufacturerlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axios
      .get("/api/get_hsn")
      .then((res) => {
        setHsnlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const submitForm = async (e) => {
    if (selectedType === "generic" && (mrp === "" || rate === "")) {
      Toast.fire({
        icon: "error",
        title: "Please fill in the MRP and Rate for Quantity 1-9.",
      });
      return;
    }

    if (dealOfTheDay === "1" && !dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please select the Deal Till date.",
      });
      return;
    }

    if (dealOfTheDay === "1" && !isFutureDateTime(dealTill)) {
      Toast.fire({
        icon: "error",
        title: "Please select a Deal Till date at least 24 hours from now.",
      });
      return;
    }

    if (dealOfTheDay === "0" && dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please clear the Deal Till date.",
      });
      return;
    }

    if (
      !productname ||
      !manufacturer ||
      !hsn ||
      !returnp ||
      !packof ||
      !composition ||
      !minorder ||
      !maxorder ||
      !delivery ||
      !expiryReturn
    ) {
      Toast.fire({
        icon: "error",
        title: "Please fill in all the fields.",
      });
      return;
    }

    e.preventDefault();
    const productobj = new FormData();
    productobj.append("product_name", productname);
    productobj.append("min_order", minorder);
    productobj.append("max_order", maxorder);
    productobj.append("division", manufacturer);
    productobj.append("return", returnp);
    productobj.append("pack_of", packof);
    productobj.append("rate", rate);
    productobj.append("rate_g", rate1);
    productobj.append("discount", discount);
    productobj.append("sale_rate", newsalerate);
    productobj.append("sale_rate_g", newsalerate1);
    productobj.append("scheme", scheme);
    productobj.append("scheme_percent", schemeDiscount);
    productobj.append("mrp", mrp);
    productobj.append("mrp_g", mrp1);
    productobj.append("expiry_return", expiryReturn);
    productobj.append("composition", composition);
    productobj.append("delivery", delivery);
    productobj.append("deal_of_the_day", dealOfTheDay);
    productobj.append("deal_till", dealTill ? dealTill : "");
    productobj.append("hsn", hsn);
    productobj.append("image", productimg);
    productobj.append("image2", productimg1);
    productobj.append("image3", productimg2);

    try {
      const result = await axios.post(
        "/api/update_product/" + currentEditId,
        productobj,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      Toast.fire({
        icon: "success",
        title: result.data.message,
      });
      setEditModalVisible(false);
      navigate("/products");
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
    }
  };

  return (
    <>
      <CModal
        size="fullscreen"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="ViewProductModal"
      >
        <CModalHeader closeButton>
          <CModalTitle style={{ marginLeft: "75px" }}>
            <strong>View Product</strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Product ID: {selectedProductId}
          <MDBContainer className="px-5 mt-4">
            <MDBRow>
              <MDBCol lg="6">
                <MDBCard className="mb-2">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="4">
                        <MDBCardText>Product Image 1</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          <a
                            href={product.image}
                            download="product-image.jpg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBCardImage
                              src={product.image}
                              alt="No Image"
                              className="mb-1"
                              style={{ width: "50px", marginLeft: "25%" }}
                              fluid
                            />
                          </a>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="4">
                        <MDBCardText>Product Image 2</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          <a
                            href={product?.image2}
                            download="product-image2.jpg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBCardImage
                              src={product?.image2}
                              alt="No Image"
                              className="mb-1"
                              style={{ width: "50px", marginLeft: "25%" }}
                              fluid
                            />
                          </a>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="4">
                        <MDBCardText>Product Image 3</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          <a
                            href={product?.image3}
                            download="product-image3.jpg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBCardImage
                              src={product?.image3}
                              alt="No Image"
                              className="mb-1"
                              style={{ width: "50px", marginLeft: "25%" }}
                              fluid
                            />
                          </a>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Product Type</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted text-capital">
                          <span className="text-capital">
                            {" "}
                            {product.type + " Product"}
                          </span>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Product Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted text-capital">
                          <span className="text-capital">
                            {" "}
                            {product.product_name}
                          </span>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Manufacturer</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted text-capital">
                          <span className="text-capital">
                            {product.division?.name}
                          </span>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Composition</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          <span className="text-capital">
                            {product.composition}
                          </span>
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    {isEthicalProduct && (
                      <>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>MRP</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.mrp}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Rate</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.rate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}

                    {isGenericProduct && (
                      <>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>MRP 1-9</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.mrp}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Rate 1-9</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.rate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Sale Rate 1-9</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.sale_rate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>MRP 10+</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.mrp_g}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Rate 10+</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.rate_g}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Sale Rate 10+</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.sale_rate_g}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}

                    {isEthicalProduct && (
                      <>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Sale Rate</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              ₹ {product.sale_rate}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Minimum Order</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.min_order}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Maxium Order</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.max_order}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="6">
                <MDBCard className="mb-2 ">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Total Stock</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted text-capital">
                          {product.stock == null
                            ? "0"
                            : product.stock?.quantity}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Return</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted text-capital">
                          {product.return}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Expiry Return</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.expiry_return}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Pack Of</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.pack_of}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <hr />
                    {isEthicalProduct && (
                      <>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Scheme</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.scheme}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Scheme Discount</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.scheme_percent}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}
                    {dealoftheday && (
                      <>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Deal Till</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {/* {product.deal_till} */}
                              {new Date(product.deal_till).toLocaleString()}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                      </>
                    )}

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Discount</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.discount}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Hsn Code</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.hsn + "-" + product.gst}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Delivery</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.delivery}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Deal Of The Day</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {product.deal_of_the_day == 1 ? "Yes" : "No"}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="5">
                        <MDBCardText>Created</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">
                          {new Date(product.created_at).toLocaleString()}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </CModalBody>
        <CModalFooter></CModalFooter>
      </CModal>
      <CModal size="xl" visible={editModalVisible}>
        <div class="d-flex justify-content-between m-2">
          <strong>Edit product id : {currentEditId} </strong>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </div>

        <CModalBody>
          <div className="row bg-white pl-4">
            <h5>
              <center className="mb-4 mt-3">Edit Product</center>
            </h5>

            <div className="row ">
              <div className="col-12 mb-3 ">
                <TextField
                  required
                  id="outlined-basic"
                  label="Product Name"
                  variant="outlined"
                  className="w-100"
                  name="product_name"
                  type="text"
                  placeholder="Product Name"
                  value={productname}
                  onChange={(e) => setproductname(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-lg-4">
                <select
                  required
                  className="mb-4 w-100 h-5"
                  onChange={(e) => setmanufacturer(e.target.value)}
                  name="division"
                >
                  <option value="">-Manufacturer-</option>
                  {manufacturerlist.map((items, index) => (
                    <option
                      selected={manufacturer == items.id}
                      key={index}
                      value={items.id}
                    >
                      &nbsp;&nbsp;&nbsp;{items.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-4">
                <select
                  required
                  className="mb-4 w-100 h-5"
                  onChange={(e) => setHsn(e.target.value)}
                  name="hsn"
                >
                  <option value="">-HSN Code-</option>
                  {hsnlist.map((categories, index) => (
                    <option
                      selected={
                        hsn ==
                        categories.hsc_code + "-" + categories.gst_percent
                      }
                      key={index}
                      value={categories.hsc_code + "-" + categories.gst_percent}
                    >
                      &nbsp;&nbsp;&nbsp;{categories.hsc_code} -{" "}
                      {categories.gst_percent}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg-4">
                <select
                  required
                  disabled
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  name="return"
                  className="mb-3 h-5 w-100"
                >
                  <option value="">-Type-</option>
                  <option value="ethical" disabled>
                    &nbsp;&nbsp;&nbsp;Ethical Brand
                  </option>
                  <option value="generic" disabled>
                    &nbsp;&nbsp;&nbsp;Generic Brand
                  </option>
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-4">
                <select
                  required
                  onChange={(e) => setreturnp(e.target.value)}
                  name="return"
                  className="mb-3 h-5 w-100"
                >
                  <option value="">-Return-</option>
                  <option selected={returnp == "7 Days"} value="7 Days">
                    &nbsp;&nbsp;&nbsp;7 Days
                  </option>

                  <option
                    selected={returnp == "Not Returnable"}
                    value="Not Returnable"
                  >
                    &nbsp;&nbsp;&nbsp;Not Returnable
                  </option>
                </select>
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-basic"
                  label="Pack"
                  variant="outlined"
                  className="w-100"
                  name="pack_of"
                  type="text"
                  placeholder="Pack"
                  value={packof}
                  onChange={(e) => {
                    const re = /^[a-zA-Z0-9 * .\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPackof(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  required
                  id="outlined-basic"
                  label="Composition"
                  variant="outlined"
                  className="w-100"
                  name="composition"
                  type="text"
                  placeholder="Composition"
                  value={composition}
                  onChange={(e) => setcomposition(e.target.value)}
                />
              </div>
            </div>

            {selectedType === "ethical" && (
              <>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <TextField
                      required
                      id="outlined-basic"
                      label="MRP"
                      variant="outlined"
                      className="w-100"
                      name="mrp"
                      placeholder="₹"
                      type="text"
                      value={mrp}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setmrp(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Rate"
                      variant="outlined"
                      className="w-100"
                      name="rate"
                      type="text"
                      placeholder="₹"
                      value={rate}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setrate(e.target.value);
                        }
                      }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Scheme"
                      variant="outlined"
                      className="w-100"
                      name="scheme"
                      type="text"
                      placeholder="Scheme"
                      value={scheme}
                      onChange={(e) => {
                        const re = /^[0-9 +\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setScheme(e.target.value);
                        }
                      }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Scheme Discount"
                      variant="outlined"
                      className="w-100"
                      name="scheme"
                      type="text"
                      placeholder="%"
                      value={schemeDiscount}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (
                          e.target.value === "" ||
                          (re.test(e.target.value) &&
                            parseFloat(e.target.value) <= 99)
                        ) {
                          setSchemeDiscount(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-3">
                    <select
                      // required
                      onChange={(e) => setDealOfTheDay(e.target.value)}
                      name="deal_of_the_day"
                      className="mb-3 h-5 w-100"
                    >
                      <option>-Deal Of The Day-</option>
                      <option selected={dealOfTheDay == "1"} value="1">
                        &nbsp;&nbsp;&nbsp;Yes
                      </option>
                      <option selected={dealOfTheDay == "0"} value="0">
                        &nbsp;&nbsp;&nbsp;No
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Deal Till"
                      variant="outlined"
                      className="w-100"
                      name="deal_till"
                      type="datetime-local"
                      value={dealTill}
                      onChange={(e) => {
                        const selectedDateTime = e.target.value.replace(
                          "T",
                          " "
                        );
                        if (isFutureDateTime(selectedDateTime)) {
                          setDealTill(selectedDateTime);
                        } else {
                          setDealTill("");
                        }
                      }}
                      inputProps={{ step: 1 }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      id="outlined-basic"
                      label="Discount"
                      variant="outlined"
                      className="w-100"
                      name="discount"
                      type="text"
                      placeholder="%"
                      value={discount}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (
                          e.target.value === "" ||
                          (re.test(e.target.value) &&
                            parseFloat(e.target.value) <= 99)
                        ) {
                          setDiscount(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      id="outlined-basic"
                      label="Sale Rate"
                      variant="outlined"
                      className="w-100"
                      name="expiry"
                      type="text"
                      placeholder="₹"
                      value={newsalerate}
                      onChange={(e) => setsalerate(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}

            {selectedType === "generic" && (
              <>
                <div className="row mb-3">
                  <label>Quantity 1-9</label>
                  <div className="col-lg-4">
                    <TextField
                      required
                      id="outlined-basic"
                      label="MRP"
                      variant="outlined"
                      className="w-100"
                      name="mrp"
                      placeholder="₹"
                      type="text"
                      value={mrp}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setmrp(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Rate"
                      variant="outlined"
                      className="w-100"
                      name="rate"
                      type="text"
                      placeholder="₹"
                      value={rate}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setrate(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      id="outlined-basic"
                      label="Sale Rate"
                      variant="outlined"
                      className="w-100"
                      name="expiry"
                      type="text"
                      placeholder="₹"
                      value={newsalerate}
                      onChange={(e) => setsalerate(e.target.value)}
                    />
                  </div>
                  <label>Quantity 10+</label>

                  <div className="col-lg-4">
                    <TextField
                      required
                      id="outlined-basic"
                      label="MRP"
                      variant="outlined"
                      className="w-100"
                      name="mrp"
                      placeholder="₹"
                      type="text"
                      value={mrp1}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setmrp1(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Rate"
                      variant="outlined"
                      className="w-100"
                      name="rate1"
                      type="text"
                      placeholder="₹"
                      value={rate1}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setrate1(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      id="outlined-basic"
                      label="Sale Rate"
                      variant="outlined"
                      className="w-100"
                      name="expiry"
                      type="text"
                      placeholder="₹"
                      value={newsalerate1}
                      onChange={(e) => setsalerateg(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-3"></div>
                <div className="row mb-3">
                  <div className="col-lg-4">
                    <TextField
                      id="outlined-basic"
                      label="Discount"
                      variant="outlined"
                      className="w-100"
                      name="discount"
                      type="text"
                      placeholder="%"
                      value={discount}
                      onChange={(e) => {
                        const re = /^[0-9 .\b]+$/;
                        if (
                          e.target.value === "" ||
                          (re.test(e.target.value) &&
                            parseFloat(e.target.value) <= 99)
                        ) {
                          setDiscount(e.target.value);
                        }
                      }}
                    />
                  </div>

                  <div className="col-lg-4">
                    <select
                      onChange={(e) => setDealOfTheDay(e.target.value)}
                      name="deal_of_the_day"
                      className="mb-3 h-5 w-100"
                    >
                      <option>-Deal Of The Day-</option>
                      <option selected={dealOfTheDay == "1"} value="1">
                        &nbsp;&nbsp;&nbsp;Yes
                      </option>
                      <option selected={dealOfTheDay == "0"} value="0">
                        &nbsp;&nbsp;&nbsp;No
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-4">
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Deal Till"
                      variant="outlined"
                      className="w-100"
                      name="deal_till"
                      type="datetime-local"
                      value={dealTill}
                      onChange={(e) => {
                        const selectedDateTime = e.target.value.replace(
                          "T",
                          " "
                        );
                        if (isFutureDateTime(selectedDateTime)) {
                          setDealTill(selectedDateTime);
                        } else {
                          setDealTill("");
                        }
                      }}
                      inputProps={{ step: 1 }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row mb-3">
              <div className="col-lg-3">
                <TextField
                  required
                  id="outlined-basic"
                  label="Min Order"
                  variant="outlined"
                  className="w-100"
                  name="min_order"
                  type="text"
                  placeholder="Min Order"
                  value={minorder}
                  onChange={(e) => {
                    const re = /^[0-9 \b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setminorder(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  required
                  id="outlined-basic"
                  label="Max Order"
                  variant="outlined"
                  className="w-100"
                  name="max_order"
                  type="text"
                  placeholder="Max Order"
                  value={maxorder}
                  onChange={(e) => {
                    const re = /^[0-9 \b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setmaxorder(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-lg-3">
                <select
                  required
                  onChange={(e) => setExpiryReturn(e.target.value)}
                  name="expiry_return"
                  className="mb-3 h-5 w-100"
                >
                  <option value="">-Expiry Return-</option>
                  <option
                    selected={expiryReturn == "Available"}
                    value="Available"
                  >
                    &nbsp;&nbsp;&nbsp;Available
                  </option>
                  <option
                    selected={expiryReturn == "Not Available"}
                    value="Not Available"
                  >
                    &nbsp;&nbsp;&nbsp;Not Available
                  </option>
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  required
                  onChange={(e) => setDelivery(e.target.value)}
                  name="delivery"
                  className="mb-3 h-5 w-100"
                >
                  <option value="">-Delivery-</option>

                  <option selected={delivery == "3 Hour"} value="3 Hour">
                    &nbsp;&nbsp;&nbsp;3 Hour
                  </option>
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-3">
                <img src={image} alt="" style={{ width: "60px" }} />
              </div>
              <div className="col-lg-6">
                <div>Image-1</div>
                <input
                  type="file"
                  className="w-100 mt-"
                  name="image"
                  accept=".png, .jpg, .jpeg .webp"
                  onChange={(e) => setProductimg(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-3">
                <img src={image1} alt="" style={{ width: "60px" }} />
              </div>
              <div className="col-lg-6">
                <div>Image-2</div>

                <input
                  type="file"
                  className="w-100 mt-1"
                  name="image2"
                  accept=".png, .jpg, .jpeg .webp"
                  onChange={(e) => setProductimg1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-3">
                <img src={image2} alt="" style={{ width: "60px" }} />
              </div>
              <div className="col-lg-6">
                <div>Image-3</div>
                <input
                  type="file"
                  className="w-100 mt-1"
                  name="image3"
                  accept=".png, .jpg, .jpeg .webp"
                  onChange={(e) => setProductimg2(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row my-3">
              <div className="col-12 text-center mb-5">
                <button
                  onClick={submitForm}
                  className="btn btn-danger mb-2"
                  style={{}}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </CModalBody>
        <CModalFooter></CModalFooter>
      </CModal>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Products</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4 mt-1">
                        <input
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                          onKeyUp={handleKeyUp}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>

                      <div className="col-sm-4 mt-2">
                        <select
                          className="mb-4 w-75 h-75 h-4 rounded"
                          onChange={selectbrand}
                          name="brands"
                        >
                          <option>- Select Manufacturer</option>
                          {brands.map((items, index) => (
                            <option key={index} value={items.name}>
                              &nbsp;&nbsp;&nbsp; {items.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-sm-4">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/products/add-products"
                            className="btn btn-primary "
                            style={{ marginRight: "50px" }}
                          >
                            + Add Products
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    <DataTable
                      defaultSortFieldId={1}
                      search
                      columns={columns}
                      data={users}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationPerPage={perPage}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      onChangePage={(page) => setPage(page)}
                      progressComponent={<CustomeLoader />}
                      onChangeRowsPerPage={(newPerPage, page) =>
                        handleRowsPerPageChange(newPerPage, page)
                      }
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Products;
