import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
// import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
// import { PDFReader } from "reactjs-pdf-reader";

const ViewCustomer = () => {
  const { id } = useParams();
  // const [customer, setCustomer] = useState("");

  // const [fssai, setFssai] = useState("");
  // const [pdfView, setPdfView] = useState("");
  // const [drug, setDrug] = useState("");
  const [customer, setCustomer] = useState("");

  const [value, setValue] = useState("1");

  // const downloadImage = () => {
  //   saveAs();
  // };

  // useEffect(() => {
  //   loadCustomer();
  // }, [id]);

  //----------------------------------------- FETCH SINGLE CUSTOMER-----------------------------------------


  useEffect(() =>{
    axios
    .get("/api/single_customer/" +id, {

    }).then((result) =>{
      setCustomer(result.data.data);
    }).catch((error) => {})
  }, [id]);
  

  // const loadCustomer = async () => {
  //   const result = await axios.get("/api/single_customer/" + id);
  //   console.log(result);
  //   setCustomer(result.data.data);
  //   // setFssai(
  //   //   result.data.data.fssai == null
  //   //     ? "null"
  //   //     : result.data.data.fssai.split(".")
  //   // );
  //   // setDrug(
  //   //   result.data.data.drug_license == null
  //   //     ? "null"
  //   //     : result.data.data.drug_license.split(".")
  //   // );
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------- NO ACCESS WITHOUT TOKEN -----------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setPdfView(customer.fssai);
  // };

 
  // -------------------------------------------------------------------------------------------------------------

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/customers">Customer</Link> / View
              </span>
            </div>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Details" value="1" />
                    <Tab label="Documents" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <section
                    className="bg-gre py-4"
                    style={{
                      height: "auto",
                    }}
                  >
                    <MDBContainer className="px-5 ">
                      <MDBRow>
                        <MDBCol lg="6">
                          <MDBCard className="mb-1 mt-1 ">
                            <MDBCardBody>
                              {/* <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Customer Id</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.id}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr /> */}
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Name</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                  <span className="text-capital"> {customer.name}</span>
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Email</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.email}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Mobile</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.mobile}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>GST Registered</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.gst_registered == "0"
                                      ? "URD"
                                      : customer.gst_registered == "1"
                                      ? "RD"
                                      : ""}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>GST Number</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                  {customer.gst_no !=="null" ? customer.gst_no : "Not Available"}
                                    {/* {customer.gst_no} */}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>

                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Verified</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.is_verified == 1 ? "Verified" : "Not Verified"}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />

                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Created</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {new Date(
                                      customer.created_at
                                    ).toLocaleString()}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              {/* <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Updated</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {new Date(
                                      customer.updated_at
                                    ).toLocaleString()}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow> */}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol lg="6">
                          <MDBCard className="mb-1 mt-1">
                            <MDBCardBody>
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Profile Image</MDBCardText>
                                </MDBCol>

                                <MDBCol sm="8">
                                  {customer.profile_image === null ||
                                  customer.profile_image === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.profile_image}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow className="mt-3">
                                <MDBCol sm="4">
                                  <MDBCardText>Shop Image</MDBCardText>
                                </MDBCol>

                                <MDBCol sm="8">
                                  {customer.shop_image === null ||
                                  customer.shop_image === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.shop_image}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Shop Name</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                  <span className="text-capital"> {customer.shop_name}</span>
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Shop Address</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.shop_address1}
                                    <br />
                                    {customer.shop_address2}
                                    <br />
                                    {customer.shop_city?.name}{" "}
                                    {customer.shop_state?.name}
                                    <br />
                                    {customer.pin}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Shop Verified</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                    {customer.shop_verified == 0
                                      ? "Not Verify"
                                      : customer.shop_verified == 1
                                      ? "Processing"
                                      : "Verified"}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Drug Licence Number</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                  {customer.drug_license_no !=="null" ? customer.drug_license_no : "Not Available"}

                                    {/* {customer.drug_license_no} */}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>

                              <hr />
                              <MDBRow>
                                <MDBCol sm="4">
                                  <MDBCardText>Tin Number</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="8">
                                  <MDBCardText className="text-muted">
                                  {customer.tin_no !=="null" ? customer.tin_no : "Not Avilable"}

                                    {/* {customer.tin_no} */}
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </section>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <section
                    className="bg-gre "
                    style={{
                      height: "auto",
                    }}
                  >
                    <MDBContainer className="px-5 pb-3">
                      <MDBRow className="justify-content-center">
                        <MDBCol lg="7">
                          <MDBCard className="mb-1 mt-4">
                            <MDBCardBody>
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>
                                    Shop Establishment Certificate
                                  </MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {customer.shop_establishment_certificate ===
                                    null ||
                                  customer.shop_establishment_certificate ===
                                    undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <Link
                                      target="_blank"
                                      rel="noreferrer"
                                      to={
                                        customer.shop_establishment_certificate
                                      }
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </Link>
                                  )}
                                </MDBCol>
                                {/* <MDBCol sm="2">
                                  <button
                                    onClick={downloadImage}
                                    className="btn btn-light text-purple"
                                    target="_blank"
                                  >
                                    <DownloadIcon
                                    />
                                  </button>
                                </MDBCol> */}
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Drug License</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>

                                <MDBCol sm="5">
                                  {customer.drug_license === null ||
                                  customer.drug_license === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.drug_license}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Trade Certificate</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {customer.trade_certificate === null ||
                                  customer.trade_certificate === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.trade_certificate}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Udhyam Adhar</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {customer.udhyam_adhar === null ||
                                  customer.udhyam_adhar === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.udhyam_adhar}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Fssai Certificate</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {/* {customer.fssai === null ||
                                  customer.fssai === undefined ? (
                                    "No Data"
                                  ) : fssai[fssai.length - 1] == "pdf" ? 
                                  
                                     <a target="_blank" rel="noreferrer" href={customer.fssai}><button className="btn">View</button></a>
                                   
                                   : (
                                    <MDBCardImage
                                      src={customer.fssai}
                                      alt= <span style={{color:"red" }}>No Image</span>
                                      className=" mb-1 ml-5 w-8"
                                      fluid
                                    />
                                  )} */}
                                  {customer.fssai === null ||
                                  customer.fssai === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.fssai}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Gst Certificate</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {customer.gst_certificate === null ||
                                  customer.gst_certificate === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.gst_certificate}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>
                                    Current Acount Cheque
                                  </MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>

                                <MDBCol sm="5">
                                  {customer.current_acount_cheque === null ||
                                  customer.current_acount_cheque ===
                                    undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.current_acount_cheque}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="5">
                                  <MDBCardText>Other Shop Document</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="2">
                                  <MDBCardText>→</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="5">
                                  {customer.other_shop_document === null ||
                                  customer.other_shop_document === undefined ? (
                                    <span style={{ color: "red" }}>
                                      No Image
                                    </span>
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.other_shop_document}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  )}
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </section>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCustomer;
