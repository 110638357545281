import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from "./pages/orders/Orders";
import Customers from "./pages/customers/Customers";
import AddCustomer from "./pages/customers/AddCustomer";
import EditCustomer from "./pages/customers/EditCustomer";
import ViewCustomer from "./pages/customers/ViewCustomer";
import Brands from "./pages/brands/Brands";
import AddBrands from "./pages/brands/AddBrands";
import ViewBrands from "./pages/brands/ViewBrands";
import EditBrands from "./pages/brands/EditBrands";
import Category from "./pages/category/Category";
import AddCategory from "./pages/category/AddCategory";
import Faq from "./pages/faqs/Faq";
import Privacy from "./pages/privacy/Privacy";
import Aboutus from "./pages/aboutus/Aboutus";
import EditCategory from "./pages/category/EditCategory";
import ViewCategory from "./pages/category/ViewCategory";
import Products from "./pages/products/Products";
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import ViewProduct from "./pages/products/ViewProduct";
import ViewOrder from "./pages/orders/ViewOrder";
import OfferTable from "./pages/offers/OfferTable";
import Offers from "./pages/offers/Offers";
import OrderReturn from "./pages/returns/OrderReturn";
import ExpiryReturn from "./pages/returns/ExpiryReturn";
import EditFaq from "./pages/faqs/EditFaq";
import Setting from "./pages/setting/Setting";
import BatchNumber from "./pages/batchnumber/BatchNumber";
import AddBatchNumber from "./pages/batchnumber/AddBatchNumber";
import PurchaseOrder from "./pages/purchaseorder/PurchaseOrder";
import Hsn from "./pages/hsn/Hsn";
import AddHsn from "./pages/hsn/AddHsn";
import EditHsn from "./pages/hsn/EditHsn";
import EditBatchNumber from "./pages/batchnumber/EditBatchNumber";
import PurchaseOtable from "./pages/purchaseorder/PurchaseOtable";
import ViewPurchaseOrder from "./pages/purchaseorder/ViewPurchaseOrder";
import Supplier from "./pages/supplier/Supplier";
import AddSupplier from "./pages/supplier/AddSuplier";
import EditSupplier from "./pages/supplier/EditSupplier";
import ViewOrderReturn from "./pages/returns/ViewOrderReturn";
import ViewExpiryReturn from "./pages/returns/ViewExpiryReturn";
import DealOfTheDay from "./pages/offers/DealOfTheDay";
import AddDealOfTheDay from "./pages/offers/AddDealOfTheDay";

import NewArrivals from "./pages/offers/NewArrivals";
import AddNewArrivals from "./pages/offers/AddNewArrivals";
import SuperSaving from "./pages/offers/SuperSaving";
import AddSuperSaving from "./pages/offers/AddSuperSaving";
import EditDealOfTheDay from "./pages/offers/EditDealOfTheDay";
import EditSuperSaving from "./pages/offers/EditSuperSaving";
import EditNewArrivals from "./pages/offers/EditNewArrivals";
import Advertisement from "./pages/offers/Advertisement";
import StockReport from "./pages/stockmanagement/StockReport";
import StockBatches from "./pages/stockmanagement/StockBatches";
import StockBatchDetails from "./pages/stockmanagement/StockBatchDetails";
import EarlyExpiry from "./pages/stockmanagement/EarlyExpiry";
import NonMovingStock from "./pages/stockmanagement/NonMovingStock";
import ExpiryStock from "./pages/stockmanagement/ExpiryStock";
import CouponCode from "./pages/coupon/CouponCode";
import CartList from "./pages/coupon/CartList";
import CartDetails from "./pages/coupon/CartDetails";
import FeedbackSuggestion from "./pages/feedback/FeedbackSuggestions ";
import ComplainBox from "./pages/complaintbox/ComplaintBox";
import FastMovingStock from "./pages/stockmanagement/FastMovingStock";
import OutOfStock from "./pages/stockmanagement/OutOfStock";

import HelpCenter from "./pages/helpcenter/HelpCenter";
import EditHelpCenter from "./pages/helpcenter/EditHelpCenter";
import EditSetting from "./pages/setting/EditSetting";
import EditPurchaseOrder from "./pages/purchaseorder/EditPurchaseOrder";
import GstPurchaseReport from "./pages/reports/GstPurchaseReport";
import HsnPurchaseReport from "./pages/reports/HsnPurchaseReport";
import GstSaleReport from "./pages/reports/GstSaleReport";
import HsnSaleReport from "./pages/reports/HsnSaleReport";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="/dashboard" element={<Home />} />
        </Route>
        <Route path="/customers">
          <Route index element={<Customers />} />
        </Route>

        <Route path="/products">
          <Route index element={<Products />} />
        </Route>
        <Route path="/order">
          <Route index element={<Orders />} />
        </Route>
        <Route path="/offers">
          <Route index element={<OfferTable />} />
        </Route>

        <Route path="/deal-of-the-day">
          <Route index element={<DealOfTheDay />} />
        </Route>
        <Route path="/add-deal-of-the-day">
          <Route index element={<AddDealOfTheDay />} />
        </Route>
        <Route path="/edit-deal-of-the-day/:id">
          <Route index element={<EditDealOfTheDay />} />
        </Route>

        <Route path="/super-saving">
          <Route index element={<SuperSaving />} />
        </Route>
        <Route path="/add-super-saving">
          <Route index element={<AddSuperSaving />} />
        </Route>
        <Route path="/edit-super-saving/:id">
          <Route index element={<EditSuperSaving />} />
        </Route>

        <Route path="/new-arrivals">
          <Route index element={<NewArrivals />} />
        </Route>
        <Route path="/add-new-arrivals">
          <Route index element={<AddNewArrivals />} />
        </Route>
        <Route path="/edit-new-arrivals/:id">
          <Route index element={<EditNewArrivals />} />
        </Route>

        <Route path="/advertisement">
          <Route index element={<Advertisement />} />
        </Route>

        <Route path="/order-returns">
          <Route index element={<OrderReturn />} />
        </Route>
        <Route path="/FAQ">
          <Route index element={<Faq />} />
        </Route>
        <Route path="/privacy">
          <Route index element={<Privacy />} />
        </Route>
        <Route path="/supplier">
          <Route index element={<Supplier />} />
        </Route>
        <Route path="/add-supplier">
          <Route index element={<AddSupplier />} />
        </Route>
        <Route path="/edit-supplier/:id">
          <Route index element={<EditSupplier />} />
        </Route>
        <Route path="/about-us">
          <Route index element={<Aboutus />} />
        </Route>
        <Route path="/settings">
          <Route index element={<Setting />} />
        </Route>
        <Route path="/batch-number">
          <Route index element={<BatchNumber />} />
        </Route>
        <Route path="/expiry-returns">
          <Route index element={<ExpiryReturn />} />
        </Route>
        <Route path="/view-order/:id">
          <Route index element={<ViewOrder />} />
        </Route>
        <Route path="/manufacturer">
          <Route index element={<Brands />} />
        </Route>
        <Route path="/manufacturer/view/:id">
          <Route index element={<ViewBrands />} />
        </Route>

        <Route path="/category">
          <Route index element={<Category />} />
        </Route>
        <Route path="/category/:id">
          <Route index element={<EditCategory />} />
        </Route>
        <Route path="/FAQ's/:id">
          <Route index element={<EditFaq />} />
        </Route>
        <Route path="/category/view/:id">
          <Route index element={<ViewCategory />} />
        </Route>
        <Route path="/customer/:id">
          <Route index element={<EditCustomer />} />
        </Route>
        <Route path="/customer/view/:id">
          <Route index element={<ViewCustomer />} />
        </Route>
        <Route path="/product/edit/:id">
          <Route index element={<EditProduct />} />
        </Route>
        <Route path="/product/view/:id">
          <Route index element={<ViewProduct />} />
        </Route>
        <Route path="/manufacturer/:id">
          <Route index element={<EditBrands />} />
        </Route>
        <Route path="/category/add-category">
          <Route index element={<AddCategory />} />
        </Route>
        <Route path="/offers/add-offer">
          <Route index element={<Offers />} />
        </Route>
        <Route path="/add-batch-number">
          <Route index element={<AddBatchNumber />} />
        </Route>
        <Route path="/edit-batch-number/:id">
          <Route index element={<EditBatchNumber />} />
        </Route>

        <Route path="/customer/add-customer">
          <Route index element={<AddCustomer />} />
        </Route>
        <Route path="/add-manufacturer">
          <Route index element={<AddBrands />} />
        </Route>
        <Route path="/products/add-products">
          <Route index element={<AddProduct />} />
        </Route>
        <Route path="/hsn">
          <Route index element={<Hsn />} />
        </Route>
        <Route path="/add-hsn">
          <Route index element={<AddHsn />} />
        </Route>
        <Route path="/edit-hsn/:id">
          <Route index element={<EditHsn />} />
        </Route>

        <Route path="/purchase-order">
          <Route index element={<PurchaseOtable />} />
        </Route>
        <Route path="/add-purchase-order">
          <Route index element={<PurchaseOrder />} />
        </Route>
        <Route path="/view-purchase-order/:id">
          <Route index element={<ViewPurchaseOrder />} />
        </Route>
        <Route path="/view-return-order/:id">
          <Route index element={<ViewOrderReturn />} />
        </Route>
        <Route path="/view-expiry-return-order/:id">
          <Route index element={<ViewExpiryReturn />} />
        </Route>

        <Route path="/coupon-list">
          <Route index element={<CartList />} />
        </Route>

        <Route path="/coupon">
          <Route index element={<CouponCode />} />
        </Route>

        <Route path="/cartlist/:id">
          <Route index element={<CartDetails />} />
        </Route>

        <Route path="/stock-report">
          <Route index element={<StockReport />} />
        </Route>

        <Route path="/stock-batch">
          <Route index element={<StockBatches />}></Route>
        </Route>

        <Route path="/early-expiry">
          <Route index element={<EarlyExpiry />}></Route>
        </Route>

        <Route path="/expiry-stock">
          <Route index element={<ExpiryStock />}></Route>
        </Route>

        <Route path="/non-moving-stock">
          <Route index element={<NonMovingStock />}></Route>
        </Route>

        <Route path="/stock-batch-detail/:id">
          <Route index element={<StockBatchDetails />}></Route>
        </Route>

        <Route path="/feedback">
          <Route index element={<FeedbackSuggestion />}></Route>
        </Route>

        <Route path="/ComplainBox">
          <Route index element={<ComplainBox />}></Route>
        </Route>

        <Route path="/fast-moving-stock">
          <Route index element={<FastMovingStock />}></Route>
        </Route>

        <Route path="/help-center">
          <Route index element={<HelpCenter />}></Route>
        </Route>

        <Route path="/edit-help-center">
          <Route index element={<EditHelpCenter />}></Route>
        </Route>

        <Route path="/edit-setting">
          <Route index element={<EditSetting />}></Route>
        </Route>

        <Route path="/edit-batch/:id">
          <Route index element={<EditPurchaseOrder />}></Route>
        </Route>

        <Route path="/gst-purchase">
          <Route index element={<GstPurchaseReport />}></Route>
        </Route>
        <Route path="/hsn-purchase">
          <Route index element={<HsnPurchaseReport />}></Route>
        </Route>
        <Route path="/gst-sale">
          <Route index element={<GstSaleReport />}></Route>
        </Route>
        <Route path="/hsn-sale">
          <Route index element={<HsnSaleReport />}></Route>
        </Route>

        <Route path="/privacy-policy">
          <Route index element={<PrivacyPolicy />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
