import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";

const ExpiryReturns = () => {
  const [orders, setOrders] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    axios
      .get("/api/get_all_expiry_return_orders")
      .then((res) => {
        res.data.status == "Success" ? setOrders(res.data.data) : setOrders([]);
      })

      .catch((err) => {});
  }, []);

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link
          to={`/view-expiry-return-order/${row.id}`}
          className="btn btn-primary"
        >
          <VisibilityIcon />
        </Link>
      ),
      width: "100px",
    },
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Order No",
      selector: (row, index) => row.order_number,
      width: "100px",
    },

    {
      name: "Customer",
      selector: (row) => (
        <span style={{ fontSize: "16px" }}>
          {row.customer
            ? row.customer.name || "Not Available"
            : "Not Available"}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Total",
      selector: (row) => Number(row.total).toFixed(2),

      width: "120px",
    },
    {
      name: "Payment",
      selector: (row) => row.payment_mode,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span>{row.order_status == 0 ? "Processing" : "Completed"} </span>
      ),
      width: "120px",
    },

    {
      name: "Mobile",
      selector: (row) => row.phone,
      width: "120px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(orders);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  const filteredItems = orders.filter(
    (item) =>
      (item.customer?.name &&
        item.customer?.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.order_number &&
        item.order_number.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Expiry Return</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <TextField
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {filteredItems.length == 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Data Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        defaultSortFieldId
                        fixedHeaderScrollHeight="480px"
                        fixedHeader
                        responsive
                        pagination={10}
                        allowOverflow
                        subHeaderAlign="right"
                        progressPending={pending}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExpiryReturns;
