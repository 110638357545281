import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { CCard, CCardBody, CCol, CCardHeader, CButton } from "@coreui/react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CartDetails = () => {
  const { id } = useParams();
  const token =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const navigate = useNavigate();

  const [couponDetails, setCouponDetails] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get(`/api/coupon/${id}`)
      .then((res) => {
        setCouponDetails(res.data.data);
      })
      .catch((error) => {});
  }, [id]);

  const deleteHandler = (e) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Scheme ?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete_coupon/" + id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });

            navigate("/coupon-list");
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <div className="container-fluid">
            <Navbar />
            <CCol md={12}>
              <CCard className="m-4">
                <CCardHeader className="d-flex justify-content-between">
                  <strong>View Scheme Details</strong>
                  <div>
                    <button
                      className="btn btn-danger m-1"
                      onClick={() => deleteHandler()}
                    >
                      <DeleteIcon />
                    </button>
                    <Link to="/coupon-list">
                      <CButton className="btn btn-primary">Back</CButton>
                    </Link>
                  </div>
                </CCardHeader>
                <CCardBody>
                  {Object.keys(couponDetails).length > 0 && (
                    <>
                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong> Title:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.coupon_title}
                          </span>
                        </CCol>
                      </CCol>

                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong> Description:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.coupon_desc}
                          </span>
                        </CCol>
                      </CCol>

                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong> Code:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.coupon_code}
                          </span>
                        </CCol>
                      </CCol>

                      {couponDetails.amount !== 0 && (
                        <CCol md={12} className="d-flex my-3">
                          <CCol sm={1}> </CCol>
                          <CCol sm={3}>
                            <span style={{ marginRight: "15px" }}>
                              <strong>Amount:</strong>
                            </span>
                          </CCol>
                          <CCol sm={7}>
                            <span className="text-capital">
                              {couponDetails.amount}
                            </span>
                          </CCol>
                        </CCol>
                      )}
                      {couponDetails.discount !== 0 && (
                        <CCol md={12} className="d-flex my-3">
                          <CCol sm={1}> </CCol>
                          <CCol sm={3}>
                            <span style={{ marginRight: "15px" }}>
                              <strong>Discount:</strong>
                            </span>
                          </CCol>
                          <CCol sm={7}>
                            <span className="text-capital">
                              {couponDetails.discount}
                            </span>
                          </CCol>
                        </CCol>
                      )}
                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong>Min Price:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.min_price}
                          </span>
                        </CCol>
                      </CCol>

                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong>Duration:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.duration == 0 && "Monthly"}
                            {couponDetails.duration == 1 && "Half-yearly"}
                            {couponDetails.duration == 2 && "Yearly"}
                          </span>
                        </CCol>
                      </CCol>

                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong>Start Time:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.start_time}
                          </span>
                        </CCol>
                      </CCol>

                      <CCol md={12} className="d-flex my-3">
                        <CCol sm={1}> </CCol>
                        <CCol sm={3}>
                          <span style={{ marginRight: "15px" }}>
                            <strong>End Time:</strong>
                          </span>
                        </CCol>
                        <CCol sm={7}>
                          <span className="text-capital">
                            {couponDetails.end_time}
                          </span>
                        </CCol>
                      </CCol>
                    </>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
