import React, {  useRef, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import Swal from "sweetalert2";


const AboutUs = () => {
  const editor = useRef(null);
  const [text, setText] = useState("");
  const [cont, setContent] = useState("");
  const [msg, setMsg] = useState("");

  const config = {
    placeholder: "Start typings...",
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // ---------------------------------------------FETCH ABOUT US CONTENT---------------------------

  useEffect(() => {
    axios.get("/api/aboutus").then((res) => {
      setText(res.data.data);
    });
  }, []);


  // -------------------------------------UPDATE ABOUT US CONTENT---------------------------------------

  const updateText = async (e) => {
    e.preventDefault();
    const aboutobj = new FormData();
    aboutobj.append("content", cont);
    axios
      .post("/api/update_aboutus/" + 1, aboutobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        setMsg(result.data.message);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
        // alert(error.message);
        
      });
  };

// -----------------------------------------------NO ACCESS WITHOUT TOKEN-----------------------------------


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white  ml-3">
                <span>
                  <Link to="/dashboard">Dashboard</Link> / About
                </span>
              </div>
              <div className="bg-gre  ml-3" style={{ padding: "35px",minHeight:"560px" }}>
                {/* {msg && <div className="alert alert-success">{msg}</div>} */}
                <center>
                  <h3>Please Write The Text For About Us</h3>
                </center>
                <JoditEditor
                  ref={editor}
                  value={text.content}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)}
                  onChange={(newContent) => {}}
                />
                <div>
                  <button
                    className="btn btn-danger mt-2 "
                    style={{ marginLeft: "45%" }}
                    onClick={updateText}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
