import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./brands.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const AddBrands = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [category, setcategory] = useState("");
  const [image, setImage] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submitForm = async (e) => {
    e.preventDefault();

    if (!image || !name || !category) {
      Toast.fire({
        icon: "error",
        title: "Please select All feilds.",
      });
      return;
    } else if (
      !["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(
        image.type
      )
    ) {
      Toast.fire({
        icon: "error",
        title: "Please select only PNG, JPEG, or JPG image files.",
      });
      return;
    }

    const categoryobj = new FormData();
    categoryobj.append("name", name);
    categoryobj.append("description", "");
    categoryobj.append("image", image);
    categoryobj.append("type", category);
    axios
      .post("/api/create_brand", categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/manufacturer");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/manufacturer">Manufacturer</Link> / Add
                </span>
              </div>

              <div className="bg-gre p-60">
                <div className="main-box">
                  <h5>
                    <center className="mb-4 mt-3">
                      <b>Add New Manufacturer</b>
                    </center>
                  </h5>
                  <div className="text-box">
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      className="input-field w-40 mb-3"
                      name="name"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <select
                      className="w-40 mb-4"
                      name="type"
                      onChange={(e) => setcategory(e.target.value)}
                      style={{ height: "55px" }}
                    >
                      <option value="">Select Category</option>
                      <option value="Top Rx Brand">Top Rx Brand</option>
                      <option value="OTC Brand">OTC Brand</option>
                      <option value="Generic Brand">Generic Brand</option>
                    </select>

                    <input
                      type="file"
                      className="input-field w-23 mb-4"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <button
                      onClick={submitForm}
                      className="btn btn-danger mb-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBrands;
