import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewCategory = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [create, setCreate] = useState("");
  const [update, setUpdate] = useState("");

  // -------------------------------------------FETCH SINGLE CATEGORY-------------------------------------------

  useEffect(() => {
    loadCategory();
  }, []);


  const loadCategory = async () => {
    const result = await axios.get(
      "/api/single_category/" + id
    );
    setName(result.data.data.name);
    setDescription(result.data.data.description);
    setImage(result.data.data.image);
    setCreate(result.data.data.created_at);
    setUpdate(result.data.data.updated_at);
  };

// ----------------------------------------------------NO ACCESS WITHOUT TOKEN-----------------------------

  const navigate = useNavigate();
  useEffect(() => {
        if(!localStorage.getItem('status')){
          navigate('/')
        }
  },[navigate])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0" >
          <Sidebar />
        </div>
        <div className="col-sm-10 " >
        <div className="container-fluid">
          <Navbar />
          <div className="p-2 bg-white">
            <span>
              <Link to="/dashboard">Dashboard</Link> /{" "}
              <Link to="/category">Category</Link> / View
            </span>
          </div>
          <section  className="bg-gre">
            <MDBContainer className="p-4">
              <MDBRow className="justify-content-center">
                <MDBCol lg="8">
                  <MDBCard className=" mt-3 mb-2">
                    <MDBCardBody className="text-center">
                      <MDBCardBody>
                        <MDBCardImage
                          src={image}
                          alt="avatar"
                          className=" mb-2"
                          style={{ width: "190px" }}
                          fluid
                        />
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              <span className="text-capital">{name}</span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              {description}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Created</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                          
                              { new Date(create).toLocaleString()} 
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Updated</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                          
                              { new Date(update).toLocaleString()} 
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ViewCategory;
