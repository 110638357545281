import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Link } from "react-router-dom";
import "./offers.scss";

const AddOffers = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    const offerobj = new FormData();
    offerobj.append("name", name);
    offerobj.append("description", description);
    offerobj.append("image", image);
    axios
      .post("/api/create_category", offerobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        alert(result.data.message);
        navigate("/dashboard/category");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/offers">Offers</Link> / Add
                </span>
              </div>
              <div className="bg-gre" style={{ padding: "38px" }}>
                <div className="main-box">
                  <h5>
                    <center className="mb-4 mt-3">
                      <b>Add New Offer</b>
                    </center>
                  </h5>
                  <div className="text-box">
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      className="text-field w-40 mb-4"
                      name="name"
                      type="text"
                      placeholder="Title"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      className="text-field w-40 mb-4"
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={3}
                      multiline
                    />
                    <input
                      type="file"
                      className="text-field w-23 mb-5"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <button
                      onClick={submitForm}
                      className="btn btn-danger mb-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOffers;
