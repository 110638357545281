import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";

const StockBatches = () => {
  const [stock, setStock] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [batchlist, setBatchList] = useState([]);

  const [batch, setBatch] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(0);

  const fetchData = async (e) => {
    const helpdetailsobj = new FormData();
    helpdetailsobj.append("batch_number", selectedBatch);

    try {
      setLoading(true);
      const res = await axios.post(
        `/api/batch_stock_statement?page=${page}&perPage=${perPage}`,
        helpdetailsobj
      );

      res.data.statusCode == 200 ? setBatch(res.data.data) : setBatch([]);

      setPerPage(res.data.pagination.per_page);
      setTotalRows(res.data.pagination.total);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, selectedBatch]);

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: "PID",
      selector: (row, index) => <div>#P{row.products.id}</div>,
      width: "90px",
    },

    {
      name: "Batch No.",
      selector: (row, index) => <div>{row.batch_number}</div>,
      width: "120px",
    },

    {
      name: "Product Name",
      selector: (row) => {
        const productName = row.products.product_name;
        const isProductNameAvailable = productName != null;
        const textColor = isProductNameAvailable ? "black" : "red";

        if (isProductNameAvailable && productName.length > 27) {
          return (
            <div
              className="text-capital"
              style={{ color: textColor, whiteSpace: "pre-line" }}
            >
              {productName.match(new RegExp(".{1," + 27 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return (
            <span className="text-capital" style={{ color: textColor }}>
              {isProductNameAvailable ? productName : "Product No Available"}
            </span>
          );
        }
      },
    },

    {
      name: "Type",
      selector: (row) => (
        <span className="text-capital">{row.products.type}</span>
      ),
      width: "130px",
    },

    {
      name: "Sale Rate",
      selector: (row) => (
        <span className="text-capital">{row.products.sale_rate}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Quantity",
      selector: (row) => (
        <span className="text-capital">{row.total_quantity}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Order",
      selector: (row) => (
        <span className="text-capital">{row.total_order}</span>
      ),
      width: "120px",
    },

    {
      name: "Remaning Quantity",
      selector: (row) => (
        <span className="text-capital">{row.remaning_quantity}</span>
      ),
      width: "150px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(stock);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    axios
      .get("/api/all_batches")
      .then((res) => {
        res.data.statusCode == 200
          ? setBatchList(res.data.data)
          : setBatchList([]);
      })
      .catch((err) => {});
  }, []);

  const selectbatch = (e) => {
    const selectedBatchId = e.target.value;

    setSelectedBatch(selectedBatchId);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Batchwise Stock</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="row">
                    <div className="col-lg-4 pt-2 m-3">
                      <select
                        className="mb-4 w-80 h-4 rounded"
                        onChange={selectbatch}
                        name="batchlist"
                      >
                        <option value="">- Select Batch -</option>{" "}
                        {batchlist.map((items, index) => (
                          <option key={index} value={items.id}>
                            &nbsp;&nbsp;&nbsp;{items.batch_number}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {batch.length === 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Batchs Details Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={batch}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StockBatches;
