import React from "react";
import { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";

const CouponCode = () => {
  const navigate = useNavigate();

  const [minimumPrice, setMinimumPrice] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submitForm = async (e) => {
    e.preventDefault();

    if (!name || !code || !discount || !time || !minimumPrice || !description) {
      Toast.fire({
        icon: "error",
        title: "Please fill the all fields",
      });
      return;
    }



    const productobj = new FormData();

    productobj.append("coupon_title", name);
    productobj.append("coupon_code", code);
    productobj.append("discount", discount);
    productobj.append("duration", time);
    productobj.append("min_price", minimumPrice);
    productobj.append("coupon_desc", description);

    try {
      const result = await axios
        .post("/api/create-scheme", productobj, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((result) => {
          Toast.fire({
            icon: "success",
            title: result.data.message,
          });

          navigate("/coupon-list");
        });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Add Scheme</strong>
                </CCardHeader>
                <div className="p-2 bg-white">
                  <span>
                    <Link to="/dashboard">Dashboard</Link> /{" "}
                    <Link to="/coupon-list">Scheme List</Link> / Add
                  </span>
                </div>

                <section className="bg-gre" style={{ padding: "50px" }}>
                  <div className="row bg-white pl-4">
                    <h5>
                      <center className="mb-4 mt-3">Add Scheme </center>
                    </h5>

                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          className="w-100"
                          name="scheme"
                          type="text"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label="Coupon Code"
                          variant="outlined"
                          className="w-100"
                          name="scheme"
                          type="text"
                          placeholder="Coupon Code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label="Minimum Price"
                          variant="outlined"
                          className="w-100"
                          name="scheme"
                          type="text"
                          placeholder="Minimum Price"
                          value={minimumPrice}
                          onChange={(e) => {
                            const re = /^[0-9 .\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setMinimumPrice(e.target.value);
                            }
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label="Discount%"
                          variant="outlined"
                          className="w-100"
                          type="text"
                          placeholder="Discount%"
                          value={discount}
                          onChange={(e) => {
                            const re = /^[0-9 .\b]+$/;
                            if (
                              e.target.value === "" ||
                              (re.test(e.target.value) &&
                                parseFloat(e.target.value) <= 99)
                            ) {
                              setDiscount(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <select
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                          className="mb-3 h-5 w-100"
                        >
                          <option>-Time Duration-</option>

                          <option value="0">Monthly</option>
                          <option value="1">Quarterly</option>
                          <option value="2">Yearly</option>
                        </select>
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label="Description"
                          variant="outlined"
                          className="w-100"
                          name="description"
                          type="text"
                          placeholder="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2"
                        style={{}}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </section>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponCode;
