import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { TextField } from "@mui/material";

const Brands = () => {
  const [users, setUser] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [create, setCreate] = useState("");
  const [update, setUpdate] = useState("");

  const [visibleLg, setVisibleLg] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);
  const { id } = useParams();
  const [category, setcategory] = useState("");
  const [brand, setBrand] = useState({
    name: "",
    description: "",
    image: "",
    category: "",
  });
  const [nullvalue] = useState({
    name: "",
    description: "",
    image: "",
    category: "",
  });
  const [picture, setPicture] = useState({
    image: "",
  });

  const handleInput = (e) => {
    e.persist();
    setBrand({ ...brand, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  const openEditModal = (id) => {
    setEditModalVisible(true);
    setCurrentEditId(id);
  };

  useEffect(() => {
    if (editModalVisible && currentEditId) {
      axios.get("/api/get_single_brand/" + currentEditId).then((res) => {
        setBrand(res.data.data);
        setcategory(res.data.data.type);
      });
    }
  }, [editModalVisible, currentEditId]);

  const updateBrands = async (e) => {
    e.preventDefault();

    if (!brand.name || !category) {
      Toast.fire({
        icon: "error",
        title: "Please select All feilds.",
      });
      return;
    }

    const brandobj = new FormData();
    brandobj.append("name", brand.name);
    brandobj.append("description", "");
    brandobj.append("image", picture.image);
    brandobj.append("type", category);
    brandobj.append("_method", "put");

    axios
      .post("/api/update_brand/" + currentEditId, brandobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        setEditModalVisible(false);
        setBrand(nullvalue);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const openModal = (brandId) => {
    setSelectedBrandId(brandId);
    setVisibleLg(true);
  };

  useEffect(() => {
    if (visibleLg && selectedBrandId) {
      axios
        .get("/api/get_single_brand/" + selectedBrandId)
        .then((result) => {
          setName(result.data.data.name);
          setImage(result.data.data.image);
          setType(result.data.data.type);
          setCreate(result.data.data.created_at);
          setUpdate(result.data.data.updated_at);
        })
        .catch((error) => {});
    }
  }, [visibleLg, selectedBrandId]);

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        res.data.status == "Success" ? setUser(res.data.data) : setUser([]);
      })

      .catch((err) => {});
  }, [editModalVisible, currentEditId]);

  const deleteHandler = (row) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Manufacturer?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete_brand/" + row.id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });

            let newArr = users.filter((items) => {
              return row.id !== items.id;
            });
            setUser(newArr);
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <div style={{ width: "90px" }}>
          <CButton color="primary" onClick={() => openModal(row.id)}>
            <VisibilityIcon />
          </CButton>
        </div>
      ),
      width: "90px",
    },

    {
      name: "#",
      selector: (row, index) => <div>{index + 1}</div>,
      width: "70px",
    },
    {
      name: "Image",
      selector: (row) => (
        <img width={60} className="m-1" src={row.image} alt="MDN logo" />
      ),
      width: "150px",
    },

    {
      name: "Name",
      selector: (row) => {
        return row.name ? (
          <span className="text-capital">{row.name}</span>
        ) : (
          "Not available"
        );
      },
      width: "220px",
    },

    {
      name: "Category",
      selector: (row) => row.type,
      width: "170px",
    },
    {
      name: "Created",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      width: "120px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <CButton color="primary" onClick={() => openEditModal(row.id)}>
            <EditIcon />
          </CButton>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "160px",
      sortable: false,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(users);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const filteredItems = users.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.type && item.type.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <CModal
        size="lg"
        backdrop="static"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="ViewBrandModal"
      >
        <CModalHeader closeButton>
          <CModalTitle style={{ marginLeft: "75px" }}>
            <strong>View Brand </strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Brand ID: {selectedBrandId}
          <MDBContainer className="px-5 py-3 ">
            <MDBRow className="justify-content-center">
              <MDBCol lg="8">
                <MDBCard className=" mt-3">
                  <MDBCardBody className="text-center">
                    <MDBCardBody>
                      <MDBCardImage
                        src={image}
                        alt="avatar"
                        className=" mb-2"
                        style={{ width: "190px" }}
                        fluid
                      />

                      <hr />
                      <MDBRow>
                        <MDBCol sm="4">
                          <MDBCardText>Name</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="8">
                          <MDBCardText className="text-muted">
                            {name}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />

                      <MDBRow>
                        <MDBCol sm="4">
                          <MDBCardText>Category</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="8">
                          <MDBCardText className="text-muted">
                            {type}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="4">
                          <MDBCardText>Created</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="8">
                          <MDBCardText className="text-muted">
                            {new Date(create).toLocaleDateString()}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                    </MDBCardBody>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </CModalBody>
      </CModal>

      <CModal
        size="lg"
        backdrop="static"
        visible={editModalVisible}
        aria-labelledby="ViewBrandModal"
      >
        <div class="d-flex justify-content-between m-2">
          <strong>Edit Brand ID : {currentEditId}</strong>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </div>

        <CModalBody>
          <h5>
            <center className="mb-3 mt-3">
              <b>Edit Manufacturer</b>
            </center>
          </h5>
          <form onSubmit={updateBrands} encType="multipart/form-data">
            <div className="text-box">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                className="w-40 mx-auto mb-2"
                name="name"
                type="text"
                placeholder="Name"
                value={brand.name}
                onChange={handleInput}
              />

              <select
                className="w-40 mb-4"
                name="type"
                onChange={(e) => setcategory(e.target.value)}
                style={{ height: "55px" }}
              >
                <option value="">Select Category</option>
                <option
                  selected={brand.type == "Top Rx Brand"}
                  value="Top Rx Brand"
                >
                  Top Rx Brand
                </option>
                <option selected={brand.type == "OTC Brand"} value="OTC Brand">
                  OTC Brand
                </option>
                <option
                  selected={brand.type == "Generic Brand"}
                  value="Generic Brand"
                >
                  Generic Brand
                </option>
              </select>
              <div className="text-center mb-3 image-box">
                <img src={brand.image} alt="" className="m-3 w-10" />
              </div>
              <input
                type="file"
                className="w-23 mx-auto mb-3"
                name="image"
                accept=".png, .jpg, .jpeg"
                onChange={handleImage}
              />
              <button className="btn btn-danger mb-2">Submit</button>
            </div>
          </form>
        </CModalBody>
      </CModal>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Manufacturer</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <input
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/add-manufacturer"
                            className="btn btn-primary mr-5 "
                          >
                            + Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="m-1">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination={10}
                      subHeaderAlign="right"
                      highlightOnHover
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Brands;
