import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";

const AddDealOfTheDay = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState([]);

  const [singleproduct, setSingleproduct] = useState("");

  const [title, setTitle] = useState("");
  const [mainimg, setMainimg] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submitForm = async (e) => {
    e.preventDefault();

    if (!title || !singleproduct || !mainimg) {
      Toast.fire({
        icon: "error",
        title: "Please fill out all fields.",
      });
      return;
    } else if (
      !["image/png", "image/jpeg", "image/jpg"].includes(mainimg.type)
    ) {
      Toast.fire({
        icon: "error",
        title: "Please select only PNG, JPEG, or JPG image files.",
      });
      return;
    }

    const productobj = new FormData();
    productobj.append("product_id", singleproduct);
    productobj.append("main_img", mainimg);

    productobj.append("title", title);
    await axios
      .post("/api/add-deal-of-days", productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        result.data.status == 400
          ? navigate("/add-deal-of-the-day")
          : navigate("/deal-of-the-day");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    axios
      .get("/api/deal_products")
      .then((res) => {
        setProduct(res.data.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/deal-of-the-day">Deal of the Day</Link> / Add Deal
                  of the Day
                </span>
              </div>

              <section className="bg-gre" style={{ padding: "50px" }}>
                <div className="row bg-white pl-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded">
                  <h5>
                    <center className="mb-4 mt-3">Add Deal of the Day</center>
                  </h5>

                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        className="w-100"
                        name="product_name"
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <select
                        onChange={(e) => setSingleproduct(e.target.value)}
                        name="state"
                        className="w-100 h-5"
                      >
                        <option>----Select Product----</option>
                        {product.map((items, index) => (
                          <option
                            selected={singleproduct === items.id}
                            key={index}
                            value={items.id}
                          >
                            &nbsp;&nbsp;&nbsp;{items.product_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-2 mb-4">
                    <div className="col-lg-3">
                      <div>Image-1</div>
                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image"
                        accept=".png, .jpg, .jpeg .webp"
                        onChange={(e) => setMainimg(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDealOfTheDay;
