import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCol,
  CCardHeader,
  CImage,
  CFormInput,
  CFormLabel,
  CButton,
  CForm,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import Swal from "sweetalert2";

const EditSupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contactPname, setContactPame] = useState("");
  const [headname, setHeadName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gstRegistered, setGstRegistered] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [drugLnumber, setDrugLnumber] = useState("");

  // -------------------------------------------FETCH SINGLE SUPPLIER---------------------------------------------

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    loadCustomer();
  }, []);
  const loadCustomer = async () => {
    const result = await axios.get("/api/single_supplier/" + id);
    setHeadName(result.data.data.head_name);
    setDrugLnumber(result.data.data.drug_license);
    setPinCode(result.data.data.pincode);
    setContactPame(result.data.data.contact_person);
    setCity(result.data.data.city);
    setDistrict(result.data.data.district);
    setGstRegistered(result.data.data.gst_registered);
    setGstNumber(result.data.data.gst_number);
    setMobile(result.data.data.mobile);
    setAddress(result.data.data.address);
  };

  // ---------------------------------------------UPDATE SUPPLIER BY POST METHOD----------------------------------

  const submitForm = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();
    categoryobj.append("address", address);
    categoryobj.append("mobile", mobile);
    categoryobj.append("city", city);
    categoryobj.append("head_name", headname);
    categoryobj.append("contact_person", contactPname);
    categoryobj.append("district", district);
    categoryobj.append("pincode", pinCode);
    categoryobj.append("drug_license", drugLnumber);
    categoryobj.append("gst_registered", gstRegistered);
    categoryobj.append("gst_number", gstNumber);
    axios
      .post("/api/update_supplier/" + id, categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        console.log(id);

        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        navigate("/supplier");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  // ---------------------------------------------NO ACCESS WITHOUT TOKEN----------------------

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    // Check if input value is numeric and does not exceed 10 digits
    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/supplier">Supplier</Link> / Edit
                </span>
              </div>
              <div className="bg-gre p-5">
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardHeader>
                      <strong>Edit Supplier</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CForm className="row g-3" onSubmit={submitForm}>
                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Account Head Name
                          </CFormLabel>

                          <CFormInput
                            value={headname}
                            required
                            name="head_name"
                            // onChange={(e) => setHeadName(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setHeadName(input);
                            }}
                            onKeyDown={(e) => {
                              // Prevent typing of non-alphabetical characters
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>
                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            City
                          </CFormLabel>
                          <CFormInput
                            required
                            name="city"
                            value={city}
                            // onChange={(e) => setCity(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setCity(input);
                            }}
                            onKeyDown={(e) => {
                              // Prevent typing of non-alphabetical characters
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        {/* <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            City
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setCity(input);
                            }}
                            onKeyPress={(e) => {
                              // Prevent typing of numeric characters
                              if (!/[a-zA-Z]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol> */}
                        
                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Contact Person Name
                          </CFormLabel>
                          <CFormInput
                            required
                            name="contact_person"
                            value={contactPname}
                            // onChange={(e) => setContactPame(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setContactPame(input);
                            }}
                            onKeyDown={(e) => {
                              // Prevent typing of non-alphabetical characters
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>
                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Mobile
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => handleNumericInput(e, setMobile)}
                            id="inputMobile"
                            placeholder=""
                            value={mobile} // Make sure to bind the mobile state to the input value
                            minLength={10}

                          />
                        </CCol>
                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Address
                          </CFormLabel>
                          <CFormTextarea
                            value={address}
                            name="address"
                            onChange={(e) => setAddress(e.target.value)}
                            id="exampleFormControlTextarea1"
                            required
                            placeholder="Address"
                            rows="1"
                          ></CFormTextarea>
                        </CCol>

                        {/* <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            District
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setDistrict(input);
                            }}
                            onKeyPress={(e) => {
                              // Prevent typing of numeric characters
                              if (!/[a-zA-Z]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol> */}

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            District
                          </CFormLabel>
                          <CFormInput
                            required
                            value={district}
                            name="district"
                            // onChange={(e) => setDistrict(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              ); // Remove non-alphabetical characters
                              setDistrict(input);
                            }}
                            onKeyDown={(e) => {
                              // Prevent typing of non-alphabetical characters
                              if (!e.key.match(/[a-zA-Z ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>

                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Pincode
                          </CFormLabel>
                          <CFormInput
                            required
                            onChange={(e) => handleNumericInput(e, setPinCode)}
                            id="inputPincode"
                            placeholder=""
                            value={pinCode}
                            maxLength={6} // Ye attribute 6 ankon tak ki sankhya ko pratibaddh karega
                            minLength={6}
                          />
                        </CCol>

                        <CCol md={6} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Drug License No
                          </CFormLabel>
                          <CFormInput
                            required
                            name="drug_license"
                            value={drugLnumber}
                            onChange={(e) => setDrugLnumber(e.target.value)}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>
                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            GST Registered
                          </CFormLabel>
                          <select
                            required
                            name="gst_registered"
                            onChange={(e) => setGstRegistered(e.target.value)}
                            className="w-100 "
                            style={{ height: "38px" }}
                          >
                            <option value="">Select</option>
                            <option selected={gstRegistered == "1"} value="1">
                              RD
                            </option>
                            <option selected={gstRegistered == "0"} value="0">
                              URD
                            </option>
                          </select>
                        </CCol>
                        <CCol md={3} className="">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            GST No
                          </CFormLabel>
                          <CFormInput
                            value={gstNumber}
                            name="gst_number"
                            required
                            // onChange={(e) => setGstNumber(e.target.value)}
                            onChange={(e) => {
                                  const input = e.target.value.replace(
                                    /[^a-zA-Z0-9 ]/g,
                                    ""
                                  ); // Allow only alphanumeric characters
                                  setGstNumber(input);
                                }}
                                onKeyDown={(e) => {
                              // Prevent typing of non-alphabetical characters
                              if (!e.key.match(/[a-zA-Z0-9 ]/)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputAddress"
                            placeholder=""
                          />
                        </CCol>
                        <CCol md={12} className="text-center">
                          <button
                            type="submit"
                            className="btn btn-danger my-3 "
                          >
                            Submit
                          </button>
                        </CCol>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSupplier;
