import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./product.scss";
import axios from "axios";
import Swal from "sweetalert2";
const AddProduct = () => {
  const navigate = useNavigate();
  const [productimg, setProductimg] = useState("");
  const [productimg1, setProductimg1] = useState("");
  const [productimg2, setProductimg2] = useState("");
  const [manufacturer, setmanufacturer] = useState("");
  const [packof, setPackof] = useState("");
  const [discount, setDiscount] = useState("");

  const [scheme, setScheme] = useState("");
  const [schemeDiscount, setSchemeDiscount] = useState("");
  const [maxorder, setmaxorder] = useState("");
  const [minorder, setminorder] = useState("");
  const [rate, setrate] = useState("");
  const [salerate, setsalerate] = useState("");
  const [salerateg, setsalerateg] = useState("");

  const [mrp, setmrp] = useState("");
  const [productname, setproductname] = useState("");
  const [delivery, setDelivery] = useState("");
  const [returnp, setreturnp] = useState("");
  const [expiryReturn, setExpiryReturn] = useState("");
  const [dealOfTheDay, setDealOfTheDay] = useState("");
  const [dealTill, setDealTill] = useState("");

  const [hsn, setHsn] = useState("");
  const [composition, setcomposition] = useState("");
  const [manufacturerlist, setmanufacturerlist] = useState([]);
  const [hsnlist, setHsnlist] = useState([]);

  const [mrp1, setmrp1] = useState("");
  const [rate1, setrate1] = useState("");

  const [selectedType, setSelectedType] = useState("");

  const isFutureDateTime = (selectedDateTime) => {
    const selectedDate = new Date(selectedDateTime);
    const now = new Date();
    const difference = selectedDate.getTime() - now.getTime();
    const hoursDifference = difference / (1000 * 3600);
    return hoursDifference >= 24;
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let newsalerate = (
    (rate * (100 - Number(discount)) * (100 - Number(schemeDiscount))) /
    10000
  ).toFixed(2);
  newsalerate = Math.max(0, parseFloat(newsalerate));

  const newsalerate1 =
    discount === "0"
      ? parseFloat(rate1.toFixed(2))
      : parseFloat(((rate1 * (100 - Number(discount))) / 100).toFixed(2));

  const submitForm = async (e) => {
    e.preventDefault();

    if (selectedType === "generic" && (mrp === "" || rate === "")) {
      Toast.fire({
        icon: "error",
        title: "Please fill in the MRP and Rate for Quantity 1-9.",
      });

      return;
    }

    if (selectedType === "generic" && (mrp1 === "" || rate1 === "")) {
      Toast.fire({
        icon: "error",
        title: "Please fill in the MRP and Rate for Quantity 10+.",
      });

      return;
    }

    if (!productimg || !productimg1 || !productimg2) {
      {
        Toast.fire({
          icon: "error",
          title: "Please select all three images.",
        });

        return;
      }
    } else if (
      !["image/png", "image/jpeg", "image/jpg"].includes(productimg.type) ||
      !["image/png", "image/jpeg", "image/jpg"].includes(productimg1.type) ||
      !["image/png", "image/jpeg", "image/jpg"].includes(productimg2.type)
    ) {
      Toast.fire({
        icon: "error",
        title: "Please select only PNG, JPEG, or JPG image files.",
      });

      return;
    }

    if (!dealOfTheDay) {
      Toast.fire({
        icon: "error",
        title: "Please select Deal of the day.",
      });
      return;
    }

    if (dealOfTheDay === "1" && !dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please select the Deal Till date.",
      });
      return;
    }

    if (dealOfTheDay === "0" && dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please clear the Deal Till date.",
      });
      return;
    }

    const productobj = new FormData();
    productobj.append("product_name", productname);
    productobj.append("min_order", minorder);
    productobj.append("max_order", maxorder);
    productobj.append("type", selectedType);
    productobj.append("division", manufacturer);
    productobj.append("return", returnp);
    productobj.append("pack_of", packof);
    productobj.append("rate", rate);
    productobj.append("rate_g", rate1);
    productobj.append("discount", discount);
    productobj.append("sale_rate", newsalerate);
    productobj.append("sale_rate_g", newsalerate1);
    productobj.append("scheme", scheme);
    productobj.append("scheme_percent", schemeDiscount);
    productobj.append("mrp", mrp);
    productobj.append("mrp_g", mrp1);
    productobj.append("expiry_return", expiryReturn);
    productobj.append("composition", composition);
    productobj.append("delivery", delivery);
    productobj.append("deal_of_the_day", dealOfTheDay);
    productobj.append("deal_till", dealTill);
    productobj.append("hsn", hsn);
    productobj.append("image", productimg);
    productobj.append("image2", productimg1);
    productobj.append("image3", productimg2);
    await axios
      .post("/api/create_product", productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        setmanufacturerlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axios
      .get("/api/get_hsn")
      .then((res) => {
        setHsnlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/products">Product</Link> / Add
                </span>
              </div>

              <section className="bg-gre" style={{ padding: "50px" }}>
                <div className="row bg-white pl-4">
                  <h5>
                    <center className="mb-4 mt-3">Add New Product</center>
                  </h5>

                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Product Name"
                        variant="outlined"
                        className="w-100"
                        name="product_name"
                        type="text"
                        placeholder="Product Name"
                        value={productname}
                        onChange={(e) => setproductname(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-lg-4">
                      <select
                        required
                        className="mb-4 w-100 h-5"
                        onChange={(e) => setmanufacturer(e.target.value)}
                        name="division"
                      >
                        <option value="">-Manufacturer-</option>
                        {manufacturerlist.map((items, index) => (
                          <option
                            selected={manufacturer == items.id}
                            key={index}
                            value={items.id}
                          >
                            &nbsp;&nbsp;&nbsp;{items.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <select
                        required
                        className="mb-4 w-100 h-5"
                        onChange={(e) => setHsn(e.target.value)}
                        name="hsn"
                      >
                        <option value="">-HSN Code-</option>
                        {hsnlist.map((categories, index) => (
                          <option
                            key={index}
                            value={
                              categories.hsc_code + "-" + categories.gst_percent
                            }
                          >
                            &nbsp;&nbsp;&nbsp;{categories.hsc_code} -{" "}
                            {categories.gst_percent}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-lg-4">
                      <select
                        required
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        name="return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Type-</option>
                        <option value="ethical">
                          &nbsp;&nbsp;&nbsp;Ethical Brand
                        </option>
                        <option value="generic">
                          &nbsp;&nbsp;&nbsp;Generic Brand
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <select
                        required
                        onChange={(e) => setreturnp(e.target.value)}
                        name="return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Return-</option>

                        <option value="7 Days">&nbsp;&nbsp;&nbsp;7 Days</option>
                        <option value="Not Returnable">
                          &nbsp;&nbsp;&nbsp;Not Returnable
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Pack"
                        variant="outlined"
                        className="w-100"
                        name="pack_of"
                        type="text"
                        placeholder="Pack"
                        value={packof}
                        onChange={(e) => {
                          const re = /^[a-zA-Z0-9 * .\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setPackof(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Composition"
                        variant="outlined"
                        className="w-100"
                        name="composition"
                        type="text"
                        placeholder="Composition"
                        value={composition}
                        onChange={(e) => setcomposition(e.target.value)}
                      />
                    </div>
                  </div>

                  {selectedType === "ethical" && (
                    <>
                      <div className="row mb-3">
                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate"
                            type="text"
                            placeholder="₹"
                            value={rate}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            id="outlined-basic"
                            label="Scheme Name"
                            variant="outlined"
                            className="w-100"
                            name="scheme"
                            type="text"
                            placeholder="Scheme Name"
                            value={scheme}
                            onChange={(e) => {
                              const re = /^[0-9 +\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setScheme(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            id="outlined-basic"
                            label="Scheme Discount"
                            variant="outlined"
                            className="w-100"
                            name="scheme_percent"
                            type="text"
                            placeholder="%"
                            value={schemeDiscount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setSchemeDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-3">
                          <select
                            required
                            onChange={(e) => setDealOfTheDay(e.target.value)}
                            name="deal_of_the_day"
                            className="mb-3 h-5 w-100"
                          >
                            <option>-Deal Of The Day-</option>
                            <option value="1">&nbsp;&nbsp;&nbsp;Yes</option>
                            <option value="0">&nbsp;&nbsp;&nbsp;No</option>
                          </select>
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            label="Deal Till"
                            variant="outlined"
                            className="w-100"
                            name="deal_till"
                            type="datetime-local"
                            value={dealTill}
                            onChange={(e) => {
                              const selectedDateTime = e.target.value.replace(
                                "T",
                                " "
                              );
                              if (isFutureDateTime(selectedDateTime)) {
                                setDealTill(selectedDateTime);
                              } else {
                                setDealTill("");
                              }
                            }}
                            inputProps={{ step: 1 }}
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            id="outlined-basic"
                            label="Discount"
                            variant="outlined"
                            className="w-100"
                            name="discount"
                            type="text"
                            placeholder="%"
                            value={discount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate}
                            onChange={(e) => setsalerate(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectedType === "generic" && (
                    <>
                      <div className="row mb-3">
                        <label>Quantity 1-9</label>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate"
                            type="text"
                            placeholder="₹"
                            value={rate}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate}
                            onChange={(e) => setsalerate(e.target.value)}
                          />
                        </div>
                        <label>Quantity 10+</label>

                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp1}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp1(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate1"
                            type="text"
                            placeholder="₹"
                            value={rate1}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate1(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate1}
                            onChange={(e) => setsalerateg(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-lg-4">
                          <TextField
                            id="outlined-basic"
                            label="Discount"
                            variant="outlined"
                            className="w-100"
                            name="discount"
                            type="text"
                            placeholder="%"
                            value={discount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-4">
                          <select
                            required
                            onChange={(e) => setDealOfTheDay(e.target.value)}
                            name="deal_of_the_day"
                            className="mb-3 h-5 w-100"
                          >
                            <option>-Deal Of The Day-</option>
                            <option value="1">&nbsp;&nbsp;&nbsp;Yes</option>
                            <option value="0">&nbsp;&nbsp;&nbsp;No</option>
                          </select>
                        </div>

                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            label="Deal Till"
                            variant="outlined"
                            className="w-100"
                            name="deal_till"
                            type="datetime-local"
                            value={dealTill}
                            onChange={(e) => {
                              const selectedDateTime = e.target.value.replace(
                                "T",
                                " "
                              );
                              if (isFutureDateTime(selectedDateTime)) {
                                setDealTill(selectedDateTime);
                              } else {
                                setDealTill("");
                              }
                            }}
                            inputProps={{ step: 1 }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Min Order"
                        variant="outlined"
                        className="w-100"
                        name="min_order"
                        type="text"
                        placeholder="Min Order"
                        value={minorder}
                        onChange={(e) => {
                          const re = /^[0-9 \b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setminorder(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Max Order"
                        variant="outlined"
                        className="w-100"
                        name="max_order"
                        type="text"
                        placeholder="Max Order"
                        value={maxorder}
                        onChange={(e) => {
                          const re = /^[0-9 \b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setmaxorder(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <select
                        required
                        onChange={(e) => setExpiryReturn(e.target.value)}
                        name="expiry_return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Expiry Return-</option>
                        <option value="Available">
                          &nbsp;&nbsp;&nbsp;Available
                        </option>
                        <option value="Not Available">
                          &nbsp;&nbsp;&nbsp;Not Available
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <select
                        required
                        onChange={(e) => setDelivery(e.target.value)}
                        name="delivery"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Delivery-</option>

                        <option value="3 Hour">&nbsp;&nbsp;&nbsp;3 Hour</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2 mb-4">
                    <div className="col-lg-3">
                      <div>Image-1</div>
                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image"
                        accept=".png, .jpg, .jpeg .webp"
                        onChange={(e) => setProductimg(e.target.files[0])}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div>Image-2</div>
                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image2"
                        accept=".png, .jpg, .jpeg .webp"
                        onChange={(e) => setProductimg1(e.target.files[0])}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div>Image-3</div>
                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image3"
                        accept=".png, .jpg, .jpeg .webp"
                        onChange={(e) => setProductimg2(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2 "
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
