import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";

const ExpiryStock = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [batch, setBatch] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/expiry_stock_statement?page=${page}&perPage=${perPage}`
      );

      res.data.statusCode == 200 ? setBatch(res.data.data) : setBatch([]);

      setPerPage(res.data.per_page);
      setTotalRows(res.data.total);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage]);

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: "PID",
      selector: (row, index) => <div>#P{row.products.id}</div>,
      width: "90px",
    },

    {
      name: "Batch Number",
      selector: (row, index) => <div>{row.batch_number}</div>,
      width: "150px",
    },

    {
      name: "Product Name",
      selector: (row) => {
        const productName = row.products.product_name;
        const isProductNameAvailable = productName != null;
        const textColor = isProductNameAvailable ? "black" : "red";

        if (isProductNameAvailable && productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ color: textColor, whiteSpace: "pre-line" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return (
            <span className="text-capital" style={{ color: textColor }}>
              {isProductNameAvailable ? productName : "Product No Available"}
            </span>
          );
        }
      },
    },

    {
      name: "Type",
      selector: (row) => (
        <span className="text-capital">{row.products.type}</span>
      ),
      width: "150px",
    },

    {
      name: "Rate",
      selector: (row) => (
        <span className="text-capital">{row.products.rate}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Quantity",
      selector: (row) => (
        <span className="text-capital">{row.total_quantity}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Order",
      selector: (row) => (
        <span className="text-capital">{row.total_order}</span>
      ),
      width: "120px",
    },

    {
      name: "Remaning Quantity",
      selector: (row) => (
        <span className="text-capital">{row.remaning_quantity}</span>
      ),
      width: "150px",
    },
  ];

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const filteredItems = batch.filter(
    (item) =>
      (item.products.product_name &&
        item.products.product_name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.batch_number &&
        item.batch_number.toLowerCase().includes(filterText.toLowerCase()))
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Expiry Stock</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="col-sm-6 mt-1">
                    <TextField
                      type="text"
                      placeholder="Search...."
                      value={filterText}
                      autoFocus
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button
                      className="btn btn-primary m-1"
                      onClick={handleClear}
                    >
                      X
                    </button>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {filteredItems.length === 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Expiry Stock Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExpiryStock;
