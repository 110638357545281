import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
const StockReport = () => {
  const [stock, setStock] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [brands, setBrands] = useState([]);

  const [brand, setBrand] = useState("");

  const today = new Date();
  const [invoice, setInvoice] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const fetchData = async () => {
    const helpdetailsobj = new FormData();
    helpdetailsobj.append("manufacturer_search", brand);
    helpdetailsobj.append("start_date", formattedStartDate);
    helpdetailsobj.append("end_date", formattedEndDate);

    try {
      setLoading(true);
      const res = await axios.post(
        `/api/stock_statement?page=${page}&perPage=${perPage}&search=${search}`,
        helpdetailsobj
      );
      if (res.data.statusCode == 200) {
        setStock(res.data.data.data);
        setInvoice(res.data.download_pdf);
        setPerPage(res.data.data.per_page);
        setTotalRows(res.data.data.total);
      } else {
        setStock([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, brand, formattedStartDate, formattedEndDate]);

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: "PID",
      selector: (row, index) => <div>#P{row.id}</div>,
      width: "90px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product_name
          ? row.product_name
          : "No Product Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "220px",
    },

    {
      name: "Manufacturer",
      selector: (row) => (
        <span className="text-capital">{row.manufacturer}</span>
      ),
      width: "150px",
    },

    {
      name: "Type",
      selector: (row) => <span className="text-capital">{row.type}</span>,
      width: "130px",
    },

    {
      name: "Date",
      selector: (row) => <span className="text-capital">{row.date}</span>,
      width: "130px",
    },

    {
      name: "Sale Rate",
      selector: (row) => <span className="text-capital">{row.sale_rate}</span>,
      width: "150px",
    },

    {
      name: "Total Quantity",
      selector: (row) => (
        <span className="text-capital">{row.total_quantity}</span>
      ),
      width: "120px",
    },

    {
      name: "Total Order",
      selector: (row) => (
        <span className="text-capital">{row.total_order}</span>
      ),
      width: "120px",
    },

    {
      name: "Remaning Quantity",
      selector: (row) => (
        <span className="text-capital">{row.remaning_quantity}</span>
      ),
      width: "170px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(stock);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const selectbrand = (e) => {
    const selectedBrandId = e.target.value;

    setBrand(selectedBrandId);
    console.log(selectedBrandId);
  };

  const CustomInput = ({ value, onClick }) => (
    <button
      style={{
        width: "250px",
        padding: "5px",
        textAlign: "center",
        backgroundColor: "#ffffff",
        border: "1px solid black",
      }}
      className="custom-date-input"
      onClick={onClick}
    >
      {value || "Select Date"}
    </button>
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <style>
                    {`
      .no-hover-effect {
        background-color: #110854; /* Same as your button's background color */
        color: #ffffff; /* Same as your button's text color */
      }

      .no-hover-effect:hover,
      .no-hover-effect:focus {
        background-color: #110854; /* Prevent color change on hover and focus */
        color: #ffffff; /* Prevent text color change on hover and focus */
        outline: none; /* Remove focus outline */
        box-shadow: none; /* Remove focus box-shadow */
      }
    `}
                  </style>
                  <div className="d-flex justify-content-between align-items-center">
                    <strong>Stock Statement</strong>
                    <div className="d-flex align-items-center m-1">
                      <strong>Download pdf: </strong>

                      <Link to={invoice}>
                        <button
                          className="btn no-hover-effect"
                          style={{ border: "none", padding: "0 10px" }}
                        >
                          {" "}
                          <DownloadIcon
                            style={{
                              width: "20px",
                              color: "#ffffff",
                              borderRadius: "50%",
                            }}
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </CCardHeader>
                <div className="customerContainer">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="col-sm-4 mt-4">
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                        onKeyUp={handleKeyUp}
                      />
                      <button
                        className="btn btn-primary m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>
                    </div>

                    <div className="col-sm-3 pt-2 mt-2 mr-2">
                      <select
                        className="mb-4 w-100 h-75 mt-2 rounded"
                        onChange={selectbrand}
                        name="brands"
                      >
                        <option>- Select Manufacturer</option>
                        {brands.map((items, index) => (
                          <option key={index} value={items.id}>
                            &nbsp;&nbsp;&nbsp; {items.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-4 pt-2 mr-2 d-flex flex-column align-items-start">
                      <div className="d-flex justify-content-between align-items-center w-75">
                        <div>
                          <strong>Start Date</strong>{" "}
                        </div>
                        <div>
                          <strong>End Date</strong>{" "}
                        </div>
                      </div>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          if (
                            update &&
                            Array.isArray(update) &&
                            update.length === 2
                          ) {
                            const start = update[0];
                            const end = update[1];
                            setStartDate(start);
                            setEndDate(end);
                          } else {
                            setStartDate(null);
                            setEndDate(null);
                          }
                        }}
                        isClearable={true}
                        withPortal
                        showIcon
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {stock.length === 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>No Stock Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={stock}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StockReport;
