import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewProduct = () => {
  // const { id } = useParams();
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [product, setProduct] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1;
  const [currentPageInView, setCurrentPageInView] = useState(currentPage);
  // ----------------------------------------------FETCH SINGLE PRODUCT---------------------------------------------
  // const history = useHistory();
  // const { id } = useParams();
  // const page = new URLSearchParams(window.location.search).get("page") || 1;

  // const location = useLocation();
  // const currentPage = new URLSearchParams(location.search).get("page") || 1;

  useEffect(() => {
    loadCustomer();

    // Store the current page in local storage
    localStorage.setItem("currentPageInView", currentPage);
    console.log("Stored current page in local storage:", currentPage);
  }, []);

  const loadCustomer = async () => {
    const result = await axios.get("/api/get_single_product/" + id);
    setProduct(result.data.data);
    setImage1(result.data.data.image);
    setImage2(result.data.data.image2);
    setImage3(result.data.data.image3);
  };

  const navigateBack = () => {
    // Get the current page from local storage
    const storedPage = localStorage.getItem("currentPageInView");
    console.log("Navigating back to page:", storedPage);
    // Navigate to the previous page with the correct page parameter
    navigate(`/products?page=${storedPage}`);
  };

  const handleNavigateBack = async () => {
    try {
      // Make the API call
      const storedPage = localStorage.getItem("currentPageInView");
      const res = await axios.get(`api/products_test?page=${storedPage}`);
  
      // Handle response data as needed
  
      // Call the navigateBack function only if the API call is successful
      if (res.status === 200) {
        navigateBack();
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle errors
      console.error("Error navigating back:", error);
    }
  };
  
  
  // const navigateBack = () => {
  //   console.log("Navigating back to page:", currentPage);
  //   navigate(`/products?page=${currentPage}`);
  // };

  // ----------------------------------------------NO ACCESS WITHOUT TOKEN---------------------------------------------
  const isEthicalProduct = product.type === "ethical"; // Assuming 'type' specifies whether the product is ethical
  const isGenericProduct = product.type === "generic";
  const dealoftheday = product.deal_of_the_day == "1 ";

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // const goBack = () => {
  //   // Navigate back to the same page with currentPage
  //   navigate(`/products?page=${currentPage}`);
  // };
  // const goBack =() =>{
  //   navigate(-1);
  // }
  // const handleRedirect = () => {
  //   history.push("/products"); // Replace '/some-route' with your desired route
  // };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                {/* <button
                  variant="contained"
                  color="primary"
                  size="large"
                  // onClick={handleRedirect}
                >
                  Go to Some Route
                </button> */}
                {/* <Link to={`/products?page=${currentPage}`}>Product</Link> / View */}
                {/* <Link onClick={goBack}>Back to Products</Link> */}
                <Link to="/dashboard">Dashboard</Link> /{" "}
                {/* <Link to={`/products${page ? `?page=${page}` : ''}`}>Back to Products</Link> */}
                <Link to="/products">Product</Link> / View
                {/* <button type="button" onClick={handleNavigateBack}>Go Back</button> */}

              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Product Image 1</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              <Link to={image1} target="_blank">
                                <MDBCardImage
                                  src={product.image}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{ width: "50px", marginLeft: "25%" }}
                                  fluid
                                />
                              </Link>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Product Image 2</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              <Link to={image2} target="_blank">
                                {" "}
                                <MDBCardImage
                                  src={product?.image2}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{ width: "50px", marginLeft: "25%" }}
                                  fluid
                                />
                              </Link>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Product Image 3</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              <Link to={image3} target="_blank">
                                {" "}
                                <MDBCardImage
                                  src={product?.image3}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{ width: "50px", marginLeft: "25%" }}
                                  fluid
                                />
                              </Link>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Product Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted text-capital">
                              <span className="text-capital">
                                {" "}
                                {product.type + " Product"}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Product Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted text-capital">
                              <span className="text-capital">
                                {" "}
                                {product.product_name}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Manufacturer</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted text-capital">
                              <span className="text-capital">
                                {product.division?.name}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Composition</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              <span className="text-capital">
                                {product.composition}
                              </span>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        {isEthicalProduct && (
                          <>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>MRP</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.mrp}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Rate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.rate}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </>
                        )}

                        {isGenericProduct && (
                          <>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>MRP 1-9</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.mrp}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Rate 1-9</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.rate}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Sale Rate 1-9</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.sale_rate}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>MRP 10+</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.mrp_g}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Rate 10+</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.rate_g}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Sale Rate 10+</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.sale_rate_g}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </>
                        )}

                        {isEthicalProduct && (
                          <>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Sale Rate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  ₹ {product.sale_rate}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </>
                        )}

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Minimum Order</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.min_order}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Maxium Order</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.max_order}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Total Stock</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted text-capital">
                              {product.stock == null
                                ? "0"
                                : product.stock?.quantity}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Return</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted text-capital">
                              {product.return}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Expiry Return</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.expiry_return}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Pack Of</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.pack_of}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        {isEthicalProduct && (
                          <>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Scheme</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  {product.scheme}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Scheme Discount</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  {product.scheme_percent}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </>
                        )}
                        {dealoftheday && (
                          <>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Deal Till</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  {/* {product.deal_till} */}
                                  {new Date(product.deal_till).toLocaleString()}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>

                            <hr />
                          </>
                        )}

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Discount</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.discount}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Hsn Code</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.hsn + "-" + product.gst}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Delivery</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.delivery}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Deal Of The Day</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {product.deal_of_the_day == 1 ? "Yes" : "No"}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Created</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {new Date(product.created_at).toLocaleString()}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
