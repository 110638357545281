import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import "./category.scss";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const UpdateCategory = () => {
  const { id } = useParams();
  const [msg, setMsg] = useState("");
  const [category, setCategory] = useState({
    name: "",
    description: "",
    image: "",
  });
  const [nullvalue] = useState({
    name: "",
    description: "",
  });
  const [picture, setPicture] = useState({
    image: "",
  });

  const handleInput = (e) => {
    e.persist();
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  //  ---------------------------------FETCH SINGLE CATEGORY----------------------------------

  useEffect(() => {
    axios.get("/api/single_category/" + id).then((res) => {
      setCategory(res.data.data);
    });
  }, [id]);

  // -------------------------------------UPDATE CATEGORY---------------------------------------

  const updateBrands = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();
    categoryobj.append("name", category.name);
    categoryobj.append("description", category.description);
    categoryobj.append("image", picture.image);
    categoryobj.append("_method", "put");
    axios
      .post("/api/category/" + id, categoryobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        setCategory(nullvalue);
        setPicture({ image: "" });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // ----------------------------------------------------NO ACCESS WITHOUT TOKEN-----------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/category">Category</Link> / View
                </span>
              </div>
              <div className="bg-gre" style={{ padding: "35px" }}>
                <div className="edit-box">
                  <h5>
                    <center className="mb-3 mt-3">
                      <b>Edit Category</b>
                    </center>
                  </h5>
                  {msg && <div className="alert alert-success">{msg}</div>}
                  <form onSubmit={updateBrands} encType="multipart/form-data">
                    <div className="text-box">
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        className="w-40 mx-auto mb-3"
                        name="name"
                        type="text"
                        placeholder="Name"
                        value={category.name}
                        onChange={handleInput}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Description"
                        variant="outlined"
                        className="w-40 mx-auto mb-3"
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={category.description}
                        onChange={handleInput}
                        rows={3}
                        multiline
                      />
                      <div className="text-center mb-3 image-box">
                        <img src={category.image} alt="" className="m-3 w-10" />
                      </div>
                      <input
                        type="file"
                        className="w-23 mx-auto mb-3"
                        name="image"
                        onChange={handleImage}
                      />
                      <button className="btn btn-outline-danger  w-25 mx-auto mb-3">
                        Update Category
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCategory;
