import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";

const SuperSaving = () => {
  const [viewdata, setViewData] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [status, setStatus] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/get-super-savings?type=all")
      .then((res) => {
        setStatus(res.data.is_active);
        setViewData(res.data.data);
      })

      .catch((err) => {});
  }, []);

  const togglehandler = async (e) => {
    e.preventDefault();

    const newStatus = status === 0 ? 1 : 0;

    try {
      const jsonData = new FormData();
      jsonData.append("super_savings", newStatus);

      await axios.post("/api/show-banner", jsonData, {});

      setStatus(newStatus);
    } catch (error) {}
  };

  const deleteHandler = (row) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Super Saving?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete-super-savings/" + row.id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });
            let newArr = viewdata.filter((items) => {
              return row.id !== items.id;
            });
            setViewData(newArr);
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "ID",
      selector: (row, index) => <div>{index + 1}</div>,
      width: "70px",
    },

    {
      name: "Title",
      selector: (row) => <span className="text-capital">{row.title}</span>,
      width: "250px",
    },

    {
      name: "Product Name",
      selector: (row) => {
        const productName = row.product_name
          ? row.product_name
          : "No Product Available";

        if (productName.length > 30) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 30 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "250px",
    },

    {
      name: "Images",
      selector: (row) => (
        <span>
          <img
            width={50}
            height={50}
            className="m-1"
            src={row.image}
            alt="MDN logo"
          />
        </span>
      ),
      width: "180px",
    },

    {
      name: "Status",
      selector: (row) => <span>{row.is_show ? "Active" : "Inactive"}</span>,
      width: "150px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          {" "}
          <Link
            to={`/edit-super-saving/${row.id}`}
            style={{ width: "50px" }}
            className="btn btn-primary m-1"
          >
            <EditIcon />
          </Link>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(viewdata);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const filteredItems = viewdata.filter(
    (item) =>
      (item.product_name &&
        item.product_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded  ml-3">
                <CCardHeader>
                  <strong>Super Saving</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-3  ">
                      <div className="form-check form-switch d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={status}
                          onChange={togglehandler}
                        />
                        <label
                          style={{ fontSize: 14 }}
                          className="form-check-label fw-light "
                          for="flexSwitchCheckChecked"
                        >
                          Show Banner
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <TextField
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/add-super-saving"
                            className="btn btn-primary "
                            style={{ marginRight: "50px" }}
                          >
                            + Add Super Saving
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      subHeaderAlign="right"
                      highlightOnHover
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperSaving;
