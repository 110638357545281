import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CCard,
  CCardBody,
  CCol,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CForm,
  CFormSelect,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CCardTitle,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import CustomeLoader from "../../components/CustomeLoader";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
const PurchaseOrder = () => {
  const navigate = useNavigate();

  const [expiryDates, setExpiryDates] = useState([]);
  const today = new Date();

  const [suppliername, setsuppliername] = useState("");
  const [billnumber, setBillnumber] = useState("");
  const [date, setDate] = useState(null);
  const [purchaseBillNo, setPurchaseBillNo] = useState("");

  const [city, setCity] = useState("");
  const [remark, setRemark] = useState("");

  const [productID, setProductId] = useState([
    {
      product_id: "",

      selectedProductId: "",
      quantity: "",
      add_gst: "",
      mrp: "",
      discount: "",
      additional_discount: "",
      batch_number: "",
      purchase_rate: "",
      final_purchase_rate: "",
      sale_rate: "",
      expiry: "",
      additional_amount: "",
      product_type: "",
      remove_discount: "",
      scheme_discount_name: "",
      remaining_quantity: "",
    },
  ]);

  const [productlist, setproductlist] = useState([]);

  const [visibleSaleProduct, setVisibleSaleProduct] = useState(false);
  const [saleProductDetails, setSaleProductDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [visible, setVisible] = useState(false);

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(true);

  const [maxOrder, setMaxOrder] = useState("");
  const [purchaseQuantity, setPurchaseQuantity] = useState("");

  const [batchList, setBatchList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [selectedBatchName, setSelectedBatchName] = useState([]);

  const [purchaseRates, setPurchaseRates] = useState([]);

  const [items, setItems] = useState([]);

  const inputRefs = useRef([]);
  const [selectedProducts, setSelectedProducts] = useState([""]);

  const generatePurchaseBillNo = () => {
    const randomNum = Math.floor(100 + Math.random() * 900);
    return `db${randomNum}`;
  };

  useEffect(() => {
    setPurchaseBillNo(generatePurchaseBillNo());
  }, []);

  const handleInputSearch = (event, index) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = [...prevSelectedProducts];
      updatedSelectedProducts[index] = event.target.value;
      return updatedSelectedProducts;
    });
  };

  const handleClear = (index) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = [...prevSelectedProducts];
      updatedSelectedProducts[index] = "";
      return updatedSelectedProducts;
    });
    inputRefs.current[index].focus();
  };

  const handleArrowClick = (index) => {
    inputRefs.current[index].focus();
  };

  const handleBatchSelect = (value, name, i) => {
    const updatedSelectedBatch = [...selectedBatch];
    updatedSelectedBatch[i] = value;
    setSelectedBatch(updatedSelectedBatch);

    const updatedSelectedBatchName = [...selectedBatchName];
    updatedSelectedBatchName[i] = name;
    setSelectedBatchName(updatedSelectedBatchName);

    handleInput(
      {
        target: {
          name: "batch_number",
          value: value,
        },
      },
      i
    );
  };

  const inputRef1 = useRef(null);
  const handleArrowClick1 = () => {
    inputRef1.current.focus();
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/products")
      .then((res) => {
        setproductlist(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const fetchBatchList = (selectedProductId) => {
    if (selectedProductId) {
      axios
        .get(`/api/purchase_product_batch/${selectedProductId}`)
        .then((res) => {
          setBatchList(res.data.data);
        })
        .catch((err) => {
          setBatchList([]);
        });
    }
  };

  useEffect(() => {
    if (selectedProductId) {
      fetchBatchList(selectedProductId);
    }
  }, [selectedProductId]);
  const totalPurchaseAmount = productID
    .reduce((total, product) => {
      return (
        total + parseFloat(product.purchase_rate) * parseFloat(product.qty)
      );
    }, 0)
    .toFixed(2);
  const [suppliernamelist, setSuppliernamelist] = useState([]);

  useEffect(() => {
    axios.get("/api/get_supplier").then((res) => {
      setSuppliernamelist(res.data.data);
    });
  }, []);

  const calculateTotal = (purchaseRate, qty, addAmount) => {
    const rate = parseFloat(purchaseRate);
    const quantity = parseFloat(qty);
    const additionalAmount = parseFloat(addAmount) || 0;

    if (isNaN(rate) || isNaN(quantity) || isNaN(additionalAmount)) {
      return 0;
    }

    const total = rate * quantity + additionalAmount;

    return total;
  };

  const handleInput = (e, i) => {
    const { name, value } = e.target;

    setProductId((prevProductID) => {
      const updatedProductID = [...prevProductID];
      updatedProductID[i] = {
        ...updatedProductID[i],
        [name]: value,
      };

      if (
        name === "discount" ||
        name === "additional_discount" ||
        name === "add_gst" ||
        name === "purchase_rate" ||
        name === "remove_discount"
      ) {
        const discount = parseFloat(updatedProductID[i].discount) || 0;
        const additional_discount =
          parseFloat(updatedProductID[i].additional_discount) || 0;
        const remove_discount =
          parseFloat(updatedProductID[i].remove_discount) || 0;
        const addGst = parseFloat(updatedProductID[i].add_gst) || 0;
        const purchaseRate = parseFloat(updatedProductID[i].purchase_rate) || 0;

        let newPurchaseRate = purchaseRate - (purchaseRate * discount) / 100;
        newPurchaseRate -= (newPurchaseRate * additional_discount) / 100;
        newPurchaseRate += (newPurchaseRate * remove_discount) / 100;
        newPurchaseRate *= 1 + addGst / 100;

        updatedProductID[i] = {
          ...updatedProductID[i],
          final_purchase_rate: newPurchaseRate.toFixed(2),
        };

        const newPurchaseRates = [...purchaseRates];
        newPurchaseRates[i] = newPurchaseRate.toFixed(2);
        setPurchaseRates(newPurchaseRates);
      }

      return updatedProductID;
    });
  };

  const handleProductSelect = (selectedProductName, index) => {
    const selectedProduct = productlist.find(
      (product) => product.product_name === selectedProductName
    );
    if (selectedProduct) {
      const { id } = selectedProduct;
      setProductId((prevProductID) => {
        const updatedProductID = [...prevProductID];
        updatedProductID[index] = {
          ...updatedProductID[index],
          selectedProductId: id,
        };
        setProductDetails([]);
        setSaleProductDetails([]);
        fetchData(id, index);
        return updatedProductID;
      });
      setSelectedProducts((prevSelectedProducts) => {
        const updatedSelectedProducts = [...prevSelectedProducts];
        updatedSelectedProducts[index] = selectedProductName;
        return updatedSelectedProducts;
      });
      inputRefs.current[index].focus();
    }
  };

  const fetchData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_purchase_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {};
      const productData = responseData.data || [];

      setMaxOrder(res.data.product_data.max_order);
      setPurchaseQuantity(res.data.product_data.total_purchase_quantity);

      setProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }

      if (
        res.data.last_batch_data &&
        Object.keys(res.data.last_batch_data).length > 0
      ) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            additional_amount: res.data.last_batch_data.additional_amount,
            additional_discount: res.data.last_batch_data.additional_discount,
            back_stock: res.data.last_batch_data.back_stock,
            batch_number: res.data.last_batch_data.batch_number,
            discount: res.data.last_batch_data.discount,
            expiry: res.data.last_batch_data.expiry,
            final_purchase_rate: res.data.last_batch_data.final_purchase_rate,
            id: res.data.last_batch_data.id,
            mrp: res.data.last_batch_data.mrp,
            product: res.data.last_batch_data.product,
            purchase_order: res.data.last_batch_data.purchase_order,
            purchase_rate: res.data.last_batch_data.purchase_rate,
            quantity: res.data.last_batch_data.quantity,
            remaining_quantity: res.data.last_batch_data.remaining_quantity,
            remove_discount: res.data.last_batch_data.remove_discount,
            sale_rate: res.data.last_batch_data.sale_rate,
            scheme_discount_name: res.data.last_batch_data.scheme_discount_name,
          };
          return updatedProducts;
        });
      } else {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],
            additional_amount: "",
            additional_discount: "",
            back_stock: "",
            batch_number: "",
            discount: "",
            expiry: "",
            final_purchase_rate: "",
            id: "",
            mrp: "",
            product: "",
            purchase_order: "",
            purchase_rate: "",
            quantity: "",
            remaining_quantity: "",
            remove_discount: "",
            sale_rate: "",
            scheme_discount_name: "",
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };

  const fetchSaleProductData = async (selectedProductId, index) => {
    try {
      const res = await axios.get(
        `/api/previous_sale_product/${selectedProductId}?page=${page}&perPage=${perPage}`
      );

      const responseData = res.data.data || {};
      const productData = responseData.data || [];

      setSaleProductDetails(productData);
      setPerPage(responseData.per_page);
      setTotalRows(responseData.total);

      if (res.data.product_data) {
        setProductId((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {
            ...updatedProducts[index],

            add_gst: res.data.product_data.gst,
            product_type: res.data.product_data.product_type,
          };
          return updatedProducts;
        });
      }
    } catch (error) {
      setProductDetails([]);
    }
  };
  const handleRow = (e, productId) => {
    for (const product of productID) {
      if (!product.selectedProductId) {
        Toast.fire({
          icon: "error",
          title: `Invalid product ID for product: ${product.selectedProductName}`,
        });
        return;
      }
    }
    setSelectedProductId("");
    setBatchList([]);

    setProductId((prevProductID) => {
      const newProductId = [
        ...prevProductID,
        {
          product_id: productId,

          selectedProductId: "",
          quantity: "",
          add_gst: "",
          mrp: "",
          discount: "",
          additional_discount: "",
          batch_number: "",
          purchase_rate: "",
          final_purchase_rate: "",
          sale_rate: "",
          expiry: "",
          additional_amount: "",
          product_type: "",
          remove_discount: "",
          scheme_discount_name: "",
          remaining_quantity: "",
        },
      ];

      setExpiryDates([...expiryDates, null]);
      setPurchaseRates([...purchaseRates, ""]);

      return newProductId;
    });
    setSelectedProducts([...selectedProducts, ""]);
    e.preventDefault();
  };

  const totalamount = productID.reduce((acc, curr) => {
    return (
      acc +
      calculateTotal(
        curr.final_purchase_rate,
        curr.quantity,
        curr.additional_amount
      )
    );
  }, 0);

  const handleRemove = (indexToRemove) => {
    setProductId((prevProductID) => {
      if (Array.isArray(prevProductID)) {
        const newForm = [...prevProductID];
        newForm.splice(indexToRemove, 1);
        return newForm;
      }
      return prevProductID;
    });

    setExpiryDates((prevExpiryDates) => {
      if (Array.isArray(prevExpiryDates)) {
        const newExpiryDates = [...prevExpiryDates];
        newExpiryDates.splice(indexToRemove, 1);
        return newExpiryDates;
      }
      return prevExpiryDates;
    });

    setPurchaseRates((prevPurchaseRates) => {
      if (Array.isArray(prevPurchaseRates)) {
        const newPurchaseRates = [...prevPurchaseRates];
        newPurchaseRates.splice(indexToRemove, 1);
        return newPurchaseRates;
      }
      return prevPurchaseRates;
    });

    setSelectedProducts((prevSelectedProducts) => {
      if (Array.isArray(prevSelectedProducts)) {
        const updatedSelectedProducts = [...prevSelectedProducts];
        updatedSelectedProducts.splice(indexToRemove, 1);
        return updatedSelectedProducts;
      }
      return prevSelectedProducts;
    });

    setProductDetails((prevProductDetails) => {
      if (Array.isArray(prevProductDetails)) {
        const updatedProductDetails = [...prevProductDetails];
        updatedProductDetails.splice(indexToRemove, 1);
        return updatedProductDetails;
      }
      return prevProductDetails;
    });
  };

  var i = 0;
  const submitForm = async (e) => {
    e.preventDefault();

    if (
      !suppliername ||
      !billnumber ||
      !purchaseBillNo ||
      !city ||
      !date ||
      !remark
    ) {
      Toast.fire({
        icon: "error",
        title: "Please fill in all required fields.",
      });
      return;
    }

    setPurchaseBillNo(generatePurchaseBillNo());

    const batchNumbersMap = new Map();

    for (const product of productID) {
      if (!product.selectedProductId) {
        Toast.fire({
          icon: "error",
          title: `Invalid product ID for product: ${product.selectedProductName}`,
        });
        return;
      }

      const compositeKey = `${product.selectedProductName}_${product.selectedProductId}`;

      let batchNumbersForProduct = batchNumbersMap.get(compositeKey);

      if (!batchNumbersForProduct) {
        batchNumbersForProduct = new Set();
        batchNumbersMap.set(compositeKey, batchNumbersForProduct);
      }

      if (batchNumbersForProduct.has(product.batch_number)) {
        Toast.fire({
          icon: "error",
          title: `Duplicate batch number detected for product (ID: ${product.selectedProductId}): ${product.batch_number}`,
        });
        return;
      }

      batchNumbersForProduct.add(product.batch_number);
      if (!product.gst) {
        product.gst = "0";
      }
    }

    const formData = new FormData();
    formData.append("supplier_id", suppliername);
    formData.append("bill_number", billnumber);
    formData.append("purchase_bill_no", purchaseBillNo);
    formData.append("city", city);
    formData.append("date", date);
    formData.append("remarks", remark);

    productID.forEach((product, index) => {
      if (!product.selectedProductId) {
        Toast.fire({
          icon: "error",
          title: `Invalid product ID for product at index ${index}.`,
        });
        return;
      }

      formData.append(`product_id[${index}]`, product.selectedProductId);
      formData.append(`qty[${index}]`, product.quantity);
      formData.append(`add_gst[${index}]`, product.add_gst);

      formData.append(`mrp[${index}]`, product.mrp);
      formData.append(
        `discount[${index}]`,
        product.discount ? product.discount : 0
      );
      formData.append(
        `additional_discount[${index}]`,
        product.additional_discount ? product.additional_discount : 0
      );
      formData.append(`batch_number[${index}]`, product.batch_number);
      formData.append(`purchase_rate[${index}]`, product.purchase_rate);
      formData.append(
        `final_purchase_rate[${index}]`,
        product.final_purchase_rate
      );
      formData.append(`sale_rate[${index}]`, product.sale_rate);
      formData.append(`expiry[${index}]`, product.expiry);
      formData.append("grand_total", totalamount);
      formData.append(
        `additional_amount[${index}]`,
        product.additional_amount ? product.additional_amount : 0
      );
      formData.append(`product_type[${index}]`, product.product_type);
      formData.append(
        `remove_discount[${index}]`,
        product.remove_discount ? product.remove_discount : 0
      );
      formData.append(
        `scheme_discount_name[${index}]`,
        product.scheme_discount_name ? product.scheme_discount_name : ""
      );
    });

    axios
      .post("/api/create_batch", formData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/purchase-order");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "product_name",
      selector: (row) => row.product_name,
      sortable: true,
      width: "180px",
    },

    {
      name: "batch Number",
      selector: (row) => row.batch_number,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Rate",
      selector: (row) => row.purchase_rate,
      sortable: true,
      width: "180px",
    },

    {
      name: "Purchase Order",
      selector: (row) => row.purchase_order,
      sortable: true,
      width: "180px",
    },

    {
      name: "Sale Rate",
      selector: (row) => row.sale_rate,
      sortable: true,
      width: "180px",
    },
    
    {
      name: "Bill Number",
      selector: (row) => row.bill_number,
      sortable: true,
      width: "180px",
    },
    
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
      width: "220px",
    },
    
    {
      name: "Purchase Date",
      selector: (row) => row.purchase_date,
      sortable: true,
      width: "180px",
    },
  ];

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  const formatDate1 = (date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/purchase-order">Purchase Order</Link> / Add
                </span>
              </div>

              <div className="bg-gre " style={{ padding: "37px" }}>
                <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardHeader>
                      <strong> Purchase Product</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CForm className="row g-3">
                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Supplier Name
                          </CFormLabel>
                          <CFormSelect
                            required
                            onChange={(e) => setsuppliername(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option value="">Select Supplier</option>

                            {suppliernamelist?.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.head_name + " - " + item.city}
                              </option>
                            ))}
                          </CFormSelect>
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Supplier Bill No
                          </CFormLabel>
                          <CFormInput
                            value={billnumber}
                            required
                            onChange={(e) => setBillnumber(e.target.value)}
                            id="inputAddress"
                            placeholder="Supplier Bill No"
                          />
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="purchaseBillNo">
                            Purchase Bill No
                          </CFormLabel>
                          <CFormInput
                            value={purchaseBillNo}
                            required
                            readOnly
                            id="purchaseBillNo"
                            placeholder="Purchase Bill No"
                          />
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            City
                          </CFormLabel>

                          <CFormInput
                            value={city}
                            required
                            onChange={(e) => setCity(e.target.value)}
                            id="inputAddress"
                            placeholder="City"
                          />
                        </CCol>

                        <CCol md={4} className="mb-2 ">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Date
                          </CFormLabel>

                          <CCol></CCol>
                          <DatePicker
                            selected={date}
                            required
                            onChange={(selectedDate) => {
                              if (selectedDate) {
                                const formattedDate = formatDate1(selectedDate);
                                setDate(formattedDate);
                              } else {
                                setDate("");
                              }
                            }}
                            dateFormat="dd-MM-yyyy"
                            timeInputLabel="Time:"
                            showDateInput
                            showIcon
                          />
                        </CCol>

                        <CCol md={4} className="mb-2">
                          <CFormLabel htmlFor="exampleFormControlTextarea1">
                            Remark
                          </CFormLabel>
                          <CFormInput
                            value={remark}
                            required
                            onChange={(e) => setRemark(e.target.value)}
                            id="inputAddress"
                            placeholder="Remark"
                          />
                        </CCol>

                        <CCol md={3}></CCol>
                        <CCol md={6}>
                          <hr />
                        </CCol>
                        <CCol md={3}></CCol>

                        {productID.map((items, i) => {
                          return (
                            <CCard key={i}>
                              <div className="row g-3">
                                <div className="mt-2 text-right">
                                  {productID[i].selectedProductId && (
                                    <button
                                      className="btn btn-primary mx-3 mt-3"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const selectedProductId =
                                          productID[i].selectedProductId;

                                        if (selectedProductId) {
                                          setSelectedProductId(
                                            selectedProductId
                                          );
                                          setVisible(!visible);
                                          fetchData(selectedProductId);
                                        } else {
                                        }
                                      }}
                                    >
                                      Previous Purchase Product
                                    </button>
                                  )}

                                  {visible && (
                                    <CModal
                                      backdrop="static"
                                      visible={visible}
                                      onClose={() => setVisible(false)}
                                      aria-labelledby="StaticBackdropExampleLabel"
                                    >
                                      {productDetails.length === 0 ? (
                                        <CCardHeader>
                                          <CCardTitle className="m-3">
                                            Product Not Found
                                          </CCardTitle>
                                        </CCardHeader>
                                      ) : (
                                        <div>
                                          <CModalBody>
                                            <h5>
                                              Previous Purchase Product Details
                                            </h5>
                                          </CModalBody>
                                          {productDetails &&
                                          productDetails.length > 0 ? (
                                            <DataTable
                                              defaultSortFieldId={1}
                                              search
                                              columns={columns}
                                              data={productDetails}
                                              pagination
                                              paginationServer
                                              paginationTotalRows={totalRows}
                                              paginationPerPage={perPage}
                                              progressComponent={
                                                <CustomeLoader />
                                              }
                                              paginationRowsPerPageOptions={[
                                                10, 20, 30, 50, 100,
                                              ]}
                                              onChangePage={(page) =>
                                                setPage(page)
                                              }
                                              onChangeRowsPerPage={(
                                                newPerPage,
                                                page
                                              ) =>
                                                handleRowsPerPageChange(
                                                  newPerPage,
                                                  page
                                                )
                                              }
                                            />
                                          ) : (
                                            <div>No Data Available</div>
                                          )}
                                        </div>
                                      )}

                                      <CModalFooter>
                                        <CButton
                                          color="secondary"
                                          onClick={() => setVisible(false)}
                                        >
                                          Close
                                        </CButton>
                                      </CModalFooter>
                                    </CModal>
                                  )}

                                  {productID[i].selectedProductId && (
                                    <button
                                      className="btn btn-primary mx-3 mt-3"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const selectedProductId =
                                          productID[i].selectedProductId;

                                        if (selectedProductId) {
                                          setSelectedProductId(
                                            selectedProductId
                                          );
                                          setVisibleSaleProduct(
                                            !visibleSaleProduct
                                          );
                                          fetchSaleProductData(
                                            selectedProductId
                                          );
                                        } else {
                                        }
                                      }}
                                    >
                                      Previous Sale Product
                                    </button>
                                  )}
                                  {visibleSaleProduct && (
                                    <CModal
                                      backdrop="static"
                                      visible={visibleSaleProduct}
                                      onClose={() =>
                                        setVisibleSaleProduct(false)
                                      }
                                      aria-labelledby="StaticBackdropExampleLabel"
                                    >
                                      {saleProductDetails.length === 0 ? (
                                        <CCardHeader>
                                          <CCardTitle className="m-3">
                                            Product Not Found
                                          </CCardTitle>
                                        </CCardHeader>
                                      ) : (
                                        <div>
                                          <CModalBody>
                                            <h5>
                                              Previous Sale Product Details
                                            </h5>
                                          </CModalBody>
                                          {saleProductDetails &&
                                          saleProductDetails.length > 0 ? (
                                            <DataTable
                                              defaultSortFieldId={1}
                                              search
                                              columns={columns}
                                              data={saleProductDetails}
                                              pagination
                                              paginationServer
                                              paginationTotalRows={totalRows}
                                              paginationPerPage={perPage}
                                              progressComponent={
                                                <CustomeLoader />
                                              }
                                              paginationRowsPerPageOptions={[
                                                10, 20, 30, 50, 100,
                                              ]}
                                              onChangePage={(page) =>
                                                setPage(page)
                                              }
                                              onChangeRowsPerPage={(
                                                newPerPage,
                                                page
                                              ) =>
                                                handleRowsPerPageChange(
                                                  newPerPage,
                                                  page
                                                )
                                              }
                                            />
                                          ) : (
                                            <div>No Data Available</div>
                                          )}
                                        </div>
                                      )}

                                      <CModalFooter>
                                        <CButton
                                          color="secondary"
                                          onClick={() =>
                                            setVisibleSaleProduct(false)
                                          }
                                        >
                                          Close
                                        </CButton>
                                      </CModalFooter>
                                    </CModal>
                                  )}
                                </div>

                                <CCol md={3}>
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Name
                                  </CFormLabel>
                                  <div>
                                    {loading ? (
                                      <CircularProgress />
                                    ) : (
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <input
                                          list={`productList${i}`}
                                          ref={(el) =>
                                            (inputRefs.current[i] = el)
                                          }
                                          onInput={(e) =>
                                            handleInputSearch(e, i)
                                          }
                                          onChange={(e) =>
                                            handleProductSelect(
                                              e.target.value,
                                              i
                                            )
                                          }
                                          value={selectedProducts[i]}
                                          placeholder="Select Product"
                                          className="product-input"
                                          style={{
                                            width: "236px",
                                            height: "40px",
                                            paddingLeft: "10px",
                                            paddingRight: "40px",
                                          }}
                                        />
                                        {selectedProducts[i] ? (
                                          <ClearIcon
                                            onClick={() => handleClear(i)}
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              right: "5px",
                                              transform: "translateY(-50%)",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          />
                                        ) : (
                                          <ArrowDropDownIcon
                                            onClick={() => handleArrowClick(i)}
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              right: "5px",
                                              transform: "translateY(-50%)",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          />
                                        )}
                                        <datalist id={`productList${i}`}>
                                          {productlist.map((product) => (
                                            <option
                                              key={product.id}
                                              value={product.product_name}
                                            />
                                          ))}
                                        </datalist>
                                      </div>
                                    )}
                                  </div>
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Type
                                  </CFormLabel>

                                  <CFormInput
                                    readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="product_type"
                                    value={items.product_type}
                                    placeholder="Product Type"
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Remaining Quantity
                                  </CFormLabel>

                                  <CFormInput
                                    readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="remaining_quantity"
                                    value={items.remaining_quantity}
                                    placeholder=" Remaining Quantity"
                                  />
                                </CCol>

                                <CCol md={3}>
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Batch List
                                  </CFormLabel>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <input
                                      value={items.batch_number}
                                      name="batch_number"
                                      required
                                      list="batchList"
                                      ref={inputRef1}
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedName = batchList.find(
                                          (batch) =>
                                            batch.batch_number === selectedValue
                                        )?.name;
                                        handleBatchSelect(
                                          selectedValue,
                                          selectedName,
                                          i
                                        );
                                      }}
                                      placeholder="Select Batch"
                                      className="batch-input"
                                      style={{
                                        width: "236px",
                                        height: "40px",
                                        paddingLeft: "10px",
                                        paddingRight: "40px",
                                      }}
                                    />
                                    <ArrowDropDownIcon
                                      onClick={() => handleArrowClick1()}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "10px",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                        color: "#555",
                                      }}
                                    />
                                    <div
                                      style={{
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <datalist id="batchList">
                                        {batchList.map((values, index) => (
                                          <option
                                            key={index}
                                            value={values.batch_number}
                                          />
                                        ))}
                                      </datalist>
                                    </div>
                                  </div>
                                </CCol>

                                <CCol md={3} className="mb-2">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Expiry Date: {items.expiry}
                                  </CFormLabel>
                                  <div>
                                    <DatePicker
                                      selected={expiryDates[i]}
                                      required
                                      minDate={
                                        new Date(
                                          today.getFullYear(),
                                          today.getMonth() + 1
                                        )
                                      }
                                      onChange={(date) => {
                                        const newExpiryDates = [...expiryDates];
                                        newExpiryDates[i] = date || "";
                                        setExpiryDates(newExpiryDates);
                                        handleInput(
                                          {
                                            target: {
                                              name: "expiry",
                                              value: date
                                                ? formatDate(date)
                                                : "",
                                            },
                                          },
                                          i
                                        );
                                      }}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                      autoComplete="off"
                                      showIcon
                                    />

                                    <input
                                      type="text"
                                      className="form-control"
                                      style={{ display: "none", width: "100%" }}
                                      value={
                                        expiryDates[i]
                                          ? formatDate(expiryDates[i])
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        const [month, year] = value.split("/");
                                        const newDate = new Date(
                                          `${year}-${month}-01`
                                        );
                                        if (!isNaN(newDate.getTime())) {
                                          const newExpiryDates = [
                                            ...expiryDates,
                                          ];
                                          newExpiryDates[i] = newDate;
                                          setExpiryDates(newExpiryDates);
                                          handleInput(
                                            {
                                              target: {
                                                name: "expiry",
                                                value: value,
                                              },
                                            },
                                            i
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Product Quantity
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    name="quantity"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 ]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    placeholder="Product Quantity"
                                    value={items.quantity}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    MRP
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="mrp"
                                    value={items.mrp}
                                    placeholder="₹"
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Sale Rate
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.sale_rate}
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="sale_rate"
                                    placeholder="₹"
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Purchase Rate
                                  </CFormLabel>

                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="purchase_rate"
                                    placeholder="₹"
                                    value={items.purchase_rate}
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Scheme Name
                                  </CFormLabel>
                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    id="inputAddress"
                                    name="scheme_discount_name"
                                    placeholder="Scheme Name"
                                    value={items.scheme_discount_name}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Scheme Discount %
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.discount}
                                    type="text"
                                    id="inputAddress"
                                    name="discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      const sanitizedValue =
                                        e.target.value.replace(/[^\d.]/g, "");

                                      const [integerPart, decimalPart] =
                                        sanitizedValue.split(".");

                                      const truncatedInteger =
                                        integerPart.slice(0, 2);

                                      let newValue = truncatedInteger;
                                      if (decimalPart !== undefined) {
                                        newValue += "." + decimalPart;
                                      }

                                      e.target.value = newValue;

                                      handleInput(e, i);
                                    }}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Add Discount (-) %
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.additional_discount}
                                    type="text"
                                    onInput={(e) => {
                                      let inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      const parts = inputValue.split(".");
                                      if (parts.length > 1) {
                                        parts[0] = parts[0].slice(0, 2);
                                        parts[1] = parts[1].slice(0, 2);
                                        inputValue = parts.join(".");
                                      } else {
                                        inputValue = inputValue.slice(0, 2);
                                      }
                                      inputValue = inputValue.slice(0, 5);
                                      e.target.value = inputValue;
                                    }}
                                    id="inputAddress"
                                    name="additional_discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      const re = /^[0-9 .\b]+$/;
                                      handleInput(e, i);
                                      items.remove_discount = "";
                                    }}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Remove Discount (+) %
                                  </CFormLabel>

                                  <CFormInput
                                    value={items.remove_discount}
                                    type="text"
                                    onInput={(e) => {
                                      let inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      const parts = inputValue.split(".");
                                      if (parts.length > 1) {
                                        parts[0] = parts[0].slice(0, 2);
                                        parts[1] = parts[1].slice(0, 2);
                                        inputValue = parts.join(".");
                                      } else {
                                        inputValue = inputValue.slice(0, 2);
                                      }
                                      inputValue = inputValue.slice(0, 5);
                                      e.target.value = inputValue;
                                    }}
                                    id="inputAddress"
                                    name="remove_discount"
                                    placeholder="₹"
                                    onChange={(e) => {
                                      const re = /^[0-9 .\b]+$/;
                                      handleInput(e, i);
                                      items.additional_discount = "";
                                    }}
                                  />
                                </CCol>

                                <CCol md={3} className="">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    GST %
                                  </CFormLabel>

                                  <CFormInput
                                    readOnly
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="add_gst"
                                    value={items.add_gst}
                                    placeholder="₹"
                                  />
                                </CCol>

                                <CCol md={3} className="mb-5">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    New Purchase Rate
                                  </CFormLabel>
                                  <CFormInput
                                    required
                                    type="text"
                                    onChange={(e) => handleInput(e)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="final_purchase_rate"
                                    placeholder="₹"
                                    value={items.final_purchase_rate}
                                  />
                                </CCol>

                                <CCol md={3} className="mb-3">
                                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                                    Additional Amount
                                  </CFormLabel>

                                  <CFormInput
                                    type="text"
                                    onChange={(e) => handleInput(e, i)}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9 .]/g,
                                        ""
                                      );
                                    }}
                                    id="inputAddress"
                                    name="additional_amount"
                                    placeholder="₹"
                                    value={items.additional_amount}
                                  />
                                </CCol>

                                <CCol md={8}></CCol>
                                <CCol md={6} key={i}>
                                  <h5 style={{}}>
                                    Total: ₹{" "}
                                    {parseFloat(
                                      calculateTotal(
                                        items.final_purchase_rate,
                                        items.quantity,
                                        items.additional_amount
                                      )
                                    ).toFixed(2)}
                                  </h5>
                                </CCol>

                                <CCol md={12} className="">
                                  {Array.isArray(productID) &&
                                    productID.length > 1 && (
                                      <button
                                        className="bg-white mt-5"
                                        style={{ border: "none" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleRemove(i);
                                        }}
                                      >
                                        <DeleteIcon className="text-red" />
                                      </button>
                                    )}
                                </CCol>

                                <CCol md={12} className="">
                                  <hr />
                                </CCol>
                              </div>
                            </CCard>
                          );
                        })}
                        <div className="text-center"></div>

                        <CCol md={10}>
                          <h5 style={{ margin: 0 }}>
                            Grand Total: ₹ {parseFloat(totalamount).toFixed(2)}
                          </h5>
                        </CCol>
                        <CCol md={2}>
                          <button
                            className=" btn btn-outline-danger mb-2"
                            onClick={(e) => handleRow(e)}
                          >
                            + Add More
                          </button>
                        </CCol>

                        <CCol md={12} className="text-center">
                          <button
                            type="submit"
                            className="btn btn-danger my-3 "
                            onClick={submitForm}
                          >
                            Submit
                          </button>
                        </CCol>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
