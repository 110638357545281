import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";

const MyOffers = () => {
  const [Offers, setOffers] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    axios
      .get("/api/offers")
      .then((res) => {
        setOffers(res.data.data);
      })
      .then(() => {})

      .catch((err) => {});
  }, []);

  const deleteHandler = (row) => {
    axios
      .delete("/api/category/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = Offers.filter((items) => {
          return row.id !== items.id;
        });
        setOffers(newArr);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Image",
      selector: (row) => (
        <img width={60} className="m-1" src={row.image} alt="MDN logo" />
      ),
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => <span className="text-capital"> {row.name} </span>,
      sortable: true,
      width: "400px",
    },

    {
      name: "Coupan Code",
      selector: (row) => row.coupon_code,
      sortable: true,
      width: "180px",
    },
    {
      name: "Discount",
      selector: (row) => <div>{row.discount}%</div>,
      sortable: true,
      width: "120px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this offer?"))
                deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "100px",
      sortable: false,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(Offers);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const filteredItems = Offers.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.coupon_code &&
        item.coupon_code.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Offer Table</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <TextField
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/offers/add-offer"
                            className="btn btn-primary "
                            style={{ marginRight: "50px" }}
                          >
                            + Add Offer
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeaderScrollHeight="480px"
                      fixedHeader
                      responsive
                      pagination={10}
                      subHeaderAlign="right"
                      paginationResetDefaultPage={resetPaginationToggle}
                      highlightOnHover
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyOffers;
