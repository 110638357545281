import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewBrand = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [create, setCreate] = useState("");
  const [update, setUpdate] = useState("");

  // ---------------------------------------------FETCH SINGLE MANUFACTURER------------------------------------------

  useEffect(() => {
    loadCategory();
  }, []);

  const loadCategory = async () => {
    const result = await axios.get(
      "/api/get_single_brand/" + id
    );
    setName(result.data.data.name);
    // setDescription(result.data.data.description);
    setImage(result.data.data.image);
    setType(result.data.data.type);
    setCreate(result.data.data.created_at);
    setUpdate(result.data.data.updated_at);
  };

// ---------------------------------------------------NO ACCESS WITHOUT TOKEN------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />

            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/manufacturer">Manufacturer</Link> / View
              </span>
            </div>

            <section className="bg-gre" style={{ minHeight: "570px" }}>
              <MDBContainer className="px-5 py-3 ">
                <MDBRow className="justify-content-center">
                  <MDBCol lg="8">
                    <MDBCard className=" mt-3">
                      <MDBCardBody className="text-center">
                        <MDBCardBody>
                          <MDBCardImage
                            src={image}
                            alt="avatar"
                            className=" mb-2"
                            style={{ width: "190px" }}
                            fluid
                          />

                          <hr />
                          <MDBRow>
                            <MDBCol sm="4">
                              <MDBCardText>Name</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="8">
                              <MDBCardText className="text-muted">
                                {name}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          {/* <MDBRow>
                            <MDBCol sm="4">
                              <MDBCardText>Description</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="8">
                              <MDBCardText className="text-muted">
                              {description !== "" ? description : "Description Not Available"}
                              </MDBCardText>
                              
                            </MDBCol>
                          </MDBRow> */}
                         
                          <MDBRow>
                            <MDBCol sm="4">
                              <MDBCardText>Category</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="8">
                              <MDBCardText className="text-muted">
                                {type}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow>
                            <MDBCol sm="4">
                              <MDBCardText>Created</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="8">
                              <MDBCardText className="text-muted">
                              { new Date(create).toLocaleDateString()} 
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />

                          {/* <MDBRow>
                            <MDBCol sm="4">
                              <MDBCardText>Updated</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="8">
                              <MDBCardText className="text-muted">
                              { new Date(update).toLocaleDateString()} 
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow> */}
                        </MDBCardBody>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBrand;
