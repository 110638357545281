import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const BatchNumber = () => {
  const [batch, setbatch] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // ------------------------------------FETCH ALL BATCH NUMBER---------------------------------------

  useEffect(() => {
    axios
      .get("/api/get_batch")
      .then((res) => {
        console.log(res)
        setbatch(res.data.data);
      })

      .catch((err) => {
        console.log(err)
        console.log(err.response.data.message);
      });
  }, []);

  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------DELETE BATCH NUMBER------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_batch/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = batch.filter((items) => {
          return row.id !== items.id;
        });
        setbatch(newArr);
        // window.location.reload(false);
      })
      .catch((error) => {
      console.log(error)
        console.log(error.response.data.message);
      });
  };

  // --------------------------------------------------------------------------------------------------------

  // ------------------------------------------------BATCH NUMBER TABLE COLUMN---------------------------------------
  const columns = [
    // {
    //   name: "View",
    //   selector: (row) => (
    //     <Link to={`/batch-number/view/${row.id}`} className="btn btn-primary m-1">
    //       <VisibilityIcon />
    //     </Link>
    //   ),

    //   width: "90px",
    // },

    {
      name: "#",
      selector: (row,index) => <div>{index+ 1}</div>,
      sortable: true,
      width: "70px",
    },
 
    {
      name: "Batch Number",
      selector: (row) => row.batch_number,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product",
      selector: (row) =><span className="text-capital">{row.product?.product_name}</span>,
      sortable: true,
      width: "190px",
    },
    {
      name: "Qty",
      selector: (row) => row.quantity
      ,
      sortable: true,
      width: "100px",
    },
    {
      name: "Expiry",
      selector: (row) => row.expiry,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Created",
    //   selector: (row) =>   new Date(row.created_at).toLocaleDateString(),
    //   sortable: true,
    //   width: "240px",
    // },

    // {
    //   name: "Updated",
    //   selector: (row) => new Date(row.updated_at).toLocaleDateString(),
    //   sortable: true,
    //   width: "240px",
    // },
    {
      name: "action",
      selector: (row) => (
        <div>
          <Link
            to={`/edit-batch-number/${row.id}`}
            style={{ width: "50px" }}
            className="btn btn-primary m-1"
          >
            <EditIcon />
          </Link>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this batch number?"))
                deleteHandler(row);
            }}
           
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "200px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(batch);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [batch]);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = batch.filter(
    (item) =>
      (item.product?.product_name &&
        item.product?.product_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.batch_number &&
        item.batch_number.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.quantity &&
        item.quantity.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.expiry &&
        item.expiry.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="customerContainer">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 mt-1">
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="btn btn-primary m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <div className="mt-2 text-right ">
                        <Link
                          to="/add-batch-number"
                          className="btn btn-primary mr-5 "
                        >
                          + Add Batch Number
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="m-1">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    fixedHeaderScrollHeight="480px"
                    fixedHeader
                    responsive
                    pagination={10}
                    subHeaderAlign="right"
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<CustomeLoader />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BatchNumber;
