import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const UpdateFaq = () => {
  const { id } = useParams();
  const [msg, setMsg] = useState("");
  const [faqs, setFaqs] = useState({
    question: "",
    answer: "",
  });
  const [nullvalue] = useState({
    question: "",
    answer: "",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleInput = (e) => {
    e.persist();
    setFaqs({ ...faqs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.get("/api/edit_faqs/" + id).then((res) => {
      setFaqs(res.data.data);
    });
  }, []);

  const updateFaqs = async (e) => {
    e.preventDefault();
    const faqobj = new FormData();
    faqobj.append("question", faqs.question);
    faqobj.append("answer", faqs.answer);

    axios
      .post("/api/update_faqs/" + id, faqobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        setFaqs(nullvalue);
        navigate("/FAQ");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/FAQ">FAQ's</Link> / Edit
                </span>
              </div>
              <div
                className="bg-gre"
                style={{ padding: "35px", minHeight: "560px" }}
              >
                <div className="edit-box my-3">
                  <h5>
                    <center className="mb-4 mt-4">
                      <b>Edit FAQ's</b>
                    </center>
                  </h5>
                  {msg && <div className="alert alert-success">{msg}</div>}
                  <form onSubmit={updateFaqs} encType="multipart/form-data">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        id="outlined-basic"
                        label="Question"
                        variant="outlined"
                        style={{
                          marginBottom: "20px",
                        }}
                        className="w-40 mx-auto"
                        name="question"
                        type="text"
                        placeholder="Question"
                        value={faqs.question}
                        onChange={handleInput}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Answer"
                        variant="outlined"
                        style={{
                          marginBottom: "20px",
                        }}
                        className="w-40 mx-auto"
                        type="text"
                        name="answer"
                        placeholder="Answer"
                        value={faqs.answer}
                        onChange={handleInput}
                        rows={3}
                        multiline
                      />

                      <button
                        className="btn btn-outline-danger mx-auto w-25"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        Update FAQ's
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateFaq;
