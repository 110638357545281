
import "./navbar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Navbar = () => {
 
  const navigate = useNavigate();
  
  // const handleLogout = () => {
  //   const confirmed = window.confirm("Are you sure you want to logout?");
  //   if (confirmed) {
  //     localStorage.removeItem('status');
  //     navigate('/');
  //   }
  // };
  const handleLogout = () => {
    Swal.fire({
      title: "Logout",
      text: "Are you sure you want to log out?",
      showCancelButton: true,
      confirmButtonColor: "btn btn-primary",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button", 
        cancelButton: "swal-cancel-button", 
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("status");
        navigate("/");
      }
    });
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="nav-head"><strong >DAWA BAJAR</strong></div>
        <div className="items">
          <div className="item">
            <button
              style={{border:"1px solid #16035e"}}
              className="logout"
              onClick={handleLogout}
            >
              <ExitToAppIcon className="icon" />&nbsp;Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;


// import "./navbar.scss";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";


// import { useNavigate } from "react-router-dom";

// const Navbar = () => {
 
//   const navigate = useNavigate()
  

//   return (
//     <div className="navbar">
//       <div className="wrapper">
        
//         <div className="nav-head"><strong >DAWA BAJAR</strong></div>
        
//         <div className="items">
       
       
         
//              <div className="item">
//             <button
//             style={{border:"1px solid #16035e"}}
//               className="logout"
//             //  onClick={ ()=>{
//             //   localStorage.removeItem('status')
//             //   alert("Are you sure to logout !")
//             //   navigate('/')
//             //  }}

//              onClick={ ()=>{
//               localStorage.removeItem('status')
//               alert("Are you sure to logout !")
//               navigate('/')
//              }}
//             > <ExitToAppIcon className="icon" />&nbsp;Logout</button>
        
//           </div>
        
       
       
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
