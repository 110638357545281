import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const EditHsn = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [hsn, setHsn] = useState("");
  const [percentage, setpercentage] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/single_hsn/" + id)
      .then((res) => {
        setHsn(res.data.data.hsc_code);
        setpercentage(res.data.data.gst_percent);
      })
      .catch((err) => {});
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();

    categoryobj.append("hsc_code", hsn);
    categoryobj.append("gst_percent", percentage);
    axios
      .post("/api/update_hsn/" + id, categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/hsn");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/hsn">HSN</Link> / Edit
                </span>
              </div>

              <div className="bg-gre p-60" style={{ minHeight: "570px" }}>
                <div className="main-box">
                  <h5>
                    <center className="mb-4 mt-3">
                      <b>Edit HSN</b>
                    </center>
                  </h5>
                  <div className="text-box">
                    <TextField
                      id="outlined-basic"
                      label="HSN"
                      variant="outlined"
                      className="input-field w-40 mb-3"
                      name="hsc_code"
                      type="text"
                      placeholder="HSN"
                      value={hsn}
                      onChange={(e) => setHsn(e.target.value)}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="GST %"
                      variant="outlined"
                      className="input-field w-40 mb-3"
                      name="gst_percent"
                      type="text"
                      placeholder="%"
                      value={percentage}
                      onChange={(e) => {
                        const re = /^[0-9 \b]+$/;
                        if (
                          e.target.value === "" ||
                          (re.test(e.target.value) &&
                            parseFloat(e.target.value) <= 99)
                        ) {
                          setpercentage(e.target.value);
                        }
                      }}
                    />

                    <button
                      onClick={submitForm}
                      className="btn btn-danger mb-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHsn;
