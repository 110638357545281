import React, { useState, useEffect } from "react";
import axios from "axios";
import { CCard, CRow, CCardBody, CCol, CCardHeader } from "@coreui/react";
import { useParams } from "react-router-dom";

const StockBatchDetails = () => {
  const { id } = useParams();
  const token =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;

  const [stock, setStock] = useState([]);

  useEffect(() => {
    const helpdetailsobj = new FormData();
    helpdetailsobj.append("batch_number", id);

    axios
      .post("/api/batch_stock_statement", helpdetailsobj, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setStock(res.data.data);
      })
      .catch((error) => {});
  }, [id, token]);

  return (
    <>
      <div className="px-2">
        <CRow>
          <CCol md={2}></CCol>
          <CCol md={8}>
            <CCard className="mb-4 shadow-lg p-3 mb-5 bg-body rounded">
              <CCardHeader>
                <strong>batch_stock_statement</strong>
              </CCardHeader>
              <CCardBody>
                <CCol md={12} className="d-flex my-2">
                  <CCol sm={3}>
                    <span style={{ marginRight: "15px" }}>
                      <strong>Name :</strong>
                    </span>{" "}
                  </CCol>{" "}
                  <CCol sm={7}>{stock?.product_name}</CCol>
                </CCol>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md={2}></CCol>
        </CRow>
      </div>
    </>
  );
};

export default StockBatchDetails;
