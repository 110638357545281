import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./product.scss";
import axios from "axios";
import Swal from "sweetalert2";
const EditProduct = () => {
  // const { id } = useParams();
  // const navigate = useNavigate();
  const [productimg, setProductimg] = useState("");
  const [productimg1, setProductimg1] = useState("");
  const [productimg2, setProductimg2] = useState("");
  const [manufacturer, setmanufacturer] = useState("");
  const [packof, setPackof] = useState("");
  const [discount, setDiscount] = useState("");
  const [gst, setGst] = useState("");
  const [scheme, setScheme] = useState("");
  const [schemeDiscount, setSchemeDiscount] = useState("");
  const [maxorder, setmaxorder] = useState("");
  const [minorder, setminorder] = useState("");
  const [rate, setrate] = useState("");
  const [salerate, setsalerate] = useState("");
  const [salerateg, setsalerateg] = useState("");

  const [mrp, setmrp] = useState("");
  const [productname, setproductname] = useState("");
  const [delivery, setDelivery] = useState("");
  const [returnp, setreturnp] = useState("");
  const [expiryReturn, setExpiryReturn] = useState("");
  const [dealOfTheDay, setDealOfTheDay] = useState("");
  const [dealTill, setDealTill] = useState("");
  const [hsn, setHsn] = useState("");
  const [composition, setcomposition] = useState("");
  const [manufacturerlist, setmanufacturerlist] = useState([]);
  const [hsnlist, setHsnlist] = useState([]);
  const [msg, setMsg] = useState("");
  const [errormsg, seterrorMsg] = useState("");
  const [image, setimage] = useState("");
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [singleproduct, setsingleproduct] = useState("");

  const [mrp1, setmrp1] = useState("");
  const [rate1, setrate1] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [discount1, setDiscount1] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  // const [productname, setproductname] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // ------------------------------------------FORMULA FOR COUNT SALE RATE--------------------------------------

  // // Function to check if a date is in the future
  // const isFutureDateTime = (dateTime) => {
  //   const currentDate = new Date();
  //   const selectedDateTime = new Date(dateTime);
  //   return selectedDateTime >= currentDate;
  // };

  const isFutureDateTime = (selectedDateTime) => {
    const selectedDate = new Date(selectedDateTime);
    const now = new Date();
    const difference = selectedDate.getTime() - now.getTime();
    const hoursDifference = difference / (1000 * 3600);
    return hoursDifference >= 24; // Check if the difference is at least 24 hours
  };

  const handleDealTillChange = (e) => {
    const selectedDateTime = e.target.value.replace("T", " "); // Remove the 'T' character
    if (isFutureDateTime(selectedDateTime)) {
      setDealTill(selectedDateTime);
    } else {
      // If the selected date-time is in the past or less than 24 hours in the future, clear the input
      setDealTill("");
      setErrorMessage(
        "Please select a date and time at least 24 hours in the future."
      );
      // Optionally, you can show an error message here to inform the user
    }
  };

  let newsalerate = (
    (rate * (100 - Number(discount)) * (100 - Number(schemeDiscount))) /
    10000
  ).toFixed(2);
  newsalerate = Math.max(0, parseFloat(newsalerate)); // Ensure sale rate is not negative

  const newsalerate1 =
    discount === "0"
      ? parseFloat(rate1.toFixed(2))
      : parseFloat(((rate1 * (100 - Number(discount))) / 100).toFixed(2));

  // ------------------------------------------FETCH SINGLE PRODUCT--------------------------------------

  useEffect(() => {
    axios.get("/api/get_single_product/" + id).then((res) => {
      setsingleproduct(res.data.data);
      setcomposition(res.data.data?.composition);
      setHsn(res.data.data?.hsn + "-" + res.data.data?.gst);
      setproductname(res.data.data?.product_name);
      setreturnp(res.data.data?.return);
      setsalerate(res.data.data?.sale_rate);
      setsalerateg(res.data.data?.sale_rate_g);
      setPackof(res.data.data?.pack_of);
      setrate(res.data.data?.rate);
      setScheme(res.data.data?.scheme);
      setSchemeDiscount(res.data.data?.scheme_percent);
      setmrp(res.data.data?.mrp);
      setminorder(res.data.data?.min_order);
      setmaxorder(res.data.data?.max_order);
      setExpiryReturn(res.data.data?.expiry_return);
      setmanufacturer(res.data.data?.division?.id);
      setDiscount(res.data.data?.discount);
      setDelivery(res.data.data?.delivery);
      setDealTill(res.data.data?.deal_till);
      setDealOfTheDay(res.data.data?.deal_of_the_day);
      setimage(res.data.data?.image);
      setimage1(res.data.data?.image2);
      setimage2(res.data.data?.image3);
      setGst(res.data.data?.gst);
      setmrp1(res.data.data?.mrp_g);
      setrate1(res.data.data?.rate_g);
      setSelectedType(res.data.data?.type);
      // setDiscount1(res.data.data?.discount_g);
    });
  }, [id]);

  // ------------------------------------------------EDIT SINGLE PRODUCT---------------------------------

  const submitForm = async (e) => {
    // Validation for Quantity 1-9 fields

    if (selectedType === "generic" && (mrp === "" || rate === "")) {
      Toast.fire({
        icon: "error",
        title: "Please fill in the MRP and Rate for Quantity 1-9.",
      });
      return;
    }

    // Additional validation: If Deal of the day is selected as "Yes", ensure dealTill is provided
    if (dealOfTheDay === "1" && !dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please select the Deal Till date.",
      });
      return;
    }

    // Additional validation: If Deal of the day is selected as "Yes", ensure dealTill is at least 24 hours away
    if (dealOfTheDay === "1" && !isFutureDateTime(dealTill)) {
      Toast.fire({
        icon: "error",
        title: "Please select a Deal Till date at least 24 hours from now.",
      });
      return;
    }

    // If Deal of the day is selected as "No", ensure dealTill is not provided
    if (dealOfTheDay === "0" && dealTill) {
      Toast.fire({
        icon: "error",
        title: "Please clear the Deal Till date.",
      });
      return;
    }

    // Basic validation checks
    if (
      !productname ||
      !manufacturer ||
      !hsn ||
      !returnp ||
      !packof ||
      !composition ||
      !minorder ||
      !maxorder ||
      !delivery ||
      !expiryReturn
    ) {
      Toast.fire({
        icon: "error",
        title: "Please fill in all the fields.",
      });
      return;
    }

    e.preventDefault();
    const productobj = new FormData();
    productobj.append("product_name", productname);
    productobj.append("min_order", minorder);
    productobj.append("max_order", maxorder);
    productobj.append("division", manufacturer);
    productobj.append("return", returnp);
    productobj.append("pack_of", packof);
    productobj.append("rate", rate);
    productobj.append("rate_g", rate1);
    productobj.append("discount", discount);
    // productobj.append("discount_g", discount1);
    productobj.append("sale_rate", newsalerate);
    productobj.append("sale_rate_g", newsalerate1);
    productobj.append("scheme", scheme);
    productobj.append("scheme_percent", schemeDiscount);
    productobj.append("mrp", mrp);
    productobj.append("mrp_g", mrp1);
    productobj.append("expiry_return", expiryReturn);
    productobj.append("composition", composition);
    productobj.append("delivery", delivery);
    productobj.append("deal_of_the_day", dealOfTheDay);
    productobj.append("deal_till", dealTill ? dealTill : "");
    productobj.append("hsn", hsn);
    productobj.append("image", productimg);
    productobj.append("image2", productimg1);
    productobj.append("image3", productimg2);

    try {
      const result = await axios.post("/api/update_product/" + id, productobj, {
        headers: {
          Accept: "application/json",
        },
      });
      Toast.fire({
        icon: "success",
        title: result.data.message,
      });
      navigate("/products");
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
    }
  };

  // -----------------------------------------------------FETCH ALL MANUFACTURER---------------------------------

  useEffect(() => {
    axios
      .get("/api/brands")
      .then((res) => {
        setmanufacturerlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  // ------------------------------------------------FETCH ALL HSN---------------------------------

  useEffect(() => {
    axios
      .get("/api/get_hsn")
      .then((res) => {
        setHsnlist(res.data.data);
      })
      .catch((err) => {});
  }, []);

  // ------------------------------------------------NO ACCESS WITHOUT TOKEN---------------------------------
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // const goBack = () => {
  //   navigate(-1); // This will navigate back to the previous page
  // };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/products">Product</Link> / Edit
                </span>
              </div>
              {/* <button
            onClick={goBack}
            style={{
              backgroundColor: "#863895",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              padding: "8px 16px",
              cursor: "pointer",
              textDecoration: "none",
              display: "inline-block",
              transition: "background-color 0.3s ease",
              marginRight: "8px",
            }}
            color="dark"
          >
            Back
          </button> */}
              <section className="bg-gre px-5 py-5">
                <div className="row bg-white pl-4">
                  <h5>
                    <center className="mb-4 mt-3">Edit Product</center>
                  </h5>

                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Product Name"
                        variant="outlined"
                        className="w-100"
                        name="product_name"
                        type="text"
                        placeholder="Product Name"
                        value={productname}
                        onChange={(e) => setproductname(e.target.value)}
                        // onChange={(e) => {
                        //   const re = /^[a-zA-Z0-9 . *\b]+$/; // Regular expression to match alphanumeric characters and space
                        //   if (
                        //     e.target.value === "" ||
                        //     re.test(e.target.value)
                        //   ) {
                        //     setproductname(e.target.value);
                        //   }
                        // }}
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-lg-4">
                      <select
                        required
                        className="mb-4 w-100 h-5"
                        onChange={(e) => setmanufacturer(e.target.value)}
                        name="division"
                      >
                        <option value="">-Manufacturer-</option>
                        {manufacturerlist.map((items, index) => (
                          <option
                            selected={manufacturer == items.id}
                            key={index}
                            value={items.id}
                          >
                            &nbsp;&nbsp;&nbsp;{items.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <select
                        required
                        className="mb-4 w-100 h-5"
                        onChange={(e) => setHsn(e.target.value)}
                        name="hsn"
                      >
                        <option value="">-HSN Code-</option>
                        {hsnlist.map((categories, index) => (
                          <option
                            selected={
                              hsn ==
                              categories.hsc_code + "-" + categories.gst_percent
                            }
                            key={index}
                            value={
                              categories.hsc_code + "-" + categories.gst_percent
                            }
                          >
                            &nbsp;&nbsp;&nbsp;{categories.hsc_code} -{" "}
                            {categories.gst_percent}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-lg-4">
                      <select
                        required
                        disabled
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        name="return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Type-</option>
                        <option value="ethical" disabled>
                          &nbsp;&nbsp;&nbsp;Ethical Brand
                        </option>
                        <option value="generic" disabled>
                          &nbsp;&nbsp;&nbsp;Generic Brand
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <select
                        required
                        onChange={(e) => setreturnp(e.target.value)}
                        name="return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Return-</option>
                        <option selected={returnp == "7 Days"} value="7 Days">
                          &nbsp;&nbsp;&nbsp;7 Days
                        </option>

                        <option
                          selected={returnp == "Not Returnable"}
                          value="Not Returnable"
                        >
                          &nbsp;&nbsp;&nbsp;Not Returnable
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Pack"
                        variant="outlined"
                        className="w-100"
                        name="pack_of"
                        type="text"
                        placeholder="Pack"
                        value={packof}
                        // onChange={(e) => setPackof(e.target.value)}
                        onChange={(e) => {
                          const re = /^[a-zA-Z0-9 * .\b]+$/; // Regular expression to match alphanumeric characters and space
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setPackof(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Composition"
                        variant="outlined"
                        className="w-100"
                        name="composition"
                        type="text"
                        placeholder="Composition"
                        value={composition}
                        onChange={(e) => setcomposition(e.target.value)}
                        // onKeyDown={(e) => {
                        //   // Prevent typing of non-alphabetical characters
                        //   if (!e.key.match(/[a-zA-Z0-9 . *\b]/)) {
                        //     e.preventDefault();
                        //   }
                        // }}
                      />
                    </div>
                  </div>

                  {selectedType === "ethical" && (
                    <>
                      <div className="row mb-3">
                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp}
                            // onChange={(e) => setmrp(e.target.value)}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate"
                            type="text"
                            placeholder="₹"
                            value={rate}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Scheme"
                            variant="outlined"
                            className="w-100"
                            name="scheme"
                            type="text"
                            placeholder="Scheme"
                            value={scheme}
                            // onChange={(e) => setScheme(e.target.value)}
                            onChange={(e) => {
                              const re = /^[0-9 +\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setScheme(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Scheme Discount"
                            variant="outlined"
                            className="w-100"
                            name="scheme"
                            type="text"
                            placeholder="%"
                            value={schemeDiscount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setSchemeDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-3">
                          <select
                            // required
                            onChange={(e) => setDealOfTheDay(e.target.value)}
                            name="deal_of_the_day"
                            className="mb-3 h-5 w-100"
                          >
                            <option>-Deal Of The Day-</option>
                            <option selected={dealOfTheDay == "1"} value="1">
                              &nbsp;&nbsp;&nbsp;Yes
                            </option>
                            <option selected={dealOfTheDay == "0"} value="0">
                              &nbsp;&nbsp;&nbsp;No
                            </option>
                          </select>
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            // required
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            label="Deal Till"
                            variant="outlined"
                            className="w-100"
                            name="deal_till"
                            type="datetime-local"
                            value={dealTill}
                            // Using onChange to set dealTill only if the selected date-time is in the future
                            onChange={(e) => {
                              const selectedDateTime = e.target.value.replace(
                                "T",
                                " "
                              ); // Remove the 'T' character
                              if (isFutureDateTime(selectedDateTime)) {
                                setDealTill(selectedDateTime);
                              } else {
                                // If the selected date-time is in the past, clear the input
                                setDealTill("");
                              }
                            }}
                            inputProps={{ step: 1 }} // Include seconds in the input
                          />
                        </div>

                        <div className="col-lg-3">
                          <TextField
                            id="outlined-basic"
                            label="Discount"
                            variant="outlined"
                            className="w-100"
                            name="discount"
                            type="text"
                            placeholder="%"
                            value={discount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <TextField
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate}
                            onChange={(e) => setsalerate(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectedType === "generic" && (
                    <>
                      <div className="row mb-3">
                        <label>Quantity 1-9</label>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate"
                            type="text"
                            placeholder="₹"
                            value={rate}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate}
                            onChange={(e) => setsalerate(e.target.value)}
                          />
                        </div>
                        <label>Quantity 10+</label>

                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="MRP"
                            variant="outlined"
                            className="w-100"
                            name="mrp"
                            placeholder="₹"
                            type="text"
                            value={mrp1}
                            // onChange={(e) => setmrp1(e.target.value)}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setmrp1(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            required
                            id="outlined-basic"
                            label="Rate"
                            variant="outlined"
                            className="w-100"
                            name="rate1"
                            type="text"
                            placeholder="₹"
                            value={rate1}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setrate1(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <TextField
                            id="outlined-basic"
                            label="Sale Rate"
                            variant="outlined"
                            className="w-100"
                            name="expiry"
                            type="text"
                            placeholder="₹"
                            value={newsalerate1}
                            onChange={(e) => setsalerateg(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-lg-4">
                          <TextField
                            id="outlined-basic"
                            label="Discount"
                            variant="outlined"
                            className="w-100"
                            name="discount"
                            type="text"
                            placeholder="%"
                            value={discount}
                            onChange={(e) => {
                              const re = /^[0-9 .\b]+$/;
                              if (
                                e.target.value === "" ||
                                (re.test(e.target.value) &&
                                  parseFloat(e.target.value) <= 99)
                              ) {
                                setDiscount(e.target.value);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-4">
                          <select
                            // required
                            onChange={(e) => setDealOfTheDay(e.target.value)}
                            name="deal_of_the_day"
                            className="mb-3 h-5 w-100"
                          >
                            <option>-Deal Of The Day-</option>
                            <option selected={dealOfTheDay == "1"} value="1">
                              &nbsp;&nbsp;&nbsp;Yes
                            </option>
                            <option selected={dealOfTheDay == "0"} value="0">
                              &nbsp;&nbsp;&nbsp;No
                            </option>
                          </select>
                        </div>

                        <div className="col-lg-4">
                          <TextField
                            // required
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            label="Deal Till"
                            variant="outlined"
                            className="w-100"
                            name="deal_till"
                            type="datetime-local"
                            value={dealTill}
                            // Using onChange to set dealTill only if the selected date-time is in the future
                            onChange={(e) => {
                              const selectedDateTime = e.target.value.replace(
                                "T",
                                " "
                              ); // Remove the 'T' character
                              if (isFutureDateTime(selectedDateTime)) {
                                setDealTill(selectedDateTime);
                              } else {
                                // If the selected date-time is in the past, clear the input
                                setDealTill("");
                              }
                            }}
                            inputProps={{ step: 1 }} // Include seconds in the input
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Min Order"
                        variant="outlined"
                        className="w-100"
                        name="min_order"
                        type="text"
                        placeholder="Min Order"
                        value={minorder}
                        onChange={(e) => {
                          const re = /^[0-9 \b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setminorder(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Max Order"
                        variant="outlined"
                        className="w-100"
                        name="max_order"
                        type="text"
                        placeholder="Max Order"
                        value={maxorder}
                        onChange={(e) => {
                          const re = /^[0-9 \b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setmaxorder(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <select
                        required
                        onChange={(e) => setExpiryReturn(e.target.value)}
                        name="expiry_return"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Expiry Return-</option>
                        <option
                          selected={expiryReturn == "Available"}
                          value="Available"
                        >
                          &nbsp;&nbsp;&nbsp;Available
                        </option>
                        <option
                          selected={expiryReturn == "Not Available"}
                          value="Not Available"
                        >
                          &nbsp;&nbsp;&nbsp;Not Available
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <select
                        required
                        onChange={(e) => setDelivery(e.target.value)}
                        name="delivery"
                        className="mb-3 h-5 w-100"
                      >
                        <option value="">-Delivery-</option>
                        {/* <option selected={delivery == "2 Hour"} value="2 Hour">
                          &nbsp;&nbsp;&nbsp;2 Hour
                        </option> */}
                        <option selected={delivery == "3 Hour"} value="3 Hour">
                          &nbsp;&nbsp;&nbsp;3 Hour
                        </option>
                        {/* <option selected={delivery == "5 Hour"} value="5 Hour">
                          &nbsp;&nbsp;&nbsp;5 Hour
                        </option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3">
                      <img src={image} alt="" style={{ width: "60px" }} />
                    </div>
                    <div className="col-lg-6">
                      <div>Image-1</div>
                      <input
                        type="file"
                        className="w-100 mt-"
                        name="image"
                        accept=".png, .jpg, .jpeg .webp" // Specify accepted file types
                        onChange={(e) => setProductimg(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3">
                      <img src={image1} alt="" style={{ width: "60px" }} />
                    </div>
                    <div className="col-lg-6">
                      <div>Image-2</div>

                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image2"
                        accept=".png, .jpg, .jpeg .webp" // Specify accepted file types
                        onChange={(e) => setProductimg1(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3">
                      <img src={image2} alt="" style={{ width: "60px" }} />
                    </div>
                    <div className="col-lg-6">
                      <div>Image-3</div>
                      <input
                        type="file"
                        className="w-100 mt-1"
                        name="image3"
                        accept=".png, .jpg, .jpeg .webp" // Specify accepted file types
                        onChange={(e) => setProductimg2(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2"
                        style={{}}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
