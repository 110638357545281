import "./category.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";


const Category = () => {
  const [users, setUser] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //  -----------------------------------------FETCH ALL CATEGORY---------------------------------

  useEffect(() => {
    axios
      .get("/api/all_category")
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {});
  }, []);

  // -------------------------------------------------------------------------------------

  //  -----------------------------------------DELETE CATEGORY---------------------------------
  const deleteHandler = (row) => {
    // alert("Are You Sure...... !");

    axios
      .delete("/api/category/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = users.filter((items) => {
          return row.id !== items.id;
        });
        setUser(newArr);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // -------------------------------------------------------------------------------------

  // -------------------------------------------TABLE COLUMN-------------------------
  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/category/view/${row.id}`} className="btn btn-primary m-1">
          <VisibilityIcon />
        </Link>
      ),
      width: "90px",
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Image",
      selector: (row) => (
        <img width={60} className="m-1" src={row.image} alt="MDN logo" />
      ),
      width: "130px",
    },
    {
      name: "Name",
      selector: (row) =>    <span className="text-capital">{row.name}</span>,
      sortable: true,
      width: "200px",
    },
    {
      name: "Created",
      selector: (row) =>  new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "180px",
    },

    {
      name: "Updated",
      selector: (row) => new Date(row.updated_at).toLocaleString(),
      sortable: true,
      width: "180px",
    },
    {
      name: "action",
      selector: (row) => (
        <div>
          <Link to={`/category/${row.id}`} className="btn btn-primary m-1">
            <EditIcon />
          </Link>
          <button
            className="btn btn-danger m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this category?"))
                deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  // -------------------------------------------------------------------------------------

  // ---------------------------------------no acess by path without token-------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // ------------------------------------------------data loded time------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(users);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -------------------------------------------------------------------------------------

  // ----------------------------------------INPUT FIELD CSS-----------------------------------------
  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;
  // -------------------------------------------------------------------------------------

  // ----------------------------------------FILTER DATA-----------------------------------------

  const filteredItems = users.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.created_at &&
        item.created_at.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.updated_at &&
        item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // -------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4">
              <CCardHeader>
              <strong>Category</strong>
            </CCardHeader>
              <div className="customerContainer">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 mt-1">
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="btn btn-primary m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <div className="mt-2 text-right ">
                        <Link
                          to="/category/add-category"
                          className="btn btn-primary mr-5"
                        >
                          + Add Category
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="m-1">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    fixedHeaderScrollHeight="480px"
                    fixedHeader
                    responsive
                    pagination={10}
                    subHeaderAlign="right"
                    paginationResetDefaultPage={resetPaginationToggle}
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<CustomeLoader />}
                  />
                </div>
              </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Category;
