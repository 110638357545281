import "bootstrap/dist/css/bootstrap.min.css";
import "./login.scss";
import logo from "./../all-image/Dawa-bajar-logo.png";
import image from "./../all-image/dawaimagebg.png";
import Navbar from "react-bootstrap/Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { MdVisibilityOff } from "react-icons/md";
import { MdVisibility } from "react-icons/md";
import { CForm, CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<MdVisibilityOff />);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = () => {
    axios
      .post("/api/admin/login", {
        email: email,
        password: password,
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        if (result.data.status === 400) {
          Toast.fire({
            icon: "error",
            title: result.data.message,
          });

          navigate("/");
          localStorage.removeItem("status");
        } else {
          Toast.fire({
            icon: "success",
            title: result.data.message,
          });

          navigate("/dashboard");
          localStorage.setItem("status", result?.data?.data?.token);
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("status")) {
      navigate("/dashboard");
    }
  }, []);
  const handleToggle = (e) => {
    e.preventDefault();
    if (type == "password") {
      setIcon(<MdVisibility />);
      setType("text");
    } else {
      setIcon(<MdVisibilityOff />);
      setType("password");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Navbar
          expand="lg"
          variant="light"
          bg="light"
          style={{ height: "80px" }}
        >
          <Navbar.Brand
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "25px",
              color: "red",
            }}
          >
            {" "}
            <img src={logo} alt="Logo" width={180} />
          </Navbar.Brand>
        </Navbar>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-5 login-image">
            <img src={image} alt="Logo" width={700} />
          </div>
          <div className="col-sm-7 mt-5">
            <div className="Auth-form">
              <div className="Auth-form-content">
                <h3 className="Auth-form-title" style={{ color: "#d93545" }}>
                  Admin Login
                </h3>

                <CForm onSubmit={handleSubmit}>
                  <CInputGroup className="my-4">
                    <CInputGroupText>
                      <CIcon className="text-black" icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      style={{ height: 45 }}
                      placeholder="Username"
                      onChange={handleEmail}
                      required
                      value={email}
                      autoComplete="username"
                    />
                  </CInputGroup>
                  <CInputGroup className="my-4">
                    <CInputGroupText>
                      <CIcon className="text-black" icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type={type}
                      placeholder="Password"
                      required
                      autoComplete="current-password"
                      onChange={handlePassword}
                      value={password}
                    />
                    <CInputGroupText>
                      <button
                        className="text-black"
                        onClick={handleToggle}
                        style={{
                          background: "#fff",
                          border: "none",
                          fontSize: "20px",
                        }}
                      >
                        <span>{icon}</span>
                      </button>
                    </CInputGroupText>
                  </CInputGroup>
                </CForm>
                <div className="submit-btn">
                  <button className="btn btn-danger" onClick={handleSubmit}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
