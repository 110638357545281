import "./datatable.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { CCardHeader, CCardBody, CCard } from "@coreui/react";
import CustomeLoader from "../CustomeLoader";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

const Category = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [users, setUser] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10); // Default value, you can adjust as needed
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true); // Added loading state
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //  -----------------------------------------FETCH ALL CATEGORY------------------------------------

  // useEffect(() => {
  //   axios
  //     .get("/api/recent_search")
  //     .then((res) => {
  //       console.log(res.data.data);

  //       setUser(res.data.data);
  //     })
  //     .catch((err) => {});
  // }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/recent_search?page=${page}&perPage=${perPage}&search_name=${search}`,
        {}
      );
      // setUser(res.data.data.data);
      res.data.status == "Success" ? setUser(res.data.data.data) : setUser([]);
      console.log(res.data.data.data);

      setPerPage(res.data.data.per_page);
      setTotalRows(res.data.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  // ---------------------------------------------------------------------------------------------------

  // ------------------------------------------- TABLE COLUMN ------------------------------------------

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      // sortable: true,
      width: "120px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.search ? row.search : "No Product Available";
        // const productName = row.search;
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      // sortable: true,
      width: "300px",
    },

    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      // sortable: true,
      width: "300px",
    },

    // {
    //   name: "Product",
    //   selector: (row) => row.search,
    //   sortable: true,
    //   width: "300px",
    // },

    // {
    //   name: "Description",
    //   selector: (row) => (
    //     <span className="text-capital">
    //       {row.description   !=null
    //         ? row.description
    //         : "No Description Avilable"}
    //     </span>
    //   ),
    //   sortable: true,
    //   width: "400px",
    // },

    // {
    //   name: "Description",
    //   selector: (row) => row.description !=0 ? row.description : "no dis",
    //   sortable: true,
    //   width: "220PX",
    // },

    {
      name: "Date",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      // sortable: true,
      width: "250px",
    },
  ];

  // const handlePerPageChange = (newPerPage) => {
  //   setPerPage(newPerPage);
  // };
  // ----------------------------------------------------------------------------------------------------

  // ---------------------------------------no acess by path without tken--------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  const handleRowsPerPageChange = (newPerPage, page) => {
    console.log(newPerPage, "fgggg");
    console.log(page, "pppppppp");
    setPerPage(newPerPage);
    setPage(page);
    // fetchData()
  };

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const filteredItems = users.filter(
    (item) =>
      item.search &&
      item.search.toLowerCase().includes(filterText.toLowerCase()) &&
      item.customer_name &&
      item.customer_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const clearData = async (e) => {
    e.preventDefault();

    axios
      .get("/api/clear_search", {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  // ----------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <CCard className="mb-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded-4 ml-3">
          <CCardHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <strong>
              {" "}
              Product searched by customer which is not present in inventory
            </strong>
            <button onClick={clearData} className="btn btn-danger mb-2">
              <DeleteIcon /> Clear Data
            </button>
          </CCardHeader>

          <div className="col-sm-6 mt-1">
            <TextField
              type="text"
              placeholder="Search...."
              value={filterText}
              autoFocus
              onChange={(e) => setFilterText(e.target.value)}
              onKeyUp={handleKeyUp}
            />
            <button
              className="btn btn-primary m-1"
              onClick={handleClear}
              // onClick={(e) => searchProduct(e.target.value)}
            >
              X
            </button>
            {/* <button
                    className="btn btn-primary m-1"
                    onClick={handleSearchButtonClick}
                  >
                    Search
                  </button> */}
          </div>

          <CCardBody>
            {users.length == 0 ? (
              <div className="text-center fw-600 my-5 fs-18 text-red">
                <span>No Data Available</span>
              </div>
            ) : (
              <DataTable
                // onColumnOrderChange={}
                columns={columns}
                data={users}
                // conditionalRowStyles={conditionalRowStyles}
                defaultSortFieldId
                // fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                progressComponent={<CustomeLoader />}
                onChangePage={(page) => setPage(page)}
                onPerPageChange={handleRowsPerPageChange} // Add this line
              />
            )}
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};
export default Category;
