import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const Widget = ({ type }) => {
  const [widgetdatacustomer,setwidgetdatacustomer] = useState("");
  const [widgetdataproduct,setwidgetdataproduct] = useState("");
  const [widgetdatasupplier,setwidgetdatasupplier] = useState("");
  const [widgetdataorder,setwidgetdataorder] = useState("");
  const [widgetdatanewcustomer,setwidgetdatanewcustomer] = useState("");
  const [widgetdatatopusers,setwidgetdatatopusers] = useState("");
  const [widgetdataactiveorder,setwidgetdataactiveorder] = useState("");
  const [widgetdatadeliveredorder,setwidgetdatadeliveredorder] = useState("");

  // const [widgetdatacartlist,setwidgetdatacartlist] = useState("");



  useEffect(() => {
    axios.get("/api/dashboard_data" ).then((res) => {

      if (res.data.status == "Success") {
        // setOrders(res.data.data);
        setwidgetdatacustomer(res.data.data?.customer);
        setwidgetdataproduct(res.data.data?.product);
        setwidgetdatasupplier(res.data.data?.supplier);
        setwidgetdataorder(res.data.data?.order);
        setwidgetdatanewcustomer(res.data.data?.new_customer);
        setwidgetdatatopusers(res.data.data?.top_users);
        setwidgetdataactiveorder(res.data.data?.order_active);
        setwidgetdatadeliveredorder(res.data.data?.order_delivery);
    } else {
        // setOrders([]);
        setwidgetdatacustomer([]);
        setwidgetdataproduct([]);
        setwidgetdatasupplier([]);
        setwidgetdataorder([]);
        setwidgetdatanewcustomer([]);
        setwidgetdatatopusers([]);
        setwidgetdataactiveorder([]);
        setwidgetdatadeliveredorder([]);
    }
    
      
      // setwidgetdatacustomer(res.data.data?.customer);
      // setwidgetdataproduct(res.data.data?.product);
      // setwidgetdatasupplier(res.data.data?.supplier);
      // setwidgetdataorder(res.data.data?.order);
      // setwidgetdatanewcustomer(res.data.data?.new_customer);
      // setwidgetdatatopusers(res.data.data?.top_users);
      // setwidgetdataactiveorder(res.data.data?.order_active);
      // setwidgetdatadeliveredorder(res.data.data?.order_delivery);
      // setwidgetdatadeliveredorder(res.data.data?.order_delivery);
    });
  }, []);
  let data;
  //temporary
  const amount = 100;
  switch (type) {
    case "user":
      data = {
        
        title: "All Customers",
        isMoney: false,
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",

        
        link: "View all customers",
      linkto:"/customers",
      total:widgetdatacustomer,
        // icon: (
        //   <PersonOutlinedIcon
        //     className="icon"
        //     style={{
        //       color: "crimson",
        //       backgroundColor: "rgba(255, 0, 0, 0.2)",
        //     }}
        //   />
        // ),
      };
      break;
    case "order":
      data = {
        title: "Products",
        isMoney: false,
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",


        link: "View all products",
        linkto:"/products",
        total:widgetdataproduct,
        // icon: (
        //   <ShoppingCartOutlinedIcon
        //     className="icon"
        //     style={{
        //       backgroundColor: "rgba(218, 165, 32, 0.2)",
        //       color: "goldenrod",
        //     }}
        //   />
        // ),
      };
      break;
    case "earning":
      data = {
        title: "Suppliers",
        isMoney: false,
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",

        
        link: "View all suppliers",
        linkto:"/supplier",
        total:widgetdatasupplier,
        // icon: (
        //   <MonetizationOnOutlinedIcon
        //     className="icon"
        //     style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
        //   />
        // ),
      };
      break;
    case "balance":
      data = {
        title: "All Orders",
        isMoney: false,
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        
        link: "View all orders",
        linkto:"/order",
        total:widgetdataorder,
        // icon: (
        //   <AccountBalanceWalletOutlinedIcon
        //     className="icon"
        //     style={{
        //       backgroundColor: "rgba(128, 0, 128, 0.2)",
        //       color: "purple",
        //     }}
        //   />
        // ),
      };

      break;

      case "newcustomer":
      data = {
        title: "New Customers",
        isMoney: false,
        // bgcolor:"linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%)",
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        
        link: "View all new customer",
        linkto:"/customers?type=new",
        total:widgetdatanewcustomer,
        // icon: (
        //   <PersonOutlinedIcon
        //     className="icon"
        //     style={{
        //       color: "crimson",
        //       backgroundColor: "rgba(255, 0, 0, 0.2)",
        //     }}
        //   />
        // ),
      };

      break;

      // case "newusers":
      // data = {
      //   title: "New Users",
      //   isMoney: false,
      //   // bgcolor:"linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
        
      //   link: "View all new users",
      //   linkto:"/order",
      //   total:widgetdataorder,
      //   icon: (
      //     <PersonOutlinedIcon
      //       className="icon"
      //       style={{
      //         color: "crimson",
      //         backgroundColor: "rgba(255, 0, 0, 0.2)",
      //       }}
      //     />
      //   ),
      // };
      // break;
      // case "topusers":
        data = {
          title: "Top Users",
          isMoney: false,
          // bgcolor:"linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
          
          link: "View all top users",
          linkto:"/order",
          total:widgetdatatopusers,
          icon: (
            <PersonOutlinedIcon
              className="icon"
              style={{
                color: "crimson",
                backgroundColor: "rgba(255, 0, 0, 0.2)",
              }}
            />
          ),
        };
      break;
      
      case "selling":
        data = {
          title: "Active Order",
          isMoney: false,
          // bgcolor:" linear-gradient(to top, #fddb92 0%, #d1fdff 100%)",
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
          
          link: "View all Active Order",
          linkto:"/order?type=active",
          total:widgetdataactiveorder,
          // icon: (
          //   <ShoppingCartOutlinedIcon
          //     className="icon"
          //     style={{
          //       backgroundColor: "rgba(218, 165, 32, 0.2)",
          //       color: "goldenrod",
          //     }}
          //   />
          // ),
        };
        break;
        case "allorders":
          data = {
            title: "All Orders",
            isMoney: false,
            bgcolor:"green",
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",

            
            link: "View all Order",
            linkto:"/order",
            total:widgetdataorder,
            // icon: (
            //   <AccountBalanceWalletOutlinedIcon
            //     className="icon"
            //     style={{
            //       backgroundColor: "rgba(255, 248, 99, 0.2745)",
            //       color: "purple",
            //     }}
            //   />
            // ),
          };
        break;

        case "activeorder":
          data = {
            title: "Active Order",
            isMoney: false,
            bgcolor:"green",
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",

            
            link: "View all Active Order",
            linkto:"/order?type=active",
            total:widgetdataactiveorder,
            // icon: (
            //   <AccountBalanceWalletOutlinedIcon
            //     className="icon"
            //     style={{
            //       backgroundColor: "rgba(128, 0, 128, 0.2)",
            //       color: "purple",
            //     }}
            //   />
            // ),
          };
        break;

        case "deliveredorder":
          data = {
            title: "Delivered Order",
            isMoney: false,
            // bgcolor:"green",
        bgcolor:"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",

            
            link: "View all Delivered Order",
            linkto:"/order?type=deliver",
            total:widgetdatadeliveredorder,
            // icon: (
            //   <AccountBalanceWalletOutlinedIcon
            //     className="icon"
            //     style={{
            //       backgroundColor: "rgba(128, 0, 128, 0.2)",
            //       color: "purple",
            //     }}
            //   />
            // ),
          };
        break;

        // case "cartlist":
        //   data = {
        //     title: "Cart List",
        //     isMoney: false,
        //     bgcolor:"green",
            
        //     link: "View all Cart",
        //     // linkto:"/api/orders?type=new",
        //     total:widgetdataactiveorder,
        //     icon: (
        //       <AccountBalanceWalletOutlinedIcon
        //         className="icon"
        //         style={{
        //           backgroundColor: "rgba(128, 0, 128, 0.2)",
        //           color: "purple",
        //         }}
        //       />
        //     ),
        //   };
        // break;

    default:
      break;
  }

 

  return (
    <div className="widget ml-3 " style={{backgroundImage:data.bgcolor}}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.total}
        </span>
       <Link  style={{textDecoration:"none",color:"grey"}} to={data.linkto}><span className="link">{data.link}</span></Link> 
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* <KeyboardArrowUpIcon /> */}

        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
