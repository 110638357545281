import React from "react";
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
} from "@coreui/react";

const PrivacyPolicy = () => {
  return (
    <>
      <CContainer className="mt-2 mb-2">
        <CRow>
          <CCol className="box-shadow">
            <CCard className="mt-3 mb-3">
              <CCardBody>
                <CCardTitle
                  style={{
                    fontSize: "30px",
                    color: "#ffffff",
                    background:
                      "linear-gradient(115deg, rgb(191, 61, 69) 33.33%, rgb(215, 60, 70) 33.33%, rgb(215, 60, 70) 66.66%, rgb(212, 75, 84) 66.66%)",
                    textAlign: "center",
                    padding: "80px",
                  }}
                >
                  Privacy Policy
                </CCardTitle>
                <CCardText>
                  <p>
                    This Privacy Policy explains how Dawabajar ({" "}
                    <strong>“we“</strong> or <strong>“our“</strong>) collects,
                    uses, and safeguards the personal information of users (
                    “you“ or “your“) when you use the Dawabajar mobile
                    application (the <strong>“App“</strong>) for online pharmacy
                    services. We are committed to protecting your privacy and
                    ensuring the security of your personal information. By using
                    the App, you consent to the practices described in this
                    Privacy Policy.
                  </p>

                  <h4>1. Information We Collect:</h4>
                  <p>
                    <strong>a. Personal Information:</strong>
                    <br />
                    We may collect the following personal information when you
                    use the App:
                  </p>

                  <p>
                    <ul>
                      <li>Full name</li>
                      <li>
                        Contact information (e.g., email address, phone number,
                        shipping address)
                      </li>
                      <li>Date of birth</li>
                      <li>Prescription information</li>
                      <li>
                        Payment details (credit card information, bank account
                        details)
                      </li>
                      <li>
                        Health-related information provided by you or your
                        healthcare provider
                      </li>
                      <li>
                        Any other information you voluntarily provide to us
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>b. Usage Information:</strong>
                    <br />
                    We may collect non-personally identifiable information about
                    how you use the App, including:
                  </p>

                  <p>
                    <ul>
                      <li>
                        Device information (e.g., device type, operating system,
                        unique device identifiers)
                      </li>
                      <li>
                        Log information (e.g., IP address, access times, browser
                        type)
                      </li>
                      <li>
                        App usage metrics (e.g., pages visited, features used,
                        time spent on the App)
                      </li>
                      <li>
                        Location information (with your consent), which may be
                        used for relevant services
                      </li>
                    </ul>
                  </p>
                  <p>
                    <h4>2. Usage Information:</h4>
                    <p>
                      <strong>a. Provision of Services:</strong>
                      <br />
                      We use your personal information to provide you with our
                      online pharmacy services, including:
                    </p>

                    <ul>
                      <li>Processing and fulfilling your orders</li>
                      <li>
                        Contacting you regarding your orders, payments, or
                        deliveries
                      </li>
                      <li>
                        Providing customer support and responding to your
                        inquiries
                      </li>
                      <li>
                        Verifying your identity and eligibility for certain
                        services
                      </li>
                      <li>
                        Sending you important service-related notifications
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>b. Personalization and Improvements:</strong>
                    <br />
                    We may use your information to personalize your experience
                    on the App and improve our services, including:
                  </p>

                  <ul>
                    <li>
                      Customizing content, offers, and recommendations based on
                      your preferences
                    </li>
                    <li>
                      Conducting data analysis and research to enhance our
                      services
                    </li>
                    <li>
                      Monitoring and improving the App's functionality and
                      performance
                    </li>
                    <li>Developing new features, products, and services</li>
                  </ul>
                  <strong>c. Legal Compliance and Protection:</strong>
                  <p>
                    We may process your personal information to comply with
                    applicable laws and regulations, enforce our terms and
                    policies, and protect our rights, safety, and property.
                  </p>
                  <h4>3. Sharing of Information:</h4>
                  <strong>a. Service Providers:</strong>
                  <p>
                    We may share your personal information with trusted
                    third-party service providers who assist us in delivering
                    our services. These service providers are contractually
                    obligated to keep your information confidential and use it
                    solely for the purposes of providing services to us.
                  </p>
                  <strong>b. Business Transfers:</strong>
                  <p>
                    In the event of a merger, acquisition, or sale of our
                    business, your personal information may be transferred to
                    the acquiring entity, subject to their compliance with this
                    Privacy Policy.
                  </p>
                  <strong>c. Legal Compliance and Protection:</strong>
                  <p>
                    We may disclose your personal information if required by
                    law, legal process, or government request. We may also
                    disclose your information to protect our rights, safety, or
                    property, or the rights, safety, or property of others.
                  </p>

                  <h4>4. Security:</h4>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, alteration,
                    disclosure, or destruction. However, please note that no
                    method of transmission or storage is completely secure.
                    Therefore, we cannot guarantee the absolute security of your
                    information.
                  </p>

                  <h4>5.Your Choices:</h4>
                  <strong>a. Account Information:</strong>
                  <p>
                    You can review and update your account information within
                    the App. It is your responsibility to ensure that your
                    information is accurate and up to date.
                  </p>
                  <strong>b. Communication Preferences:</strong>
                  <p>
                    You can choose to opt out of receiving promotional
                    communications from us by following the instructions
                    provided in the communication or by contacting us directly.
                    However, please note that we may still send you important
                    service-related messages that are necessary for the
                    provision of our services.
                  </p>
                  <strong>c. Data Access</strong>
                  <p>
                    You can review and update your account information within
                    the App. It is your responsibility to ensure that your
                    information is accurate and up to date.
                  </p>
                  
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default PrivacyPolicy;
