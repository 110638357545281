import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import DownloadIcon from "@mui/icons-material/Download";

const ViewPurchaseOrder = () => {
  const { id } = useParams();
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [invoice, setInvoice] = useState("");

  const [setting, setSetting] = useState("");
  const [image, setImage] = useState("");
  // ------------------------------------------FETCH SINGLE PURCHASE ORDER------------------------------------------

  useEffect(() => {
    loadCustomer();
  }, []);
  const loadCustomer = async () => {
    const result = await axios.get("/api/single_batch/" + id);
    setProducts(result.data.data);
    setProduct(result.data.po);
    setInvoice(result.data.invoice);

  };

  useEffect(() => {
    axios.get("/api/get_settings").then((res) => {
      setSetting(res.data.data);
      setImage(res.data.data.logo);
    });
  }, []);

 
  // --------------------------------SINGLT PURCHASE ORDER PRODUCT DETAILS TABLE COLUMN-------------------------------

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "60px",
    },

    {
      name: "Product Id",
      selector: (row) => row.product?.id,
      width: "100px",
    },

    

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product?.product_name ? row.product?.product_name : "Not Available";
        if (productName.length > 20) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 20 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "170px",
    },

    {
      name: "Pack",
      selector: (row) => row.product?.pack_of,
      width: "120px",
    },

    {
      name: "HSN",
      selector: (row) => row.product?.hsn,
      width: "140px",
    },

    {
      name: "Batch",
      selector: (row) => row.batch_number,
      width: "140px",
    },

    {
      name: "Expiry",
      selector: (row) => row.expiry,
      width: "90px",
    },

    

    // {
    //   name: "Free Q",
    //   selector: (row) => row.free_quantity,
    //   width: "80px",
    // },

    {
      name: "Sale Rate",
      selector: (row) => Number(row.sale_rate).toFixed(2),

      width: "120px",
    },

    

    {
      name: "Discount%",
      selector: (row) => row.discount,
      width: "100px",
    },

    {
      name: "Add. Discount%",
      selector: (row) => row.additional_discount,
      width: "140px",
    },

    {
      name: "GST%",
      selector: (row) => row.product?.gst,
      width: "90px",
    },

    {
      name: "Bill Q",
      selector: (row) => row.quantity,
      width: "80px",
    },
   
    {
      name: "Purchase R",
      selector: (row) => Number(row.final_purchase_rate).toFixed(2),

      width: "120px",
    },

    
  ];

  // ------------------------------------------NO ACCESS WITHOUT TOKEN------------------------------------------

 
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // const goBack = () =>{
  //   navigate(-1)
  // }
  // const queryParams = new URLSearchParams(window.location.search);
  // const currentPageNumber = queryParams.get('page') || 1;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white ml-3">
            {/* <button onClick={goBack}> Back</button> */}
            {/* <Link to={`/purchase-order?page=${currentPageNumber}`}>Purchase-order</Link> */}
            {/* <Link to={`/purchase-order?page=${currentPageNumber}`}>Back</Link> */}
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/purchase-order">Purchase-order</Link> / View
              </span>
            </div>
            <section
              className="bg-gre ml-3"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol
                            sm="3"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span className="fw-600">
                                <strong className="text-capital" style={{ fontSize: "20px" }}>{product.supplier_name}</strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">
                                {product.supplier_mobile}
                              </span>
                            </MDBCardText>
                            <MDBCardText>
                              <span className="fw-600">
                                {product.supplier_address +
                                  " , " +
                                  product.supplier_city +
                                  " , " +
                                  product.supplier_district +
                                  " , " +
                                  product.supplier_pincode}
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              Bill No. -
                              <span className="fw-600">
                                {product.bill_number}
                              </span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol
                            sm="3"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              Order No :{" "}
                              <span className="fw-600">
                                {product.order_number}
                              </span>
                            </MDBCardText>
                            <MDBCardText>
                              DL No. :{" "}
                              <span className="fw-600">
                                {product.supplier_drug_license}
                              </span>
                            </MDBCardText>
                            <MDBCardText>
                              GST No. :{" "}
                              <span className="fw-600">
                                {product.supplier_gst_number}
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              Date :{" "}
                              <span className="fw-600">{product.date}</span>
                            </MDBCardText>
                          </MDBCol>

                          <MDBCol
                            sm="3"
                            className="border-right"
                            style={{ fontSize: "14px" }}
                          >
                            <MDBCardText>
                              <span className="fw-600">
                                <strong style={{ fontSize: "20px" }}>{setting.company_name}</strong>
                              </span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.email}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.phone}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.website}</span>
                            </MDBCardText>

                            <MDBCardText>
                              <span className="fw-600">{setting.address}</span>
                            </MDBCardText>
                           
                          </MDBCol>

                          <MDBCol className="d-flex align-items-center" sm="3">
                            <img
                              src={image}
                              className="rounded mx-auto d-block"
                              alt="Logo"
                              width={180}
                            />
                          </MDBCol>

                          
                        </MDBRow>

                        <hr />

                        <MDBRow className="mt-1">
                          <div style={{ margin: "1%" }}>
                            <DataTable
                              columns={columns}
                              title="Products Detail"
                              data={products}
                              defaultSortFieldId
                              fixedHeader
                              responsive
                              
                              allowOverflow
                              subHeaderAlign="right"
                             
                            />
                          </div>

                          <MDBRow className="mt-2">
                            <hr></hr>
                            <MDBRow className="mt-2">
                              <MDBCol sm="5"></MDBCol>
                              <MDBCol sm="4"></MDBCol>
                              

                              <MDBCol sm="3">
                                <MDBCardText style={{ fontSize: "25px" }}>
                                  Total :{" "}
                                  <span
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    ₹ {parseFloat(product.grand_total).toFixed(2)}
                                  </span>
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>

                            <MDBCol sm="9">
                              <MDBCardText>
                                Download Invoice : 
                                <Link to={invoice}>
                                  <button
                                    className="bg-white"
                                    style={{ border: "none" }}
                                  >
                                    <DownloadIcon />
                                  </button>
                                </Link>
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
