import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./category.scss";
import axios from "axios";
import { Link } from "react-router-dom";

const AddCategory = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [msg, setMsg] = useState("");
  const [errormsg, seterrorMsg] = useState("");
  const [image, setImage] = useState("");

  // ---------------------------------------------ADD CATEGORY----------------------------------

  const submitForm = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();
    categoryobj.append("name", name);
    categoryobj.append("description", description);
    categoryobj.append("image", image);
    axios
      .post("/api/create_category", categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
       
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        
      });
  };

  //  ---------------------------------------------no access by path without token ----------------------------------------

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/category">Category</Link> / View
                </span>
              </div>
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <div className="bg-gre" style={{ padding: "38px" }}>
                <div className="main-box">
                  <h5>
                    <center className="mb-4 mt-3">
                      <b>Add New Category</b>
                    </center>
                  </h5>
                  <div className="text-box">
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      className="input-field w-40 mb-4"
                      name="name"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      className="input-field w-40 mb-4"
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={3}
                      multiline
                    />
                    <input
                      type="file"
                      className="input-field w-23 mb-5"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <button
                      onClick={submitForm}
                      className="btn btn-outline-danger  w-25 input-field mb-4"
                    >
                      Add Category
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
