import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const UpdateBrand = () => {
  const { id } = useParams();
  const [msg, setMsg] = useState("");
  const [category, setcategory] = useState("");
  const [brand, setBrand] = useState({
    name: "",
    description: "",
    image: "",
    category: "",
  });
  const [nullvalue] = useState({
    name: "",
    description: "",
    image: "",
    category: "",
  });
  const [picture, setPicture] = useState({
    image: "",
  });
  //  ---------------------------------HANDLE MANUFACTURER INPUTFIELD DATA----------------------------------
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });



  const handleInput = (e) => {
    e.persist();
    setBrand({ ...brand, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  //  ---------------------------------FETCH SINGLE MANUFACTURER----------------------------------

  useEffect(() => {
    axios
      .get("/api/get_single_brand/" + id)
      .then((res) => {
        console.log(res)
        setBrand(res.data.data);
        setcategory(res.data.data.type)
      });
  }, []);

  // -------------------------------------UPDATE MANUFACTURER---------------------------------------

  const updateBrands = async (e) => {
    e.preventDefault();


  // Check if image files are selected and validate their types
  if (!brand.name || !category) {
    // alert("Please select all three images.");
    Toast.fire({
      icon: "error",
      title: "Please select All feilds.",
    });
    return;
  // } else if (
  //   !["image/png", "image/jpeg", "image/jpg"].includes(picture.image.type)
   
  // ) {
    Toast.fire({
      icon: "error",
      title: "Please select only PNG, JPEG, or JPG image files.",
    });
    // alert("Please fill in all fields");
    return;
    // alert("Please select only PNG, JPEG, or JPG image files.");
    // return;
  }
  


    const brandobj = new FormData();
    brandobj.append("name", brand.name);
    brandobj.append("description", "");
    brandobj.append("image", picture.image);
    brandobj.append("type", category);
    brandobj.append("_method", "put");

    console.log(picture.image);

    axios
      .post(
        "/api/update_brand/" + id,
        brandobj,
        {
          header: {
            accept: "application/json",
          },
        }
      )
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        // setMsg(result.data.message);
        // alert(result.data.message);

        setBrand(nullvalue);
        navigate("/manufacturer");
        console.log(result.data.message);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      //  alert(error.message)
        
      });
  };

  // ----------------------------------------------NO ACCESS WITHOUT TOKEN---------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/manufacturer">Manufacturer</Link> / Edit
                </span>
              </div>
              <div className="bg-gre" style={{ padding: "35px" }}>
                <div className="edit-box">
                  <h5>
                    <center className="mb-3 mt-3">
                      <b>Edit Manufacturer</b>
                    </center>
                  </h5>
                  {/* {msg && <div className="alert alert-success">{msg}</div>} */}
                  <form onSubmit={updateBrands} encType="multipart/form-data">
                    <div className="text-box">
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        className="w-40 mx-auto mb-2"
                        name="name"
                        type="text"
                        placeholder="Name"
                        value={brand.name}
                        onChange={handleInput}

                        // onChange={(e) => {
                        //       const input = e.target.value.replace(
                        //         /[^a-zA-Z]/g,
                        //         ""
                        //       ); // Remove non-alphabetical characters
                        //       handleInput(input);
                        //     }}
                        //     onKeyDown={(e) => {
                        //       // Prevent typing of non-alphabetical characters
                        //       if (!e.key.match(/[a-zA-Z]/)) {
                        //         e.preventDefault();
                        //       }
                        //     }}
                      />
                      
                      {/* <TextField
                        id="outlined-basic"
                        label="Description"
                        variant="outlined"
                        className="w-40 mx-auto mb-2"
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={brand.description}
                        onChange={handleInput}
                        rows={3}
                        multiline
                      /> */}
                      
                        <select
                      className="w-40 mb-4"
                      name="type"
                      onChange={(e) => setcategory(e.target.value)}
                      style={{ height: "55px" }}
                    >
                      <option value="">Select Category</option>
                      <option selected={brand.type == "Top Rx Brand"} value="Top Rx Brand">Top Rx Brand</option>
                      <option selected={brand.type == "OTC Brand"}  value="OTC Brand">OTC Brand</option>
                      <option selected={brand.type == "Generic Brand"}  value="Generic Brand">Generic Brand</option>
                    </select>
                      <div className="text-center mb-3 image-box">
                        <img src={brand.image} alt="" className="m-3 w-10" />
                      </div>
                      <input
                        type="file"
                        className="w-23 mx-auto mb-3"
                        name="image"
                      accept=".png, .jpg, .jpeg" // Specify accepted file types

                        onChange={handleImage}
                      />
                      <button className="btn btn-danger mb-2">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBrand;
