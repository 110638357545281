import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditNewArrivals = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [productimg, setProductimg] = useState("");

  const [singleproduct, setSingleproduct] = useState("");
  const [image, setimage] = useState("");

  const [status, setStatus] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/get-new-arrivals/" + id)
      .then((res) => {
        setSingleproduct(res.data.data.title);
        setimage(res.data.data.media);

        setStatus(res.data.data.is_show);
      })
      .catch((err) => {});
  }, [id]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (!singleproduct) {
      Toast.fire({
        icon: "error",
        title: "Please fill out all fields.",
      });
      return;
    }

    const productobj = new FormData();
    productobj.append("id", id);
    productobj.append("title", singleproduct);
    productobj.append("media", productimg);

    productobj.append("status", status);

    await axios
      .post("/api/edit-new-arrivals", productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        result.data.status == 400
          ? navigate("/api/edit-new-arrivals")
          : navigate("/new-arrivals");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/new-arrivals">New Arrivals</Link> / Edit New
                  Arrivals
                </span>
              </div>

              <section className="bg-gre" style={{ padding: "50px" }}>
                <div className="row bg-white pl-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded">
                  <h5>
                    <center className="mb-4 mt-3">Edit New Arrivals</center>
                  </h5>

                  <div className="row ">
                    <div className="col-12 mb-3 ">
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        className="w-100"
                        name="title"
                        type="text"
                        placeholder="Title"
                        value={singleproduct}
                        onChange={(e) => {
                          setSingleproduct(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-3">
                      <img src={image} alt="" style={{ width: "60px" }} />
                    </div>
                    <div className="col-lg-6">
                      <div>Image-1</div>
                      <input
                        type="file"
                        className="w-100 mt-"
                        name="image"
                        onChange={(e) => setProductimg(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="col-6 mb-3 mt-5">
                    <span>Status</span>
                  </div>
                  <div className="col-6 mb-3 mt-5"></div>
                  <div className="col-2 form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="inlineRadio1"
                      value="1"
                      status
                      checked={status === 1}
                      onChange={() => setStatus(1)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="col-2 form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="inlineRadio2"
                      value="0"
                      checked={status === 0}
                      onChange={() => setStatus(0)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>

                  <div className="row my-2">
                    <div className="col-12 text-center mb-5">
                      <button
                        onClick={submitForm}
                        className="btn btn-danger mb-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNewArrivals;
