import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
const GstSaleReport = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [gst, setGst] = useState([]);

  const [invoice, setInvoice] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const CustomInput = ({ value, onClick }) => (
    <button
      style={{
        width: "250px",
        padding: "5px",
        textAlign: "center",
        backgroundColor: "#ffffff",
        border: "1px solid black",
      }}
      className="custom-date-input"
      onClick={onClick}
    >
      {value || "Select Date"}
    </button>
  );

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/gst_sale_report?page=${page}&perPage=${perPage}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );

      if (res.data.status === "Success") {
        setGst(res.data.data.data);
        setInvoice(res.data.download_pdf);
        setPerPage(res.data.data.per_page);
        setTotalRows(res.data.data.total);
      } else {
        setGst([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, formattedStartDate, formattedEndDate]);

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: "Date",
      selector: (row, index) => <div>{row.date}</div>,
      width: "150px",
    },

    {
      name: "TAXABLE Amount",
      selector: (row, index) => <div>{row.all_amount}</div>,
      width: "150px",
    },

    {
      name: "CGST",
      selector: (row) => <span className="text-capital">{row.cgst}</span>,
      width: "120px",
    },

    {
      name: "SGST",
      selector: (row) => <span className="text-capital">{row.sgst}</span>,
      width: "120px",
    },

    {
      name: "Total GST",
      selector: (row) => <span className="text-capital">{row.total_gst}</span>,
      width: "120px",
    },

    {
      name: "Net Value",
      selector: (row, index) => <div>{row.total}</div>,
      width: "150px",
    },
  ];

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <style>
                    {`
      .no-hover-effect {
        background-color: #110854; /* Same as your button's background color */
        color: #ffffff; /* Same as your button's text color */
      }

      .no-hover-effect:hover,
      .no-hover-effect:focus {
        background-color: #110854; /* Prevent color change on hover and focus */
        color: #ffffff; /* Prevent text color change on hover and focus */
        outline: none; /* Remove focus outline */
        box-shadow: none; /* Remove focus box-shadow */
      }
    `}
                  </style>
                  <div className="d-flex justify-content-between align-items-center">
                    <strong>GST Sale Report</strong>
                    <div className="d-flex align-items-center m-1">
                      <strong>Download pdf: </strong>

                      <Link to={invoice}>
                        <button
                          className="btn no-hover-effect"
                          style={{ border: "none", padding: "0 10px" }}
                        >
                          {" "}
                          <DownloadIcon
                            style={{
                              width: "20px",
                              color: "#ffffff",
                              borderRadius: "50%",
                            }}
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </CCardHeader>
                <div className="customerContainer">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="col-sm-4 pt-2 mr-2 d-flex flex-column align-items-start">
                      <div className="d-flex justify-content-between align-items-center w-75">
                        <div>
                          <strong>Start Date</strong>{" "}
                        </div>
                        <div>
                          <strong>End Date</strong>{" "}
                        </div>
                      </div>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          if (
                            update &&
                            Array.isArray(update) &&
                            update.length === 2
                          ) {
                            const start = update[0];
                            const end = update[1];
                            setStartDate(start);
                            setEndDate(end);
                          } else {
                            setStartDate(null);
                            setEndDate(null);
                          }
                        }}
                        isClearable={true}
                        withPortal
                        showIcon
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    {gst.length == 0 ? (
                      <div className="text-center fw-600 my-5 fs-18 text-red">
                        <span>Data Not Available</span>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={gst}
                        defaultSortFieldId
                        fixedHeader
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                        progressComponent={<CustomeLoader />}
                      />
                    )}
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GstSaleReport;
