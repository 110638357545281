import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import "./customers.scss";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import Swal from "sweetalert2";

const AddCustomer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [shopname, setShopName] = useState("");
  const [password, setpassword] = useState("");
  const [isVerified, setisVerified] = useState("");
  const [shopVerified, setshopVerified] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [pin, setPin] = useState("");
  const [shopstate, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [stateID, setStateId] = useState("");
  const [cityID, setCityID] = useState("");
  const [shopECimg, setShopECImage] = useState("");
  const [druglicenseimg, setDruglicenseImage] = useState("");
  const [gstcertificateimg, setGstcertificateImage] = useState("");
  const [fssaicertificateimg, setFssaicertificateImage] = useState("");
  const [udhyamadharimg, setUdhyamadharImage] = useState("");
  const [currentAchequeimg, setCurrentAchequeImage] = useState("");
  const [othershopdocumentimg, setOthershopdocumentImage] = useState("");
  const [tradecertificateimg, setTradecertificateImage] = useState("");
  const [profileimg, setProfileImage] = useState("");
  const [shopimg, setShopImage] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [drugLnumber, setdrugLnumber] = useState("");
  const [tinnumber, settinnumber] = useState("");
  const [gstregistered, setgstregistered] = useState("");

  const customerVerify = [
    {
      value: 1,
      name: "Verified",
    },
    {
      value: 0,
      name: "Not Verified",
    },
  ];

  const shopVerify = [
    {
      value: 0,
      name: "Not Verify",
    },
    {
      value: 1,
      name: "Processing",
    },
    {
      value: 2,
      name: "Verified",
    },
  ];

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios.get("/api/states").then((res) => {
      setState(res.data.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/cities/" + stateID).then((res) => {
      setCity(res.data.data);
    });
  }, [stateID]);

  const submitForm = async (e) => {
    // if (pin.length !== 6) {
    //   Toast.fire({
    //     icon: "error",
    //     title: " Required all fields & Pin code must be exactly 6 digits long.",
    //   });
    //   return;
    // }

    // if (mobile.length !== 10) {
    //   Toast.fire({
    //     icon: "error",
    //     title: "Mobile No. must be exactly 10 digits long.",
    //   });
    //   return;
    // }

    e.preventDefault();
    const customerobj = new FormData();
    customerobj.append("name", name);
    customerobj.append("email", email);
    customerobj.append("mobile", mobile);
    customerobj.append("is_verified", isVerified);
    customerobj.append("shop_name", shopname);
    customerobj.append("profile_image", profileimg);
    customerobj.append("shop_image", shopimg);
    customerobj.append("password", password);
    customerobj.append("address1", address1);
    customerobj.append("address2", address2);
    customerobj.append("city", cityID);
    customerobj.append("state", stateID);
    customerobj.append("pin", pin);
    customerobj.append("shop_verified", shopVerified);
    customerobj.append("shop_establishment_certificate", shopECimg);
    customerobj.append("drug_license", druglicenseimg);
    customerobj.append("trade_certificate", tradecertificateimg);
    customerobj.append("udhyam_adhar", udhyamadharimg);
    customerobj.append("fssai", fssaicertificateimg);
    customerobj.append("gst_certificate", gstcertificateimg);
    customerobj.append("current_acount_cheque", currentAchequeimg);
    customerobj.append("other_shop_document", othershopdocumentimg);
    customerobj.append("drug_license_no", drugLnumber);
    customerobj.append("tin_no", tinnumber);
    customerobj.append("gst_no", gstnumber);
    customerobj.append("gst_registered", gstregistered);

    await axios
      .post("/api/create_customer", customerobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        navigate("/customers");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const handlePinChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPin(e.target.value);
    }
  };

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white">
                <span>
                  <Link to="/dashboard">Dashboard</Link> /{" "}
                  <Link to="/customers">Customer</Link> / Add
                </span>
              </div>

              <section className="bg-gre p-0">
                <MDBContainer className="px-5">
                  <MDBRow>
                    <MDBCol lg="4">
                      <MDBCard className="mb-3 mt-4">
                        <MDBCardBody>
                          <MDBRow>
                            <MDBCol sm="12">
                            <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                className="w-100 mx-auto capitalize-first-letter"
                                name="name"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => {
                                  // Capitalize the first letter
                                  const value = e.target.value;
                                  const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                  setName(capitalizedValue);
                                }}
                                onKeyDown={(e) => {
                                  if (!e.key.match(/[a-zA-Z ]/)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {/* <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="name"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onKeyDown={(e) => {
                                  if (!e.key.match(/[a-zA-Z ]/)) {
                                    e.preventDefault();
                                  }
                                }}
                              /> */}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Mobile"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="mobile"
                                type="text"
                                placeholder="Mobile"
                                value={mobile}
                                onChange={(e) =>
                                  handleNumericInput(e, setmobile)
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="password"
                                type="text"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setpassword(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Shop Name"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="shop_name"
                                type="text"
                                placeholder="Shop Name"
                                value={shopname}
                                onChange={(e) => setShopName(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Shop Address1"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="address1"
                                type="text"
                                placeholder="Shop Address1"
                                value={address1}
                                onChange={(e) => setaddress1(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>{" "}
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Shop Address2"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="address2"
                                type="text"
                                placeholder="Shop Address2"
                                value={address2}
                                onChange={(e) => setaddress2(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>{" "}
                          <MDBRow>
                            <MDBCol sm="12" className="mt-1 mb-4">
                              <select
                                onChange={(e) => setStateId(e.target.value)}
                                name="state"
                                className="w-100 h-5"
                              >
                                <option>----Select State----</option>
                                {shopstate.map((state, index) => (
                                  <option key={index} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12" className="mt-1 mb-4">
                              <select
                                onChange={(e) => setCityID(e.target.value)}
                                name="city"
                                className="w-100 h-5"
                              >
                                <option>----Select City----</option>
                                {city.map((citys, index) => (
                                  <option key={index} value={citys.id}>
                                    {citys.name}
                                  </option>
                                ))}
                              </select>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Pin Code"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="pin"
                                type="text"
                                placeholder="Pin Code"
                                value={pin}
                                onChange={handlePinChange}
                                inputProps={{
                                  maxLength: 6,
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12" className="mt-1 mb-4">
                              <select
                                name="is_verified"
                                onChange={(e) => setisVerified(e.target.value)}
                                className="w-100 h-5"
                              >
                                <option value="">
                                  ----Select Customer Status----
                                </option>
                                {customerVerify.map((verify, index) => (
                                  <option key={index} value={verify.value}>
                                    {verify.name}
                                  </option>
                                ))}
                              </select>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12" className="mt-1 mb-4">
                              <select
                                name="shop_verified"
                                onChange={(e) =>
                                  setshopVerified(e.target.value)
                                }
                                className="w-100 h-5"
                              >
                                <option value="">
                                  ----Select Shop Status----
                                </option>
                                {shopVerify.map((shopverify, index) => (
                                  <option key={index} value={shopverify.value}>
                                    {shopverify.name}
                                  </option>
                                ))}
                              </select>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Tin Number"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="pin"
                                type="text"
                                placeholder="Tin Number"
                                value={tinnumber}
                                onChange={(e) => {
                                  const re = /^[0-9 .\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    settinnumber(e.target.value);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol sm="12">
                              <TextField
                                id="outlined-basic"
                                label="Drug Licence Number"
                                variant="outlined"
                                className="w-100 mx-auto"
                                name="pin"
                                type="text"
                                placeholder="Drug Licence Number"
                                value={drugLnumber}
                                onChange={(e) => setdrugLnumber(e.target.value)}
                              />
                            </MDBCol>
                          </MDBRow>
                          <>
                            <MDBRow>
                              <MDBCol sm="12" className="mt-1 mb-4">
                                <select
                                  name="shop_verified"
                                  onChange={(e) =>
                                    setgstregistered(e.target.value)
                                  }
                                  className="w-100 h-5"
                                >
                                  <option value="">GST Registered</option>
                                  <option value="1">RD</option>
                                  <option value="0">URD</option>
                                </select>
                              </MDBCol>
                            </MDBRow>
                            {gstregistered === "1" && (
                              <MDBRow>
                                <MDBCol sm="12">
                                  <TextField
                                    id="outlined-basic"
                                    label="GST Number"
                                    variant="outlined"
                                    className="w-100 mx-auto"
                                    name="pin"
                                    type="text"
                                    placeholder="GST Number"
                                    value={gstnumber}
                                    onChange={(e) => {
                                      const input = e.target.value.replace(
                                        /[^a-zA-Z0-9]/g,
                                        ""
                                      );
                                      if (input.length <= 16) {
                                        setgstnumber(input);
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      const input = e.target.value;
                                      if (
                                        input.length === 16 &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                      if (!e.key.match(/[a-zA-Z0-9]/)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                            )}
                          </>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol lg="8">
                      <MDBCard className="mb-4 mt-4 p-4">
                        <MDBCardBody>
                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Profile Image</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="profile_image"
                                type="file"
                                onChange={(e) =>
                                  setProfileImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Shop Image</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>

                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="shop_image"
                                type="file"
                                onChange={(e) =>
                                  setShopImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>
                                Shop Estable Certificate
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="shop_establishment_certificate"
                                type="file"
                                onChange={(e) =>
                                  setShopECImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Drug License</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="drug_license"
                                type="file"
                                onChange={(e) =>
                                  setDruglicenseImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Trade Certificate</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="trade_certificate"
                                type="file"
                                onChange={(e) =>
                                  setTradecertificateImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Udhyam Adhar</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="udhyam_adhar"
                                type="file"
                                onChange={(e) =>
                                  setUdhyamadharImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Fssai Certificate</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="fssai"
                                type="file"
                                onChange={(e) =>
                                  setFssaicertificateImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Gst Certificate</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              {" "}
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="gst_certificate"
                                type="file"
                                onChange={(e) =>
                                  setGstcertificateImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Current Account Cheque</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                className="w-29 mx-auto mb-5"
                                accept="image/jpeg, image/png, image/jpg"
                                name="current_acount_cheque"
                                type="file"
                                onChange={(e) =>
                                  setCurrentAchequeImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>

                          <MDBRow className="mt-3">
                            <MDBCol sm="4">
                              <MDBCardText>Other Shop Document</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="2"></MDBCol>
                            <MDBCol sm="6">
                              <input
                                id="outlined-basic"
                                variant="outlined"
                                accept="image/jpeg, image/png, image/jpg"
                                className="w-29 mx-auto mb-5"
                                name="other_shop_document"
                                type="file"
                                onChange={(e) =>
                                  setOthershopdocumentImage(e.target.files[0])
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mt-3">
                            <MDBCol sm="12">
                              <button
                                className="btn btn-danger mb-2"
                                style={{
                                  marginLeft: "45%",
                                }}
                                onClick={submitForm}
                              >
                                Submit
                              </button>
                            </MDBCol>
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
