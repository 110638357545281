import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import Swal from "sweetalert2";
import {
  CModal,
  CCard,
  CCardBody,
  CCol,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CButton,
  CForm,
  CFormTextarea,
  CModalBody,
  CModalFooter,
} from "@coreui/react";

const Supplier = () => {
  const [supplier, setSupplier] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [expandedAddressRow, setExpandedAddressRow] = useState(null);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);

  const { id } = useParams();
  const [contactPname, setContactPame] = useState("");
  const [headname, setHeadName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gstRegistered, setGstRegistered] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [drugLnumber, setDrugLnumber] = useState("");

  const [emailId, setEmailId] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const openEditModal = (id) => {
    setEditModalVisible(true);
    setCurrentEditId(id);
  };

  useEffect(() => {
    loadCustomer();
  }, [editModalVisible, currentEditId]);

  const loadCustomer = async () => {
    if (editModalVisible && currentEditId) {
      const result = await axios.get("/api/single_supplier/" + currentEditId);
      setHeadName(result.data.data.head_name);
      setDrugLnumber(result.data.data.drug_license);
      setPinCode(result.data.data.pincode);
      setContactPame(result.data.data.contact_person);
      setCity(result.data.data.city);
      setDistrict(result.data.data.district);
      setGstRegistered(result.data.data.gst_registered);
      setGstNumber(result.data.data.gst_number);
      setMobile(result.data.data.mobile);
      setAddress(result.data.data.address);
      setEmailId(result.data.data.email);
      setTelephoneNumber(result.data.data.telephone_number);
      setState(result.data.data.state);
      setStateCode(result.data.data.state_code);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();
    categoryobj.append("address", address);
    categoryobj.append("mobile", mobile);
    categoryobj.append("city", city);
    categoryobj.append("head_name", headname);
    categoryobj.append("contact_person", contactPname);
    categoryobj.append("district", district);
    categoryobj.append("pincode", pinCode);
    categoryobj.append("drug_license", drugLnumber);
    categoryobj.append("gst_registered", gstRegistered);
    categoryobj.append("gst_number", gstNumber);

    categoryobj.append("email", emailId);
    categoryobj.append("telephone_number", telephoneNumber);
    categoryobj.append("state", state);
    categoryobj.append("state_code", stateCode);

    axios
      .post("/api/update_supplier/" + currentEditId, categoryobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        setEditModalVisible(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    axios
      .get("/api/get_supplier")
      .then((res) => {
        setSupplier(res.data.data);
      })
      .catch((err) => {});
  }, [editModalVisible, currentEditId]);

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  const deleteHandler = (row) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Supplier?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete_supplier/" + row.id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });
            let newArr = supplier.filter((items) => {
              return row.id !== items.id;
            });
            setSupplier(newArr);
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => <div>{index + 1}</div>,

      width: "70px",
    },

    {
      name: "A/c Head Name",
      selector: (row) => (
        <span style={{ fontSize: "14px" }}>{row.head_name}</span>
      ),

      width: "220px",
    },
    {
      name: "Contact Person Name",
      selector: (row) => (
        <span style={{ fontSize: "14px" }}>{row.contact_person}</span>
      ),

      width: "220px",
    },
    {
      name: "City",
      selector: (row) => row.city,

      width: "140px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,

      width: "120px",
    },

    {
      name: "Address",
      selector: (row) => row.address + ", " + row.district + ", " + row.pincode,

      width: "200px",
      cell: (row) => {
        const address = row.address + ", " + row.district + ", " + row.pincode;
        const maxLength = 300;
        const expanded =
          expandedAddressRow === row.id || address.length <= maxLength;

        return (
          <div>
            <span>
              {expanded ? address : address.slice(0, maxLength) + "... "}
            </span>
            {address.length > maxLength && (
              <span
                onClick={() => setExpandedAddressRow(expanded ? null : row.id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {expanded ? "See less" : "See more"}
              </span>
            )}
          </div>
        );
      },
    },
    {
      name: "Drug License No",
      selector: (row) => row.drug_license,

      width: "200px",
    },
    {
      name: "Gst Registered",
      selector: (row) => (row.gst_registered == 1 ? "RD" : "URD"),

      width: "130px",
    },
    {
      name: "Gst No",
      selector: (row) => row.gst_number,

      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <CButton color="primary" onClick={() => openEditModal(row.id)}>
            <EditIcon />
          </CButton>
          <button
            className="btn btn-danger m-1"
            onClick={() => deleteHandler(row)}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(supplier);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const filteredItems = supplier.filter(
    (item) =>
      (item.head_name &&
        item.head_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.contact_person &&
        item.contact_person.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <CModal size="xl" visible={editModalVisible}>
        <div class="d-flex justify-content-between m-2">
          <strong>Edit Supplier ID: {currentEditId}</strong>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </div>

        <CModalBody>
          <div className="bg-gre p-5">
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Edit Supplier</strong>
                </CCardHeader>
                <CCardBody>
                  <CForm className="row g-3" onSubmit={submitForm}>
                    <CCol md={6} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Account Head Name
                      </CFormLabel>

                      <CFormInput
                        value={headname}
                        required
                        name="head_name"
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setHeadName(input);
                        }}
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-Z ]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={6} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Contact Person Name
                      </CFormLabel>
                      <CFormInput
                        required
                        name="contact_person"
                        value={contactPname}
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setContactPame(input);
                        }}
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-Z ]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={6} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        E-Mail ID
                      </CFormLabel>
                      <CFormInput
                        required
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={6} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Address
                      </CFormLabel>
                      <CFormTextarea
                        value={address}
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                        id="exampleFormControlTextarea1"
                        required
                        placeholder="Address"
                        rows="1"
                      ></CFormTextarea>
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        State
                      </CFormLabel>
                      <CFormInput
                        required
                        value={state}
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setState(input);
                        }}
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-Z ]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        State Code
                      </CFormLabel>
                      <CFormInput
                        required
                        onChange={(e) => handleNumericInput(e, setStateCode)}
                        id="inputPincode"
                        placeholder=""
                        value={stateCode}
                        maxLength={2}
                        minLength={2}
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        City
                      </CFormLabel>
                      <CFormInput
                        required
                        name="city"
                        value={city}
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setCity(input);
                        }}
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-Z ]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Mobile
                      </CFormLabel>
                      <CFormInput
                        required
                        onChange={(e) => handleNumericInput(e, setMobile)}
                        id="inputMobile"
                        placeholder=""
                        value={mobile}
                        minLength={10}
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        District
                      </CFormLabel>
                      <CFormInput
                        required
                        value={district}
                        name="district"
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setDistrict(input);
                        }}
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-Z ]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Pincode
                      </CFormLabel>
                      <CFormInput
                        required
                        onChange={(e) => handleNumericInput(e, setPinCode)}
                        id="inputPincode"
                        placeholder=""
                        value={pinCode}
                        maxLength={6}
                        minLength={6}
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Telephone Number
                      </CFormLabel>
                      <CFormInput
                        required
                        onChange={(e) =>
                          handleNumericInput(e, setTelephoneNumber)
                        }
                        id="inputNo"
                        placeholder=""
                        value={telephoneNumber}
                        minLength={10}
                      />
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        Drug License No
                      </CFormLabel>
                      <CFormInput
                        required
                        name="drug_license"
                        value={drugLnumber}
                        onChange={(e) => setDrugLnumber(e.target.value)}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>
                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        GST Registered
                      </CFormLabel>
                      <select
                        required
                        name="gst_registered"
                        onChange={(e) => setGstRegistered(e.target.value)}
                        className="w-100 "
                        style={{ height: "38px" }}
                      >
                        <option value="">Select</option>
                        <option selected={gstRegistered == "1"} value="1">
                          RD
                        </option>
                        <option selected={gstRegistered == "0"} value="0">
                          URD
                        </option>
                      </select>
                    </CCol>

                    <CCol md={3} className="">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        GST No
                      </CFormLabel>
                      <CFormInput
                        required
                        value={gstNumber}
                        onChange={(e) => {
                          const input = e.target.value.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          );
                          if (input.length <= 16) {
                            setGstNumber(input);
                          }
                        }}
                        onKeyDown={(e) => {
                          const input = e.target.value;
                          if (input.length === 16 && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                          if (!e.key.match(/[a-zA-Z0-9]/)) {
                            e.preventDefault();
                          }
                        }}
                        id="inputAddress"
                        placeholder=""
                      />
                    </CCol>

                    <CCol md={12} className="text-center">
                      <button type="submit" className="btn btn-danger my-3 ">
                        Submit
                      </button>
                    </CCol>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Supplier</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <input
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/add-supplier"
                            className="btn btn-primary mr-5 "
                          >
                            + Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="m-1">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      allowOverflow
                      responsive
                      pagination={10}
                      subHeaderAlign="right"
                      highlightOnHover
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Supplier;
