import React, { useEffect, useState } from "react";
import axios from "axios";
import { AccordionDetails, Typography, TextField } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { CCardHeader } from "@coreui/react";
import Navbar from "../../components/navbar/Navbar";
const Setting = () => {
  const [helpCenter, setHelpCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [image, setImage] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");

  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [cashback, setCashback] = useState("");
  const [returnDays, setReturnDays] = useState("");

  useEffect(() => {
    axios
      .get("/api/get_settings")
      .then((res) => {
        const data = res.data.data;
        setHelpCenter(data);

        setLoading(false);
        setCompanyName(res.data.data.company_name);
        setEmail(res.data.data.email);
        setMobileNumber(res.data.data.phone);
        setAddress(res.data.data.address);
        setWebsite(res.data.data.website);
        setAccountHolderName(res.data.data.account_name);
        setAccountNumber(res.data.data.account_number);
        setIfscCode(res.data.data.ifsc);
        setBankName(res.data.data.bank_name);
        setCashback(res.data.data.referal_cashback);
        setReturnDays(res.data.data.return_days);
        setImage(res.data.data.logo);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <div className="p-2 bg-white d-flex justify-content-between ml-3">
                <span>
                  <Link to="/dashboard">Dashboard</Link>
                </span>

                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <Link to="/edit-setting" className="btn btn-primary mr-5 ">
                      Edit Account Setting
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bg-gre  ml-3 " style={{ padding: "50px" }}>
                <AccordionDetails>
                  <Typography>
                    <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                      <div className="text-box">
                        <strong className="m-3">
                          <CCardHeader>Company Details</CCardHeader>
                        </strong>

                        <div className="mb-3">
                          {helpCenter && (
                            <img
                              src={image}
                              alt="Company Logo"
                              className="logo-image"
                              style={{ height: "100px", width: "100px" }}
                            />
                          )}
                        </div>

                        <TextField
                          id="outlined-basic"
                          label="Company Name"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="companyname"
                          placeholder="Company Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />

                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          name="email"
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Mobile Number"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="mobileNumber"
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="address"
                          placeholder="Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />

                        <TextField
                          id="outlined-basic"
                          label="Website"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="website"
                          placeholder="Website"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails>
                  <Typography>
                    <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                      <div className="text-box">
                        <strong className="m-3">
                          <CCardHeader>Account Details</CCardHeader>
                        </strong>

                        <TextField
                          id="outlined-basic"
                          label="Account Holder Name"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="accountholdername"
                          placeholder="Account Holder Name"
                          value={accountHolderName}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />

                        <TextField
                          id="outlined-basic"
                          label="Account Number"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          name="accountnumber"
                          type="text"
                          placeholder="Account Number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="IFSC Code"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="ifsccode"
                          placeholder="IFSC Code"
                          value={ifscCode}
                          onChange={(e) => setIfscCode(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Bank Name"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="bankname"
                          placeholder="Bank Name"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails>
                  <Typography>
                    <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                      <div className="text-box">
                        <strong className="m-3">
                          <CCardHeader>Other Details</CCardHeader>
                        </strong>

                        <TextField
                          id="outlined-basic"
                          label="Cashback"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          type="text"
                          name="cashback"
                          placeholder="Cashback"
                          value={cashback}
                          onChange={(e) => setCashback(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />

                        <TextField
                          id="outlined-basic"
                          label="Return Days"
                          variant="outlined"
                          className="input-field w-40 mb-2"
                          name="returns"
                          type="text"
                          placeholder="Return Days"
                          value={returnDays}
                          onChange={(e) => setReturnDays(e.target.value)}
                          disabled
                          sx={{ color: "#FFFFFF" }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
