import "./customers.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TextField } from "@mui/material";
const Customers = () => {
  const [users, setUser] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [visibleLg, setVisibleLg] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const [value, setValue] = useState("1");

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);

  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [shopstate, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [stateID, setStateId] = useState("");
  const [cityID, setCityID] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [drugLnumber, setdrugLnumber] = useState("");
  const [tinnumber, settinnumber] = useState("");
  const [gstregistered, setgstregistered] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    is_verified: "",
    mobile: "",
    shop_address1: "",
    shop_address2: "",
    shop_city: "",
    shop_state: "",
    pin: "",
    shop_image: "",
    profile_image: "",
    shop_name: "",
    shop_verified: "",
    shop_establishment_certificate: "",
    drug_license: "",
    gst_certificate: "",
    fssai: "",
    udhyam_adhar: "",
    trade_certificate: "",
    current_acount_cheque: "",
    other_shop_document: "",
  });

  const [shopECimg, setShopECImage] = useState({
    image: "",
  });
  const [druglicenseimg, setDruglicenseImage] = useState({
    image: "",
  });
  const [gstcertificateimg, setGstcertificateImage] = useState({
    image: "",
  });
  const [fssaicertificateimg, setFssaicertificateImage] = useState({
    image: "",
  });
  const [udhyamadharimg, setUdhyamadharImage] = useState({
    image: "",
  });
  const [currentAchequeimg, setCurrentAchequeImage] = useState({
    image: "",
  });
  const [othershopdocumentimg, setOthershopdocumentImage] = useState({
    image: "",
  });
  const [tradecertificateimg, setTradecertificateImage] = useState({
    image: "",
  });
  const [profileimg, setProfileImage] = useState({
    image: "",
  });
  const [shopimg, setShopImage] = useState({
    image: "",
  });

  const openEditModal = (id) => {
    setEditModalVisible(true);
    setCurrentEditId(id);
  };

  const handleInput = (e) => {
    e.persist();
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const handleImage1 = (e) => {
    setShopECImage({ image: e.target.files[0] });
  };
  const handleImage2 = (e) => {
    setDruglicenseImage({ image: e.target.files[0] });
  };
  const handleImage3 = (e) => {
    setTradecertificateImage({ image: e.target.files[0] });
  };
  const handleImage4 = (e) => {
    setUdhyamadharImage({ image: e.target.files[0] });
  };
  const handleImage5 = (e) => {
    setFssaicertificateImage({ image: e.target.files[0] });
  };
  const handleImage6 = (e) => {
    setGstcertificateImage({ image: e.target.files[0] });
  };

  const handleImage7 = (e) => {
    setCurrentAchequeImage({ image: e.target.files[0] });
  };

  const handleImage8 = (e) => {
    setOthershopdocumentImage({ image: e.target.files[0] });
  };
  const handleImage9 = (e) => {
    setShopImage({ image: e.target.files[0] });
  };
  const handleImage0 = (e) => {
    setProfileImage({ image: e.target.files[0] });
  };

  const handleCity = (e) => {
    setCityID(e.target.value);
  };

  const handleState = (e) => {
    setStateId(e.target.value);
  };

  const customerVerify = [
    {
      value: 1,
      name: "Verified",
    },
    {
      value: 0,
      name: "Not Verified",
    },
  ];

  const shopVerify = [
    {
      value: 0,
      name: "Not Verified",
    },
    {
      value: 1,
      name: "Processing",
    },
    {
      value: 2,
      name: "Verified",
    },
  ];

  useEffect(() => {
    getCustomer();
  }, [editModalVisible, currentEditId]);

  const getCustomer = async () => {
    if (editModalVisible && currentEditId) {
      try {
        const res = await axios.get("/api/single_customer/" + currentEditId);
        const data = res.data.data;

        setCustomer({
          id: data.id || "",
          name: data.name || "",
          email: data.email || "",
          is_verified: data.is_verified,
          mobile: data.mobile || "",
          shop_address1: data.shop_address1 || "",
          shop_address2: data.shop_address2 || "",
          shop_city: data.shop_city ? data.shop_city.id : "",
          shop_state: data.shop_state ? data.shop_state.id : "",
          pin: data.pin || "",
          shop_image: data.shop_image || "",
          profile_image: data.profile_image || "",
          shop_name: data.shop_name || "",
          shop_verified: data.shop_verified || "",
          shop_establishment_certificate:
            data.shop_establishment_certificate || "",
          drug_license: data.drug_license || "",
          gst_certificate: data.gst_certificate || "",
          fssai: data.fssai || "",
          udhyam_adhar: data.udhyam_adhar || "",
          trade_certificate: data.trade_certificate || "",
          current_acount_cheque: data.current_acount_cheque || "",
          other_shop_document: data.other_shop_document || "",
        });

        setaddress1(data.shop_address1 || "");
        setaddress2(data.shop_address2 || "");
        if (data.shop_state) {
          setStateId(data.shop_state.id);
        }
        if (data.shop_city) {
          setCityID(data.shop_city.id);
        }
        setdrugLnumber(data.drug_license_no || "");
        setgstregistered(data.gst_registered || 0);
        settinnumber(data.tin_no || "");
        setgstnumber(data.gst_no);
      } catch (error) {}
    }
  };

  useEffect(() => {
    axios.get("/api/states").then((res) => {
      setState(res.data.data);
    });
  }, [stateID]);

  useEffect(() => {
    getCity();
  }, [stateID]);
  const getCity = async () => {
    await axios.get("/api/cities/" + stateID).then((res) => {
      setCity(res.data.data);
    });
  };

  const updateCustomer = async (e) => {
    e.preventDefault();

    const customerobj = new FormData();
    customerobj.append("name", customer.name);
    customerobj.append("email", customer.email);
    customerobj.append("mobile", customer.mobile);
    customerobj.append("is_verified", customer.is_verified);
    customerobj.append("shop_name", customer.shop_name);
    customerobj.append("profile_image", profileimg.image);
    customerobj.append("shop_image", shopimg.image);
    customerobj.append("address1", address1);
    customerobj.append("address2", address2);
    customerobj.append("city", cityID);
    customerobj.append("state", stateID);
    customerobj.append("pin", customer.pin);
    customerobj.append("shop_verified", customer.shop_verified);
    customerobj.append("shop_establishment_certificate", shopECimg.image);
    customerobj.append("drug_license", druglicenseimg.image);
    customerobj.append("trade_certificate", tradecertificateimg.image);
    customerobj.append("udhyam_adhar", udhyamadharimg.image);
    customerobj.append("fssai", fssaicertificateimg.image);
    customerobj.append("gst_certificate", gstcertificateimg.image);
    customerobj.append("current_acount_cheque", currentAchequeimg.image);
    customerobj.append("other_shop_document", othershopdocumentimg.image);
    customerobj.append("drug_license_no", drugLnumber);
    customerobj.append("tin_no", tinnumber);
    customerobj.append("gst_no", gstnumber);
    customerobj.append("gst_registered", gstregistered);

    axios
      .post("/api/update_customer_admin/" + customer.id, customerobj, {
        header: {
          accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });
        setEditModalVisible(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const type = params.get("type");

  const api_url =
    type === "new" ? "/api/get_all_customer?type=new" : "/api/get_all_customer";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModal = (customerId) => {
    setSelectedCustomerId(customerId);
    setVisibleLg(true);
  };

  useEffect(() => {
    if (visibleLg && selectedCustomerId) {
      axios
        .get("/api/single_customer/" + selectedCustomerId)
        .then((response) => {
          const data = response.data.data;

          setCustomer({
            name: data.name || "",
            email: data.email || "",
            is_verified: data.is_verified || "",
            mobile: data.mobile || "",
            shop_address1: data.shop_address1 || "",
            shop_address2: data.shop_address2 || "",
            shop_city: data.shop_city ? data.shop_city.id : "",
            shop_state: data.shop_state ? data.shop_state.id : "",
            pin: data.pin || "",
            gst_no: data.gst_no || "",
            gst_registered: data.gst_registered || "",
            drug_license_no: data.drug_license_no || "",
            tin_no: data.tin_no || "",
            created_at: data.created_at || "",
            shop_image: data.shop_image || "",
            profile_image: data.profile_image || "",
            shop_name: data.shop_name || "",
            shop_verified: data.shop_verified || "",
            shop_establishment_certificate:
              data.shop_establishment_certificate || "",
            drug_license: data.drug_license || "",
            gst_certificate: data.gst_certificate || "",
            fssai: data.fssai || "",
            udhyam_adhar: data.udhyam_adhar || "",
            trade_certificate: data.trade_certificate || "",
            current_acount_cheque: data.current_acount_cheque || "",
            other_shop_document: data.other_shop_document || "",
          });
        })
        .catch((error) => {});
    }
  }, [visibleLg, selectedCustomerId]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get(api_url)
      .then((res) => {
        res.data.status == "Success" ? setUser(res.data.data) : setUser([]);
      })
      .catch((err) => {});
  }, [editModalVisible, currentEditId]);

  const deleteHandler = (row) => {
    Swal.fire({
      html: "Are you sure you wish to<br /> delete this Customer?",
      showCancelButton: true,
      confirmButtonColor: "#1D718B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      width: "350",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/api/delete_customer/" + row.id, {
            headers: {
              accept: "application/json",
            },
          })
          .then((result) => {
            Toast.fire({
              icon: "success",
              title: result.data.message,
            });
            let newArr = users.filter((items) => {
              return row.id !== items.id;
            });
            setUser(newArr);
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Error deleting Language",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "View",
      selector: (row) => (
        <div style={{ width: "90px" }}>
          <CButton color="primary" onClick={() => openModal(row.id)}>
            <VisibilityIcon />
          </CButton>
        </div>
      ),
      width: "90px",
    },

    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Shop Status",
      selector: (row) => {
        let statusText;
        let color;
    
        switch (row.shop_verified) {
          case "0":
            statusText = "Not Verified";
            color = 'red';
            break;
          case "1":
            statusText = "Processing";
            color = 'orange';
            break;
          case "2":
            statusText = "Verified";
            color = 'green';
            break;
          default:
            statusText = "Unknown";
            color = 'gray';
        }
    
        return (
          <span
            style={{
              color: color,
              fontWeight: 'bold'
            }}
          >
            {statusText}
          </span>
        );
      },
      width: "150px",
    },
    
    // {
    //   name: "Shop Status",
    //   selector: (row) => (
    //     <span 
    //       style={{ 
    //         color: row.shop_verified === 1 ? 'green' : 'red', 
    //         fontWeight: 'bold' 
    //       }}
    //     >
    //       {row.shop_verified === 1 ? "Verified" : "Not Verified"}
    //     </span>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Shop Name",
      selector: (row) => {
        if (row.shop_name) {
          return <span className="text-capital" style={{ whiteSpace: 'pre-line', width: '250px' }}>{row.shop_name}</span>;
        } else {
          return "Not available";
        }
      },
      width: "250px",
    },
   
    {
      name: "Email",
      selector: (row) => row.email,
      width: "250px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      width: "150px",
    },
   
    {
      name: "Total Order",
      selector: (row) => row.orders_count,
      width: "130px",
    },
    {
      name: "Name",
      selector: (row) => {
        if (row.name) {
          return <span className="text-capital" style={{ whiteSpace: 'pre-line', width: '250px' }}>{row.name}</span>;
        } else {
          return "Not available";
        }
      },
      width: "250px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <CButton color="primary" onClick={() => openEditModal(row.id)}>
            <EditIcon />
          </CButton>

          <button
            className="btn btn-danger m-1"
            onClick={() => {
              deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
      width: "250px",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(users);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const filteredItems = users.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.mobile &&
        item.mobile.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.shop_name &&
        item.shop_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.created_at &&
        item.created_at.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.shop_address &&
        item.shop_address.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.updated_at &&
        item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    <>
      <CModal
        size="fullscreen"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="ViewCustomerModal"
      >
        <CModalHeader closeButton>
          <CModalTitle style={{ marginLeft: "75px" }}>
            <strong>View Customer</strong>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Customer ID: {selectedCustomerId}
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Details" value="1" />
                  <Tab label="Documents" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <section
                  className="bg-gre py-4"
                  style={{
                    height: "auto",
                  }}
                >
                  <MDBContainer className="px-5 ">
                    <MDBRow>
                      <MDBCol lg="6">
                        <MDBCard className="mb-1 mt-1 ">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Name</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  <span className="text-capital">
                                    {" "}
                                    {customer.name}
                                  </span>
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Email</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.email}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Mobile</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.mobile}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>GST Registered</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.gst_registered == 1 ? "RD" : "URD"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>GST Number</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.gst_no !== "null"
                                    ? customer.gst_no
                                    : "Not Available"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>

                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Verified</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.is_verified == 1
                                    ? "Verified"
                                    : "Not Verified"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />

                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Created</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {new Date(
                                    customer.created_at
                                  ).toLocaleString()}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol lg="6">
                        <MDBCard className="mb-1 mt-1">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Profile Image</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                  {customer.profile_image ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.profile_image}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow className="mt-3">
                              <MDBCol sm="4">
                                <MDBCardText>Shop Image</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                  {customer.shop_image ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.shop_image}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Shop Name</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  <span className="text-capital">
                                    {" "}
                                    {customer.shop_name}
                                  </span>
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Shop Address</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.shop_address1}
                                  <br />
                                  {customer.shop_address2}
                                  <br />
                                  {customer.shop_city?.name}{" "}
                                  {customer.shop_state?.name}
                                  <br />
                                  {customer.pin}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Shop Verified</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.shop_verified == 0
                                    ? "Not Verify"
                                    : customer.shop_verified == 1
                                    ? "Processing"
                                    : "Verified"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Drug Licence Number</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.drug_license_no !== "null"
                                    ? customer.drug_license_no
                                    : "Not Available"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>

                            <hr />
                            <MDBRow>
                              <MDBCol sm="4">
                                <MDBCardText>Tin Number</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="8">
                                <MDBCardText className="text-muted">
                                  {customer.tin_no !== "null"
                                    ? customer.tin_no
                                    : "Not Avilable"}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </section>
              </TabPanel>
              <TabPanel value="2">
                {" "}
                <section
                  className="bg-gre "
                  style={{
                    height: "auto",
                  }}
                >
                  <MDBContainer className="px-5 pb-3">
                    <MDBRow className="justify-content-center">
                      <MDBCol lg="7">
                        <MDBCard className="mb-1 mt-4">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>
                                  Shop Establishment Certificate
                                </MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.shop_establishment_certificate ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.shop_establishment_certificate}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.shop_establishment_certificate ===
                                  null ||
                                customer.shop_establishment_certificate ===
                                  undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    to={customer.shop_establishment_certificate}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </Link>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Drug License</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.drug_license ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.drug_license}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.drug_license === null ||
                                customer.drug_license === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.drug_license}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Trade Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.trade_certificate ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.trade_certificate}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.trade_certificate === null ||
                                customer.trade_certificate === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.trade_certificate}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Udhyam Adhar</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.udhyam_adhar ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.udhyam_adhar}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.udhyam_adhar === null ||
                                customer.udhyam_adhar === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.udhyam_adhar}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Fssai Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.fssai ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.fssai}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.fssai === null ||
                                customer.fssai === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.fssai}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Gst Certificate</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.gst_certificate ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.gst_certificate}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.gst_certificate === null ||
                                customer.gst_certificate === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.gst_certificate}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Current Acount Cheque</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.current_acount_cheque ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.current_acount_cheque}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.current_acount_cheque === null ||
                                customer.current_acount_cheque === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.current_acount_cheque}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                            <hr />
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>Other Shop Document</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="2">
                                <MDBCardText>→</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="5">
                                  {customer.other_shop_document ? (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={customer.other_shop_document}
                                    >
                                      <button className="btn btn-primary">
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <span style={{ color: "red" }}>No Image</span>
                                  )}
                                </MDBCol>
                              {/* <MDBCol sm="5">
                                {customer.other_shop_document === null ||
                                customer.other_shop_document === undefined ? (
                                  <span style={{ color: "red" }}>No Image</span>
                                ) : (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={customer.other_shop_document}
                                  >
                                    <button className="btn btn-primary">
                                      View
                                    </button>
                                  </a>
                                )}
                              </MDBCol> */}
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </section>
              </TabPanel>
            </TabContext>
          </Box>
        </CModalBody>
      </CModal>
      <CModal size="xl" visible={editModalVisible}>
        <div class="d-flex justify-content-between m-2">
          <strong>Edit Customer ID : {currentEditId}</strong>
          <CButton
            className="btn btn-primary "
            onClick={() => setEditModalVisible(false)}
          >
            Close
          </CButton>
        </div>

        <CModalBody>
          <MDBContainer className="px-5">
            <form onSubmit={updateCustomer} encType="multipart/form-data">
              <MDBRow>
                <MDBCol lg="4">
                  <MDBCard className="mb-3 mt-4">
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="name"
                            type="text"
                            placeholder="Name"
                            value={customer.name}
                            onChange={handleInput}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={customer.email}
                            onChange={handleInput}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Mobile"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="mobile"
                            type="text"
                            placeholder="Mobile"
                            value={customer.mobile}
                            onChange={handleInput}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Shop Name"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="shop_name"
                            type="text"
                            placeholder="Shop Name"
                            value={customer.shop_name}
                            onChange={handleInput}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Shop Address1"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="address1"
                            type="text"
                            placeholder="Shop Address1"
                            value={address1}
                            onChange={(e) => setaddress1(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>{" "}
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Shop Address2"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="address2"
                            type="text"
                            placeholder="Shop Address2"
                            value={address2}
                            onChange={(e) => setaddress2(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>{" "}
                      <MDBRow>
                        <MDBCol sm="12" className=" mb-3">
                          <select onChange={handleState} className="w-100 h-5">
                            <option>----State----</option>
                            {shopstate.map((state, index) => (
                              <option
                                selected={
                                  customer.shop_state &&
                                  customer.shop_state.id === state.id
                                }
                                key={index}
                                value={state.id}
                              >
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12" className=" mb-3">
                          <select onChange={handleCity} className="w-100 h-5">
                            <option>----City----</option>

                            {city.map((citys, index) => (
                              <option
                                selected={
                                  customer.shop_city &&
                                  customer.shop_city.id === citys.id
                                }
                                key={index}
                                value={citys.id}
                              >
                                {citys.name}
                              </option>
                            ))}
                          </select>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Pin Code"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="pin"
                            type="text"
                            placeholder="Pin Code"
                            value={customer.pin}
                            onChange={handleInput}
                            inputProps={{
                              maxLength: 6,
                              pattern: "\\d{0,6}",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={customer.pin.length !== 6}
                            helperText={
                              customer.pin.length !== 6
                                ? "Pin Code must be exactly 6 digits"
                                : ""
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12" className=" mb-3">
                          <select
                            name="is_verified"
                            onChange={handleInput}
                            className="w-100 h-5"
                          >
                            <option value="">--Select Customer Status--</option>
                            {customerVerify.map((verify, index) => (
                              <option
                                selected={customer.is_verified == verify.value}
                                key={index}
                                value={verify.value}
                              >
                                {verify.name}
                              </option>
                            ))}
                          </select>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12" className="mb-3">
                          <select
                            name="shop_verified"
                            onChange={handleInput}
                            className="w-100 h-5"
                          >
                            <option value="">--Select Shop Status--</option>
                            {shopVerify.map((shopverifed, index) => (
                              <option
                                selected={
                                  customer.shop_verified == shopverifed.value
                                }
                                key={index}
                                value={shopverifed.value}
                              >
                                {shopverifed.name}
                              </option>
                            ))}
                          </select>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Tin Number"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="pin"
                            type="text"
                            placeholder="Tin Number"
                            value={tinnumber}
                            onChange={(e) => settinnumber(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12">
                          <TextField
                            id="outlined-basic"
                            label="Drug Licence Number"
                            variant="outlined"
                            className="w-100 mx-auto"
                            name="pin"
                            type="text"
                            placeholder="Drug Licence Number"
                            value={drugLnumber}
                            onChange={(e) => setdrugLnumber(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="12" className="mt-1 mb-4">
                          <select
                            name="shop_verified"
                            onChange={(e) => setgstregistered(e.target.value)}
                            className="w-100 h-5"
                          >
                            <option>GST Registered</option>
                            <option selected={gstregistered == "1"} value="1">
                              RD
                            </option>
                            <option selected={gstregistered == "0"} value="0">
                              URD
                            </option>
                          </select>
                        </MDBCol>
                      </MDBRow>
                      {gstregistered === "1" && (
                        <MDBRow>
                          <MDBCol sm="12">
                            <TextField
                              id="outlined-basic"
                              label="GST Number"
                              variant="outlined"
                              className="w-100 mx-auto"
                              name="pin"
                              type="text"
                              placeholder="GST Number"
                              value={gstnumber}
                              onChange={(e) => {
                                const input = e.target.value.replace(
                                  /[^a-zA-Z0-9]/g,
                                  ""
                                );
                                if (input.length <= 16) {
                                  setgstnumber(input);
                                }
                              }}
                              onKeyDown={(e) => {
                                const input = e.target.value;
                                if (
                                  input.length === 16 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                                if (!e.key.match(/[a-zA-Z0-9]/)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol lg="8">
                  <MDBCard className="mb-4 mt-4">
                    <MDBCardBody>
                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Profile Image</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.profile_image ? (
                             <Link
                             to={customer.profile_image}
                             target="_blank"
                             className="btn btn-primary"
                           >
                             View
                           </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.profile_image == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.profile_image}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3 mt-2"
                            name="profile_image"
                            type="file"
                            onChange={handleImage0}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Shop Image</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.shop_image ? (
                           <Link
                           to={customer.shop_image}
                           target="_blank"
                           className="btn btn-primary"
                         >
                           View
                         </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="">
                          {customer.shop_image == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.shop_image}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="shop_image"
                            type="file"
                            onChange={handleImage9}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Shop Estable Certificate</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.shop_establishment_certificate ? (
                           <Link
                           to={customer.shop_establishment_certificate}
                           target="_blank"
                           className="btn btn-primary"
                         >
                           View
                         </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.shop_establishment_certificate == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.shop_establishment_certificate}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="shop_establishment_certificate"
                            type="file"
                            onChange={handleImage1}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Drug License</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.drug_license ? (
                             <Link
                             to={customer.drug_license}
                             target="_blank"
                             className="btn btn-primary"
                           >
                             View
                           </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.drug_license == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.drug_license}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="drug_license"
                            type="file"
                            onChange={handleImage2}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Trade Certificate</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.trade_certificate ? (
                            <Link
                            to={customer.trade_certificate}
                            target="_blank"
                            className="btn btn-primary"
                          >
                            View
                          </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.trade_certificate == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.trade_certificate}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="trade_certificate"
                            type="file"
                            onChange={handleImage3}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Udhyam Adhar</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.udhyam_adhar ? (
                            <Link
                            to={customer.udhyam_adhar}
                            target="_blank"
                            className="btn btn-primary"
                          >
                            View
                          </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.udhyam_adhar == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.udhyam_adhar}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="udhyam_adhar"
                            type="file"
                            onChange={handleImage4}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Fssai Certificate</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.fssai ? (
                            <Link
                            to={customer.fssai}
                            target="_blank"
                            className="btn btn-primary"
                          >
                            View
                          </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.fssai == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.fssai}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="fssai"
                            type="file"
                            onChange={handleImage5}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Gst Certificate</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.gst_certificate ? (
                              <Link
                              to={customer.gst_certificate}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.gst_certificate == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.gst_certificate}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          {" "}
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="gst_certificate"
                            type="file"
                            onChange={handleImage6}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Current Acount Cheque</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.current_acount_cheque ? (
                           <Link
                           to={customer.current_acount_cheque}
                           target="_blank"
                           className="btn btn-primary"
                         >
                           View
                         </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.current_acount_cheque == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.current_acount_cheque}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="current_acount_cheque"
                            type="file"
                            onChange={handleImage7}
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5">
                        <MDBCol sm="4">
                          <MDBCardText>Other Shop Document</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="3">
                          {customer.other_shop_document ? (
                             <Link
                             to={customer.other_shop_document}
                             target="_blank"
                             className="btn btn-primary"
                           >
                             View
                           </Link>
                          ) : (
                            <span style={{ color: "red" }}>No Image</span>
                          )}
                        </MDBCol>
                        {/* <MDBCol sm="3">
                          {customer.other_shop_document == null ? (
                            <span className="text-red">Not Available</span>
                          ) : (
                            <Link
                              to={customer.other_shop_document}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              View
                            </Link>
                          )}
                        </MDBCol> */}
                        <MDBCol sm="5">
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            accept="image/jpeg, image/png, image/jpg"
                            className="w-29 mx-auto mb-3"
                            name="other_shop_document"
                            type="file"
                            onChange={handleImage8}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="my-4">
                        <MDBCol sm="12">
                          <button
                            disabled={customer.pin.length !== 6}
                            className="btn btn-danger mb-2 mt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            Submit
                          </button>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBContainer>
        </CModalBody>
      </CModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader>
                  <strong>Customers</strong>
                </CCardHeader>
                <div className="customerContainer">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6 mt-1">
                        <input
                          type="text"
                          placeholder="Search...."
                          value={filterText}
                          autoFocus
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                        <button
                          className="btn btn-primary m-1 small-button"
                          onClick={handleClear}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-2 text-right ">
                          <Link
                            to="/customer/add-customer"
                            className="btn btn-primary "
                          >
                            + Add Customer
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "1%" }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination={10}
                      subHeaderAlign="right"
                      highlightOnHover
                      progressPending={pending}
                      progressComponent={<CustomeLoader />}
                    />
                  </div>
                </div>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Customers;
