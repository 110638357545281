// import "./customers.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { CRow, CCol, CCardHeader } from "@coreui/react";
import { CCard, CCardBody } from "@coreui/react";
import { Link } from "react-router-dom";
import { CButton } from "@coreui/react";

const CartList = () => {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    axios
      .get("/api/coupon_all")
      .then((res) => {
        setCoupons(res.data.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4  ml-3">
                <CCardHeader className="d-flex justify-content-between">
                  <strong>Scheme List</strong>
                  <Link to="/coupon">
                    <CButton className="btn btn-primary">Add Scheme</CButton>
                  </Link>
                </CCardHeader>
                {coupons.length == 0 ? (
                  <div className="text-center fw-600 my-5 fs-18 text-red">
                    <span>No Scheme List Available</span>
                  </div>
                ) : (
                  <CRow className="mb-2 p-2 g-4">
                    {coupons.map((coupon, index) => {
                      return (
                        <CCol key={coupon.id} sm={12} md={4} lg={4}>
                          <CCard
                            className="shadow mb-3 bg-body rounded "
                            style={{ height: "100%" }}
                          >
                            <CCardBody>
                              <CCol md={12} className="d-flex ">
                                <CCol sm={5}>
                                  <span style={{ marginRight: "15px" }}>
                                    <span className="fw-semibold">Title :</span>
                                  </span>{" "}
                                </CCol>{" "}
                                <CCol sm={7}>
                                  <span className="text-capital">
                                    {coupon?.coupon_title}
                                  </span>
                                </CCol>
                              </CCol>

                              <CCol md={12} className="d-flex ">
                                <CCol sm={5}>
                                  <span style={{ marginRight: "15px" }}>
                                    <span className="fw-semibold">Code :</span>
                                  </span>{" "}
                                </CCol>{" "}
                                <CCol sm={7}>
                                  <span className="text-capital">
                                    {coupon?.coupon_code}
                                  </span>
                                </CCol>
                              </CCol>

                              <CCol md={12} className="d-flex ">
                                <CCol sm={5}>
                                  <span style={{ marginRight: "15px" }}>
                                    <span className="fw-semibold">
                                      Details :
                                    </span>
                                  </span>{" "}
                                </CCol>{" "}
                                <CCol sm={7}>
                                  <Link
                                    to={`/cartlist/${coupon.id}`}
                                    className="font-weight-bold font-xs text-medium-emphasis"
                                  >
                                    <CButton className="btn btn-primary">
                                      View
                                    </CButton>
                                  </Link>
                                </CCol>
                              </CCol>
                            </CCardBody>
                          </CCard>
                        </CCol>
                      );
                    })}
                  </CRow>
                )}
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CartList;
