import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CCard, CCardHeader } from "@coreui/react";
import Swal from "sweetalert2";
const Advertisement = () => {
  const { id } = useParams();

  const [img, setimg] = useState("");
  const [img1, setimg1] = useState("");
  const [img2, setimg2] = useState("");

  const [image, setimage] = useState("");
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/get-advertisement")
      .then((res) => {
        setimage(res.data.data[0].image);
        setimage1(res.data.data[1].image);
        setimage2(res.data.data[2].image);
      })
      .catch((err) => {});
  }, [id]);

  const submitForm = async (e) => {
    e.preventDefault();

    const productobj = new FormData();

    productobj.append("image", img);
    productobj.append("image1", img1);
    productobj.append("image2", img2);

    await axios
      .post("/api/add-advertisement", productobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: result.data.message,
        });

        window.location.reload(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <div className="container-fluid">
              <Navbar />
              <CCard className="mb-4 shadow-lg p-3 mb-5 bg-body-tertiary rounded  ml-3">
                <CCardHeader>
                  <strong>Advertisement</strong>
                </CCardHeader>

                <section className="bg-gre" style={{ padding: "50px" }}>
                  <div className="row bg-white pl-4">
                    <strong className="m-3" style={{ fontSize: 25 }}>
                      Advertisement
                    </strong>
                    <div className="row mt-2">
                      <div className="col-lg-3">
                        <img
                          src={image}
                          alt=""
                          style={{ width: "170px", height: "100px" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <div>Image-1</div>
                        <input
                          type="file"
                          className="w-100 mt-"
                          name="image"
                          accept=".png, .jpg, .jpeg .webp"
                          onChange={(e) => setimg(e.target.files[0])}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-3">
                        <img
                          src={image1}
                          alt=""
                          style={{ width: "170px", height: "100px" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <div>Image-2</div>

                        <input
                          type="file"
                          className="w-100 mt-1"
                          name="image1"
                          accept=".png, .jpg, .jpeg .webp"
                          onChange={(e) => setimg1(e.target.files[0])}
                          required
                        />
                      </div>
                    </div>

                    <strong className="m-3" style={{ fontSize: 25 }}>
                      Target Scheme <span style={{ color: "red" }}>*</span>{" "}
                    </strong>

                    <div className="row mt-2">
                      <div className="col-lg-3">
                        <img
                          src={image2}
                          alt=""
                          style={{ width: "170px", height: "100px" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <div>Image-3</div>

                        <input
                          type="file"
                          className="w-100 mt-1"
                          name="image2"
                          accept=".png, .jpg, .jpeg .webp"
                          onChange={(e) => setimg2(e.target.files[0])}
                          required
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-12 text-center mb-5">
                        <button
                          onClick={submitForm}
                          className="btn btn-danger mb-2"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </CCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advertisement;
